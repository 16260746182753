import React, { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { BankAdmin, CompanyAdminUnApproved, CompanyAdminApproved, FingerTipLogViewer, CompanyMasterDataCreater, CompanyMasterDataAuthorizer, Maker, ReportViewer, ProductAdmin, CustomerMaker, CustomerAuthorizer, VendorUser } from '../../config/side-navbar-config';
import '../../styles/layouts/side-main-navbar.scss';
import useAuth from '../../services/hooks/useauth';
import useAxiosPrivate from "../../services/hooks/useaxios-private";



export default function SideMainNavbar() {
    const [navbarTitle, setNavbarTitle] = useState("");
    const [currentPaymentActor, setCurrentPaymentActor] = useState("");
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        fetchData()
        // => /user/get-privilege

    }, [])
    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('user/get-privilege');
            setNavbarTitle(response.data.UserPrivilege)
            setCurrentPaymentActor(response.data.CurrentPaymentActor)
        } catch (error) {
            setNavbarTitle("")

        }
    };


    return (
        <div className='side-main-navbar'>
            {/* <div className='arrow-icons'>
                <AiOutlineLeft />
            </div> */}
            <div className='fingertip-logo'></div>
            {/* {
                navbarTitle === null && <ProductAdmin /> && <BankAdmin />
            } */}
            {/* <FingerTipLogViewer /> */}
            {
                (navbarTitle === "FingertipAdmin") && <ProductAdmin />
            }
            {
                (navbarTitle === "BankAdmin") && <BankAdmin />
            }
            {
                (navbarTitle === "ApprovedCompany" || navbarTitle === "ApprovedCompanyAdmin" || navbarTitle === "Approver" || navbarTitle === "Checker" || navbarTitle === "Maker" || navbarTitle === "Recommender" || navbarTitle === "Report viewer") &&
                <CompanyAdminApproved
                    currentPaymentActor={currentPaymentActor}
                    navbarTitle={navbarTitle}
                />
            }
            {
                (navbarTitle === "UnapprovedCompany" || navbarTitle === "UnapprovedCompanyAdmin") &&
                <CompanyAdminUnApproved />
            }
            {
                (navbarTitle === "Customer maker") && <CustomerMaker />
            }
            {
                (navbarTitle === "Customer authorizer") && <CustomerAuthorizer />
            }
            {
                (navbarTitle === "Vendor Admin") && <VendorUser />
            }
            {/* {
                (navbarTitle === "Master data creators") && <CompanyMasterDataCreater />
            }
            {
                (navbarTitle === "Master data authorizer") &&
                <CompanyMasterDataAuthorizer />
            } */}
            {/* {
                (navbarTitle === "Approver" || navbarTitle === "Checker" || navbarTitle === "Maker" || navbarTitle === "Recommender") &&
                <Maker
                    navbarTitle={navbarTitle}
                    currentPaymentActor={currentPaymentActor}
                />
            }*/}
            {/* {
                navbarTitle === "Report viewer" && <ReportViewer />
            } */}

        </div>
    )
}

