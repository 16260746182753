import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import InputComponent from '../../common-component/form-elements/input-component'
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox';
import { BsPlusLg } from 'react-icons/bs';
import IconButton from '../../common-component/form-elements/icon-button';
import ButtonComponent from '../../common-component/form-elements/button-component';
import UploadInvoice from './upload-invoice';
import { resetFormModified } from '../../../redux/action';
import { useDispatch, useSelector } from "react-redux";
import useAuth from '../../../services/hooks/useauth'
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { axiosPrivate } from '../../../middleware/axois-api';

interface props {
    invoiceDetails: any;
    SetInvoiceDetails: (val: any) => void;
    setInvoiceShowmodal: (val: any) => void;
    enterInvoiceNext: () => void;
    enterInvoiceBack: () => void;
    handleSaveForm: () => void;
    setShowDeleteModal: (val: boolean) => void
    getFile: (val: File) => void;
    currentActor: string
    invoiceDataErrors: any
    isEditMode: boolean;
    partPaymetFieldsBlock: boolean;
    invoice: any
    editData: any
    gstinDD: any
    setRateCardDetails: any
    setTransactionDetails: any
    setGstinDD: (val: any) => void
}


function EnterInvoiceDetails({ invoiceDetails, partPaymetFieldsBlock, currentActor, gstinDD, setRateCardDetails, setTransactionDetails, setGstinDD, editData, isEditMode, enterInvoiceNext, setInvoiceShowmodal, invoice, setShowDeleteModal, invoiceDataErrors, enterInvoiceBack, SetInvoiceDetails, getFile, handleSaveForm }: props) {
    const isFormModified = useSelector((state: any) => state.isFormModified);
    // const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [mindate, setMindate] = useState<any>();
    const todayDate = moment(new Date()).format('DD-MM-YYYY');
    const [expenceDD, setExpenceDD] = useState<any[]>([]);
    const [vendorNameDD, setVendorNameDD] = useState<any[]>([]);
    const [GSTNnumbers, setGSTNnumbers] = useState<any[]>([]);

    // Get the current date
    const currentDate = moment();
    // Determine the current year and month
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Months are 0-based, so add 1
    // Calculate the end date of the Indian financial year
    let financialYearEndDate;
    if (currentMonth >= 4) {
        // If the current month is April or later, the financial year ends on March 31 of the next year
        financialYearEndDate = moment(`${currentYear + 1}-03-31`);
    } else {
        // If the current month is before April, the financial year ends on March 31 of the current year
        financialYearEndDate = moment(`${currentYear}-03-31`);
    }

    useEffect(() => {
        fetchData();
        // handleTransactionData();
    }, [])

    // function to show thousand separator
    const formatAmount = (amount: any) => {
        if (amount && amount !== ".") {
            const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
            // Handle empty input
            if (onlyNumber === "") {
                return "";
            }
            // Split input into integer and decimal parts
            const parts = onlyNumber.split(".");
            const integerPart = parts[0];
            const decimalPart = parts[1] || "";
            // Format the integer part with commas
            const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
            // Handle complete decimal input (e.g., "5000.50")
            if (decimalPart !== "") {
                return `${formattedInteger}.${decimalPart}`;
            }
            // Handle incomplete decimal input (e.g., "5000.")
            if (amount.toString().endsWith(".")) {
                return `${formattedInteger}.`;
            }
            // Return formatted integer
            return formattedInteger;
        }
        return "";
    };

    // get expance type and vendor dropdown 
    // const fetchData = async () => {
    //     try {
    //         const response = await axiosPrivate.get(`/vendor-payable/expanse-vender-dropdown/get`);
    //         if (response.status === 200) {

    //             const dropdownExpenceDD = response.data.decryptExpanseType.map((item: any) => ({
    //                 label: item.expenseName,
    //                 value: item.expenseName,
    //                 id: item.expenseId,
    //             }));

    //             const dropdownVendorName = response.data.decryptVendor.map((item: any) => ({
    //                 label: item.vendorName,
    //                 value: item.vendorName,
    //                 // vendorEmail: item.vendorEmail,
    //                 // vendorContactNumber: item.vendorContactNumber,
    //                 id: item.vendorOverviewId,
    //             }));

    //             console.log(dropdownVendorName, "dropdownVendorName")

    //             const sortedExpenceDD = [...dropdownExpenceDD].sort((a, b) => a.label.localeCompare(b.label));
    //             const sortedvendorNameDD = [...dropdownVendorName].sort((a, b) => a.label.localeCompare(b.label));
    //             console.log(sortedvendorNameDD, "sortedvendorNameDD")

    //             setExpenceDD(sortedExpenceDD);
    //             setVendorNameDD(sortedvendorNameDD);
    //         }
    //     } catch (error) {
    //     }
    // };
    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get(`/vendor-payable/expanse-vender-dropdown/get`);

            if (response.status === 200) {
                const dropdownExpenceDD = response.data.decryptExpanseType.map((item: any) => ({
                    label: item.expenseName,
                    value: item.expenseName,
                    id: item.expenseId,
                }));

                const dropdownVendorName = response.data.decryptVendor
                    .map((item: any) => ({
                        label: item.vendorName,
                        value: item.vendorName,
                        vendorEmail: item.vendorEmail,
                        vendorContactNumber: item.vendorContactNumber,
                        id: item.vendorOverviewId,
                    }))
                    .filter((item: any) => item.label && item.label.trim() !== "");

                // Sort the filtered vendor names
                const sortedExpenceDD = [...dropdownExpenceDD].sort((a, b) => a.label.localeCompare(b.label));
                const sortedvendorNameDD = [...dropdownVendorName].sort((a, b) => a.label.localeCompare(b.label));

                setExpenceDD(sortedExpenceDD);
                setVendorNameDD(sortedvendorNameDD);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // get vendor details by vendor ID
    const getVendorAccountData = async (id: number) => {
        const response = await axiosPrivate.get(`/vendor-payable/Gstin-Account-Ifsc/${id}`);
        if (response.status === 200) {

            const data = response.data;

            if (response.data.decryptGstn.length === 1) {
                SetInvoiceDetails((invoiceDetails: any) => ({
                    ...invoiceDetails,
                    GSTIN: response.data.decryptGstn[0],
                }));
                setGstinDD([]);
            } else {
                const dropdownVendorName = response.data.decryptGstn.map((item: string) => ({
                    label: item,
                    value: item,
                    id: item,
                }));
                setGstinDD(dropdownVendorName);
            }

            SetInvoiceDetails((invoiceDetails: any) => ({
                ...invoiceDetails,
                accountNo: response.data.accountNo,
                IFSC: response.data.ifscCode,
                companyId: response.data.companyId
            }));

            if (data.companyId) {
                await handleTransactionData(data.companyId);
            } else {
                console.error("CompanyId is undefined or null");
            }
        }
    };

    const handleTransactionData = async (id: any) => {
        try {
            const response = await axiosPrivate.get(`/get/company/transaction-charges/details/${id}`)
            if (response.status === 200) {
                setTransactionDetails(response.data.transactionData)
                setRateCardDetails(response.data?.rateCardDetails)

                console.log(response)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    // invoiceCalculator 
    const invoiceCalculator = (baseAmount: any, discount: any, gst: any, tds: any) => {
        let subTotal = parseFloat(baseAmount) - (parseFloat(discount) ? parseFloat(discount) : 0)
        let GST = (subTotal * (parseFloat(gst) ? parseFloat(gst) : 0)) / 100
        let invoiceAmount = subTotal + GST
        let TDS = (subTotal * (parseFloat(tds) ? parseFloat(tds) : 0)) / 100
        let paymentAmount = invoiceAmount - TDS
        SetInvoiceDetails((invoiceDetails: any) => ({
            ...invoiceDetails,
            subTotal: parseFloat(subTotal.toFixed(2)).toString(),
            GST: parseFloat(GST.toFixed(2)).toString(),
            invoiceAmount: parseFloat(invoiceAmount.toFixed(2)).toString(),
            TDS: parseFloat(TDS.toFixed(2)).toString(),
            paymentAmount: parseFloat(paymentAmount.toFixed(2)).toString(),
            amountToVendor: parseFloat(paymentAmount.toFixed(2)).toString(),
        }));
    }

    return (
        <>
            <div>
                <div className='enter-invoice-details'>
                    <div>
                        <div className='wrapper-sub'>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"300px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={invoiceDataErrors.invoiceNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    autoFocus
                                    color={"black"}
                                    type={"text"}
                                    maxLength={16}
                                    inputTitle={'Invoice Number'}
                                    required={true}
                                    disabled={partPaymetFieldsBlock}
                                    placeHolder={'Enter invoice number'}
                                    className={"vendor-input-type"}
                                    inputValue={invoiceDetails.invoiceNumber}
                                    getUser={(value) => {
                                        let invoiceNumber = value.replace(/ /g, "");
                                        SetInvoiceDetails((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            invoiceNumber: invoiceNumber,
                                        }));
                                        dispatch(resetFormModified(true));
                                    }}
                                />
                            </div>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"300px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={invoiceDataErrors.invoiceDate ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"date"}
                                    maxLength={8}
                                    inputTitle={'Invoice Date'}
                                    // required={true}
                                    disabled={partPaymetFieldsBlock}
                                    className={"vendor-input-type"}
                                    max={todayDate}
                                    inputValue={invoiceDetails.invoiceDate}
                                    getUser={(value) => {
                                        if (value.length > 8) {
                                            const year = value.substring(0, 4);
                                            // Extract the year (first 4 characters)
                                            // Check if the year is not greater than a specific limit (e.g., 2100)
                                            if (parseInt(year, 10) > 2100) {
                                                // Prevent updating the state with an invalid year
                                                return;
                                            }
                                            if (year > 999) {
                                                if (parseInt(year, 10) < 1900) {
                                                    // Prevent updating the state with an invalid year
                                                    return;
                                                }
                                            }
                                        }
                                        setMindate(moment(value).format("YYYY-MM-DD"));
                                        SetInvoiceDetails((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            invoiceDate: value,
                                            dueDate: "",
                                        }));
                                        dispatch(resetFormModified(true));

                                    }}
                                />
                            </div>
                        </div>
                        <div className='wrapper-sub'>
                            <div className='vendor-dropdown-type'>
                                <label htmlFor="">Expense Type</label>
                                <div className={invoiceDataErrors.expenseType ? 'setError-vendor-dd' : 'dropdown-vendor-pay'}
                                    style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}} >
                                    <DropdownComponent key={uuidv4()}
                                        options={expenceDD}
                                        isDisabled={partPaymetFieldsBlock}
                                        width={'350px'}
                                        getData={(value: any) => {
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                expenseType: value.value,
                                                expenseTypeID: value.id,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}

                                        defaultValue={[{ label: invoiceDetails.expenseType === "" ? "Select" : invoiceDetails.expenseType, value: invoiceDetails.expenseType }]}
                                    />
                                </div>
                            </div>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"300px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={invoiceDataErrors.dueDate ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"date"}
                                    maxLength={8}
                                    inputTitle={'Due Date'}
                                    min={mindate}
                                    disabled={partPaymetFieldsBlock}
                                    max={financialYearEndDate.format('DD-MM-YYYY')}
                                    className={"vendor-input-type"}
                                    inputValue={invoiceDetails.invoiceDate === "" ? "" : invoiceDetails.dueDate}
                                    getUser={(value) => {
                                        if (value.length > 8) {
                                            const year = value.substring(0, 4);
                                            if (parseInt(year, 10) > 2100) {
                                                return;
                                            }
                                            if (year > 999) {
                                                if (parseInt(year, 10) < 1900) {
                                                    return;
                                                }
                                            }
                                        }
                                        SetInvoiceDetails((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            dueDate: value,
                                        }));
                                        dispatch(resetFormModified(true));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='wrapper-sub'>
                            <div className='wrapper-sub-row'>
                                <div className='vendor-form-radio-input'>
                                    <p className='vendor-radio-label'>Vendor Type</p>
                                    <div style={(true) ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                                        <RadioOrCheckbox
                                            value={"Registered"}
                                            type={"radio"}
                                            name={"Registered"}
                                            margin={"0px 30px 0px 0px"}
                                            checkedValue={invoiceDetails.vendorType}
                                            getVal={(val) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    vendorType: val,
                                                    vendorName: "",
                                                    GSTIN: "",
                                                    accountNo: "",
                                                    IFSC: ""
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                    <div style={(false) ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                                        <RadioOrCheckbox
                                            value={"Unregistered"}
                                            type={"radio"}
                                            name={"Unregistered"}
                                            checkedValue={invoiceDetails.vendorType}
                                            disabled={true}
                                            getVal={(val) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    vendorType: val,
                                                    vendorName: "",
                                                    GSTIN: "",
                                                    accountNo: "",
                                                    IFSC: ""
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* {
                                    invoiceDetails.vendorType === "Registered" && */}

                                {
                                    invoiceDetails.vendorRazorpayNameId ?
                                        <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                            <InputComponent
                                                height={"40px"}
                                                width={"300px"}
                                                margin={"0px 0px 0px 0px"}
                                                padding={"10px"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                inputTitle={'Vendor Name'}
                                                disabled={true}
                                                required={true}
                                                className={"vendor-input-type"}
                                                inputValue={invoiceDetails.vendorRazorpayName}
                                                placeHolder={'vendor Name'}
                                                getUser={(value) => { }}
                                            />
                                        </div>
                                        :
                                        <div className='vendor-dropdown-type'>
                                            <label htmlFor="">Vendor Name<span className='required-red'>*</span></label>
                                            <div className='Vendor-Name'>
                                                <div className={invoiceDataErrors.vendorName ? 'setError-with-plus-vendor' : 'dropdown-vendor-pay-plus'}
                                                    style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                                                    <DropdownComponent key={uuidv4()}
                                                        options={vendorNameDD}
                                                        isDisabled={partPaymetFieldsBlock}
                                                        width={'350px'}
                                                        getData={(value: any) => {
                                                            console.log(value, "VALUE")
                                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                                ...invoiceDetails,
                                                                vendorName: value.value,
                                                                vendorNameID: value.id,
                                                                vendorEmail: value.vendorEmail,
                                                                vendorContactNumber: value.vendorContactNumber,
                                                                GSTIN: "",
                                                            }));
                                                            getVendorAccountData(value.id)
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: invoiceDetails.vendorName === "" ? "Select" : invoiceDetails.vendorName, value: invoiceDetails.vendorName }]}
                                                    />
                                                </div>
                                                <div className='with-plus-vendor' style={(currentActor === "singleUser" && auth.UserPrivilege === "ApprovedCompanyAdmin") ? {} : { pointerEvents: "none", opacity: "0.5" }} onClick={() => {
                                                    if (currentActor === "singleUser" && auth.UserPrivilege === "ApprovedCompanyAdmin") {
                                                        navigate("/organisation/vendor");
                                                    }
                                                }}>
                                                    <BsPlusLg color='#004E9C' height={"14px"} />
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    invoiceDetails.vendorType === "Unregistered" &&
                                    <div>
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 0px 0px 0px"}
                                            padding={"10px"}
                                            border={invoiceDataErrors.vendorName ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={100}
                                            disabled={partPaymetFieldsBlock}
                                            inputTitle={'Vendor Name'}
                                            required={true}
                                            placeHolder={'Enter Vendor Name'}
                                            className={"vendor-input-type"}
                                            inputValue={invoiceDetails.vendorName}
                                            getUser={(value) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    vendorName: value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    invoiceDetails.vendorType === "Registered" &&
                                    <div className='vendor-dropdown-type' style={partPaymetFieldsBlock ? { pointerEvents: "none", opacity: 0.5 } : gstinDD.length > 1 ? {} : { pointerEvents: "none", opacity: 0.5 }} >
                                        <label htmlFor="">GSTIN
                                            {
                                                gstinDD.length > 1 && <span className='required-red'>*</span>
                                            }
                                        </label>
                                        <div className={invoiceDataErrors.GSTIN ? 'setError-vendor-dd' : 'dropdown-vendor-pay'}
                                        // style={(gstinDD.length > 1) ? {} : { pointerEvents: "none", opacity: 0.5, cursor: "auto" }} 
                                        >
                                            <DropdownComponent key={uuidv4()}
                                                options={gstinDD}
                                                isDisabled={partPaymetFieldsBlock ? true : gstinDD.length > 1 ? false : true}
                                                width={'350px'}
                                                getData={(value: any) => {
                                                    SetInvoiceDetails((invoiceDetails: any) => ({
                                                        ...invoiceDetails,
                                                        GSTIN: value.value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                                defaultValue={[{ label: invoiceDetails.GSTIN === "" ? "Select" : invoiceDetails.GSTIN, value: invoiceDetails.GSTIN }]}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    invoiceDetails.vendorType === "Unregistered" &&
                                    <div >
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 0px 0px 0px"}
                                            padding={"10px"}
                                            border={invoiceDataErrors.GSTIN ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={15}
                                            inputTitle={'GSTIN'}
                                            disabled={invoiceDetails.vendorType === "Unregistered" ? false : true}
                                            required={invoiceDetails.vendorType === "Unregistered" ? true : false}
                                            placeHolder={'Enter GSTIN'}
                                            className={"vendor-input-type"}
                                            inputValue={invoiceDetails.GSTIN}
                                            getUser={(value) => {
                                                const gstin = value.toUpperCase().replace(/^[0-9]{2}[A-Z]{5}[0-9]{4}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$/, "")
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    GSTIN: gstin,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                }
                                <div style={invoiceDetails.vendorType === "Registered" ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.accountNo ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={16}
                                        inputTitle={'Account No'}
                                        disabled={invoiceDetails.vendorType === "Unregistered" ? false : true}
                                        required={invoiceDetails.vendorType === "Unregistered" ? true : false}
                                        className={"vendor-input-type"}
                                        inputValue={invoiceDetails.accountNo}
                                        placeHolder={'Enter Account number'}
                                        getUser={(value) => {
                                            const number = value.replace(/[^0-9]/g, "")
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                accountNo: number,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}

                                    />
                                </div>
                                <div style={invoiceDetails.vendorType === "Registered" ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.IFSC ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={11}
                                        inputTitle={'IFSC'}
                                        disabled={invoiceDetails.vendorType === "Unregistered" ? false : true}
                                        required={invoiceDetails.vendorType === "Unregistered" ? true : false}
                                        placeHolder={'Enter IFSC'}
                                        className={"vendor-input-type"}
                                        inputValue={invoiceDetails.IFSC}
                                        getUser={(value) => {
                                            const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                IFSC: ifsc,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div className='vendor-form-radio-input'>
                                    <p className='vendor-radio-label'>Payment Frequency</p>
                                    <div style={(true) ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                                        <RadioOrCheckbox
                                            value={"Immediate"}
                                            type={"radio"}
                                            disabled={partPaymetFieldsBlock}
                                            name={"Immediate"}
                                            checkedValue={invoiceDetails.paymentFrequeny}
                                            margin={"0px 30px 0px 0px"}
                                            getVal={(val) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    paymentFrequeny: val,
                                                    scheduledDate: ""
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                    <RadioOrCheckbox
                                        value={"Scheduled"}
                                        type={"radio"}
                                        name={"Scheduled"}
                                        disabled={partPaymetFieldsBlock}
                                        checkedValue={invoiceDetails.paymentFrequeny}
                                        getVal={(val) => {
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                paymentFrequeny: val,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div style={invoiceDetails.paymentFrequeny === "Immediate" ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.scheduledDate ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"date"}
                                        maxLength={6}
                                        inputTitle={'Scheduled Date'}
                                        min={mindate}
                                        max={financialYearEndDate.format('DD-MM-YYYY')}
                                        required={invoiceDetails.paymentFrequeny === "Immediate" ? false : true}
                                        disabled={invoiceDetails.paymentFrequeny === "Immediate"}
                                        className={"vendor-input-type"}
                                        inputValue={invoiceDetails.scheduledDate}
                                        getUser={(value) => {
                                            if (value.length > 8) {
                                                const year = value.substring(0, 4);
                                                if (parseInt(year, 10) > 2100) {
                                                    return;
                                                }
                                                if (year > 999) {
                                                    if (parseInt(year, 10) < 1900) {
                                                        return;
                                                    }
                                                }
                                            }
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                scheduledDate: value,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}

                                    />
                                </div>
                                <div className='vendor-form-radio-input'>
                                    <p className='vendor-radio-label'>Payment Terms</p>
                                    <div style={(true) ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                                        <RadioOrCheckbox
                                            value={"Full Payment"}
                                            type={"radio"}
                                            checkedValue={invoiceDetails.paymentTerms}
                                            disabled={partPaymetFieldsBlock}
                                            name={"Full Payment"}
                                            margin={"0px 30px 0px 0px"}
                                            getVal={(val) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    paymentTerms: val,
                                                    amountToVendor: invoiceDetails.paymentAmount,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                    <div style={(true) ? {} : { pointerEvents: "none", opacity: "0.5", cursor: "not-allowed" }}>
                                        <RadioOrCheckbox
                                            value={"Part Payment"}
                                            type={"radio"}
                                            name={"Part Payment"}
                                            checkedValue={invoiceDetails.paymentTerms}
                                            getVal={(val) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    paymentTerms: val,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='wrapper-sub-row'>
                                <div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.baseAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={15}
                                        textAlign={"right"}
                                        inputTitle={'Base Amount'}
                                        disabled={partPaymetFieldsBlock}
                                        required={true}
                                        placeHolder={'0'}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.baseAmount)}
                                        getUser={(value) => {
                                            if (value.startsWith('.')) {
                                                value = value.substring(1);
                                            }
                                            let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                baseAmount: baseAmount,
                                            }));
                                            invoiceCalculator(baseAmount, invoiceDetails.discount, invoiceDetails.GSTpercentage, invoiceDetails.TDSpercentage)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.discount ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={15}
                                        textAlign={"right"}
                                        inputTitle={'Discount'}
                                        placeHolder={'0'}
                                        required={false}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.discount)}
                                        disabled={partPaymetFieldsBlock}
                                        getUser={(value) => {
                                            if (value.startsWith('.')) {
                                                value = value.substring(1);
                                            }
                                            let discount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                discount: discount,
                                            }));
                                            invoiceCalculator(invoiceDetails.baseAmount, discount, invoiceDetails.GSTpercentage, invoiceDetails.TDSpercentage)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={6}
                                        disabled={true}
                                        textAlign={"right"}
                                        placeHolder={'0'}
                                        inputTitle={'Sub Total'}
                                        required={false}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.subTotal)}
                                        getUser={(value) => {
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                subTotal: value,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div className='GSt-TDS'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"60px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={2}
                                        disabled={partPaymetFieldsBlock}
                                        inputTitle={'GST'}
                                        placeHolder={'0'}
                                        required={false}
                                        className={"vendor-input-type-gst"}
                                        inputValue={invoiceDetails.GSTpercentage}
                                        getUser={(value) => {
                                            const GSTpercentage = value.replace(/[^0-9]/g, "")
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                GSTpercentage: GSTpercentage,
                                            }));
                                            invoiceCalculator(invoiceDetails.baseAmount, invoiceDetails.discount, GSTpercentage, invoiceDetails.TDSpercentage)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    <span className='percentage-symbol'>%</span>
                                    <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                        <InputComponent
                                            height={"40px"}
                                            width={"220px"}
                                            margin={"0px 0px 0px 0px"}
                                            padding={"10px"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            textAlign={"right"}
                                            disabled={true}
                                            placeHolder={'0'}
                                            maxLength={6}
                                            inputValue={formatAmount(invoiceDetails.GST)}
                                            getUser={(value) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    GST: value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={16}
                                        disabled={true}
                                        placeHolder={'0'}
                                        textAlign={"right"}
                                        inputTitle={'Invoice Amount'}
                                        required={false}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.invoiceAmount)}
                                        getUser={(value) => {
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                invoiceAmount: value,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div className='GSt-TDS'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"60px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={2}
                                        inputTitle={'TDS'}
                                        placeHolder={'0'}
                                        disabled={partPaymetFieldsBlock}
                                        required={false}
                                        className={"vendor-input-type-gst"}
                                        inputValue={invoiceDetails.TDSpercentage}
                                        getUser={(value) => {
                                            const TDSpercentage = value.replace(/[^0-9]/g, "")
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                TDSpercentage: TDSpercentage,
                                            }));
                                            invoiceCalculator(invoiceDetails.baseAmount, invoiceDetails.discount, invoiceDetails.GSTpercentage, TDSpercentage)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    <span className='percentage-symbol'>%</span>
                                    <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                        <InputComponent
                                            height={"40px"}
                                            width={"220px"}
                                            margin={"0px 0px 0px 0px"}
                                            padding={"10px"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            placeHolder={'0'}
                                            textAlign={"right"}
                                            disabled={true}
                                            maxLength={16}
                                            inputValue={formatAmount(invoiceDetails.TDS)}
                                            getUser={(value) => {
                                                SetInvoiceDetails((invoiceDetails: any) => ({
                                                    ...invoiceDetails,
                                                    TDS: value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={15}
                                        password={'0'}
                                        inputTitle={'Payment Amount'}
                                        required={false}
                                        disabled={true}
                                        placeHolder={'0'}
                                        textAlign={"right"}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.paymentAmount)}
                                        getUser={(value) => {
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                paymentAmount: value,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div style={invoiceDetails.paymentTerms === "Full Payment" ? { pointerEvents: "none", opacity: "0.5" } : {}}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={invoiceDataErrors.amountToVendor ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={15}
                                        placeHolder={'0'}
                                        textAlign={"right"}
                                        disabled={invoiceDetails.paymentTerms === "Full Payment"}
                                        inputTitle={'Amount to Vendor'}
                                        required={invoiceDetails.paymentTerms === "Full Payment" ? false : true}
                                        className={"vendor-input-type"}
                                        inputValue={formatAmount(invoiceDetails.amountToVendor)}
                                        getUser={(value) => {
                                            if (value.startsWith('.')) {
                                                value = value.substring(1);
                                            }
                                            let amountToVendor = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                amountToVendor: amountToVendor,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='UploadInvoice-wrapper' style={partPaymetFieldsBlock ? { pointerEvents: "none", opacity: "0.5" } : {}}>
                        <UploadInvoice
                            getFile={(val: File) => { getFile(val) }}
                            setShowDeleteModal={(val: boolean) => { setShowDeleteModal(val) }}
                            setInvoiceShowmodal={(val: any) => { setInvoiceShowmodal(val) }}
                            invoice={invoice}
                        />
                        <div>
                            <div style={{ pointerEvents: "none", opacity: 1 }}>
                                <InputComponent
                                    height={"40px"}
                                    width={"300px"}
                                    margin={"0px 0px 30px 0px"}
                                    padding={"10px"}
                                    border={"1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    maxLength={15}
                                    inputTitle={'Amount Paid'}
                                    textAlign={"right"}
                                    required={false}
                                    placeHolder={'0'}
                                    disabled={true}
                                    inputValue={formatAmount(invoiceDetails.amountPaid)}
                                    getUser={(value) => {
                                        SetInvoiceDetails((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            amountPaid: value,
                                        }));
                                        dispatch(resetFormModified(true));
                                    }}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ pointerEvents: "none", opacity: 1 }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"260px"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={15}
                                        inputTitle={'Amount Due'}
                                        textAlign={"right"}
                                        placeHolder={'0'}
                                        required={false}
                                        disabled={true}
                                        inputValue={formatAmount(invoiceDetails.amountDue)}
                                        getUser={(value) => {

                                            SetInvoiceDetails((invoiceDetails: any) => ({
                                                ...invoiceDetails,
                                                amountDue: value,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div>
                                    <IconButton
                                        iconName={"ShowEye"}
                                        height={"40px"}
                                        width={"40px"}
                                        fontSize={"20px"}
                                        margin={"27px 0px 0px 0px"}
                                        color={"white"}
                                        borderRadius={"0px"}
                                        className={"invoice-upload-eye"}
                                        backgroundColor={"#004E9C"}
                                        hover={true}
                                        handleClick={() => { setInvoiceShowmodal(true) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='footer-vendor-pb'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"48px"}
                        width={"150px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        margin={"0px 8px"}
                        className={"button-component-hover cancel"}
                        handleClick={() => {
                            enterInvoiceBack()
                        }}
                    />

                    {
                        (!isEditMode || editData?.payableInvoice?.isDraft === "Yes") &&
                        <ButtonComponent
                            title={"Save as Draft"}
                            height={"48px"}
                            width={"150px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            className={"button-component-hover common-btn"}
                            handleClick={handleSaveForm}
                        />
                    }

                    <ButtonComponent
                        title={"Next"}
                        height={"48px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 8px"}
                        className={"button-component common-btn"}
                        handleClick={() => {
                            enterInvoiceNext()
                        }}
                    />
                </div>
            </div >
        </>
    )
}

export default EnterInvoiceDetails;