import React, { useState, useEffect, useRef, useCallback } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import IconButton from '../../common-component/form-elements/icon-button';
// import { vendorColumnData, rowDatathis } from '../../../config/payable/vendor/vendor-grid';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import "../../../styles/payable/vendor/vendor-grid.scss"
// import ColumnHeadingsFillterIcon from '../../common-component/grids-and-tables/column-headings-fillter-icon';
import { v4 as uuidv4 } from 'uuid';
import { axiosPrivate } from '../../../middleware/axois-api';
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteModal from '../../common-component/modals/delete-modal';
import AlertBox from '../../common-component/alert-box';
import cellEyeIcon from '../../common-component/grids-and-tables/cell-eye-icon';
import VendorPaymentInvoice from '../../common-component/modals/vendor-payment-invoice';
import useAuth from '../../../services/hooks/useauth';
import FileUpload from '../../common-component/modals/file-upload-modal';
import Loading from '../../common-component/modals/loading-screen';
import { decryptToken } from '../../../services/utils/token-utils';
import { Filter } from '../../../helpers/filtterFunction';
import { DateConverter, DateConverterAgGrid } from '../../../helpers/capitalizetext';
import PaymentResultModal from './payment-result-popup';
import { AmountRenderer, formatAmount } from '../../../helpers/amountRenderer';
import ButtonComponent from '../../common-component/form-elements/button-component';
import { RxCross1 } from 'react-icons/rx';
import InvoiceDeclinemodal from '../../common-component/modals/invoice-decline-modal';
import DoumentRemarksmodal from '../../common-component/modals/document-remarks-modal';




interface Props {
    vendorVewFun: (val: any) => void;
    loggedUser: (val: any) => void;
    getEditData: (val: any, isEditMode: boolean, nav: string) => void;
    SetEditData: (val: any, isEditMode: boolean) => void;
    setUserPrivilege: (val: any) => void;
    approvalProcessFlow: any[]
    currentActor: string
    SetIsBulkPayment: (val: any) => void;
    editData: any
}

interface IFilterValues {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;

    };
}

export default function VendorGrid({ vendorVewFun, loggedUser, setUserPrivilege, getEditData, SetEditData, editData, SetIsBulkPayment, approvalProcessFlow, currentActor }: Props) {

    const navigate = useNavigate();
    const parentGridDownloadRef = useRef<AgGridRef>(null);
    const DownloadRef = useRef<AgGridRef>(null);
    const [rowData, setRowData] = useState<any>([]);                                                         // row data for parent grid
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [vendorEyeShowmodal, setVendorEyeShowModal] = useState<boolean>(false);
    const [modifiedParentColumn, setModifiedParentColumn] = useState<any>([]);
    const [declinePopUp, setDeclinePopUp] = useState<boolean>(false);
    const [declineRemarks, setDeclineRemarks] = useState<string>("");
    const [filter, setFilter] = useState(false);
    const [filterRestore, setFilterRestore] = useState<IFilterValues>({});
    const [filterMethod, setFilterMethod] = useState("");
    const [curruntData, setCurruntData] = useState<any[]>([]);
    const [curruntSelectedVendorData, setCurruntSelectedVendorData] = useState<any>();
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [messagePopUp, setMessagePopUp] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning"); // error message showType
    const [status, setStatus] = useState<string>("")
    const [showMessage, setShowMessage] = useState<string>("");// error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false); // error message ShowAlertBox
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false);
    const [showremarksModal, setShowRemarksModal] = useState<boolean>(false);
    const [currentRemarks, setCurrentRemarks] = useState<string>("");
    const [transactionsID, setTransactionsID] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<boolean>(false);
    const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
    const templateLink = "bulkupload_templates/bulk-payment-template.xlsx";
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const message: any = (queryParams.get('message'));
    const id: any = (queryParams.get('id'));

    useEffect(() => {
        // fetchData();
        loggedUserCall();
        getInvoices();
        if (message) {
            let newvmessage = decryptToken(message)
            setTransactionsID(id)
            if (newvmessage === "Success") {
                // setShowAlertBox(true)
                setStatus('Paid')
                setMessagePopUp(true)
                setShowType("Payment Success!")
                setShowMessage(`Your transaction has been executed flawlessly.`)
                const timer = setTimeout(() => {
                    // setShowAlertBox(false);
                    // setMessagePopUp(false);
                    // setShowMessage("");
                    // clearTimeout(timer);
                    navigate('/payable/vendor')
                }, 5000);
            } else if (newvmessage === "Rejected") {
                // setShowAlertBox(true)
                setMessagePopUp(true)
                setStatus('Failed')
                setShowType("Payment Declined")
                setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
                const timer = setTimeout(() => {
                    // setShowAlertBox(false);
                    // setMessagePopUp(false);
                    // setShowMessage("");
                    // clearTimeout(timer);
                    navigate('/payable/vendor')
                }, 5000);
            }
        }
    }, []);

    const loggedUserCall = async () => {
        setLoadingScreen(true);
        try {
            const response = await axiosPrivate.get(`/vendor-payable/logedin-user`);
            if (response.status === 200) {
                loggedUser(response.data.userData)
                // const filteredColumns = removeFieldsByUserType(response.data.userData.approvalProcessFlow);
                // setModifiedParentColumn(filteredColumns);
                setLoadingScreen(false);
            }
        } catch (error) {
            setLoadingScreen(false);
        }
    }

    const clearValue = (val: any) => {
        setFileUpload(val);
    }

    function removeFieldsByUserType(userTypeArray: any) {
        const fieldsToRemove: any = [];
        if (userTypeArray.includes("singleUser")) {
            fieldsToRemove.push("checker", "checkerStatus", "recommender", "recommenderStatus");
        } else if (userTypeArray.includes("maker") && userTypeArray.includes("checker") && userTypeArray.includes("approver") && userTypeArray.includes("recommender")) {
            fieldsToRemove.push();
        } else if (userTypeArray.includes("maker") && userTypeArray.includes("checker") && userTypeArray.includes("approver")) {
            fieldsToRemove.push("recommender", "recommenderStatus");
        } else if (userTypeArray.includes("maker") && userTypeArray.includes("approver")) {
            fieldsToRemove.push("checker", "checkerStatus", "recommender", "recommenderStatus");
        }
        const filteredColumns = vendorColumnData.filter(column => !fieldsToRemove.includes(column.field));
        return filteredColumns;
    }

    const getUploadedUserData = (val: any) => {
        setuploadedDataStore(val);
    }

    // const values for vendor top grid column values
    const vendorSummaray = [
        {
            field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: true, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: false, cellStyle: { textAlign: 'center' }
        },
        {
            field: 'vendorName', headerName: 'Vendor Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'invoiceCount', headerName: '#Invoice', minWidth: 150, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'outstanding', headerName: 'Outstanding', headerClass: 'header-right-align',
            minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        },
        {
            field: 'overdue', headerName: 'Overdue', headerClass: 'header-right-align',
            minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        },
        // {
        //     field: 'draft', headerName: 'Draft', headerClass: 'header-right-align',
        //     minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        {
            field: 'scheduled', headerName: 'Scheduled', headerClass: 'header-right-align',
            minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        },
        {
            field: 'processing', headerName: 'Processing', headerClass: 'header-right-align',
            minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        },
        {
            field: 'paid', headerName: 'Paid', headerClass: 'header-right-align',
            minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        },
    ]

    // const rowData = [
    //     {
    //         number: "Inv019",
    //         status: "Approved",
    //         createdAt: "09/09/2024 10:00",
    //         companyName: "Sharon Industry",
    //         id: "pay_KLIPlOIbEUMXZE",
    //         status1: "Captured",
    //         orderId: "order_OvlNEsvAZuOz3h",
    //         status2: "Scheduled",
    //         date: "11/09/2024 16:00",
    //         utr: "",
    //         settlementId: "",
    //         amount: "43,200.00",
    //         amountToVendor: "43,200.00",
    //         amountDue: "0",
    //         remarks: "HAIIIIIII"
    //     },
    //     {
    //         number: "Inv020",
    //         status: "Approved",
    //         createdAt: "09/09/2024 10:00",
    //         companyName: "Sharon Industry",
    //         id: "pay_KLIPlOIbEUMXZE",
    //         status1: "Captured",
    //         orderId: "order_OvlNEsvAZuOz3h",
    //         status2: "Scheduled",
    //         date: "11/09/2024 16:00",
    //         utr: "",
    //         settlementId: "",
    //         amount: "43,500.00",
    //         amountToVendor: "43,200.00",
    //         amountDue: "0",
    //         remarks: ""
    //     },
    // ]

    const vendorColumnData = [
        {
            field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
        },

        {
            field: 'Invoice Details', headerName: 'Invoice Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                const statusColor = (e.data.approvalStatus === "Approved" || e.data.approvalStatus === "Completed") ? "green" : e.data.approvalStatus === "Declined" ? "red" : "#0055D4";
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Number:</span> {e.data.invoiceNumber}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.approvalStatus}</span>
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Created date:</span> {e.data.invoiceDate}
                        </div>
                    </div>
                )
            },
        },
        // { 
        //     field: 'draft', headerName: 'Draft', minWidth: 100, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        {
            field: 'Company Name', headerName: 'Company Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div>{e.data.companyName}</div>
                    </div>
                )
            },
        },
        {
            field: 'Payment Details', headerName: 'Payment Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                const statusColor = e.data.payment_Status === "Captured" ? "green" : e.data.payment_Status === "Failed" ? "red" : "black";
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>ID:</span> {e.data.razorpayPaymentId}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.payment_Status}</span>
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Order ID:</span> {e.data.razorpayOrderId}
                        </div>
                    </div>
                )
            },
        },
        {
            field: 'Settlement Details', headerName: 'Settlement Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                const statusColor = e.data.settlementStatus === "Scheduled" ? "#0c1dff" : e.data.settlementStatus === "Settled" ? "green" : "black";
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.settlementStatus}</span>
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Date:</span> {DateConverter(e.data.settlementDate)}
                        </div>
                    </div>
                )
            },
        },
        {
            field: 'Reference No', headerName: 'Reference No', minWidth: 380, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>UTR:</span> {e.data.utrNumber}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Settlement ID:</span> {e.data.settlementId}
                        </div>
                    </div>
                )
            },
        },
        {
            field: 'Amount Details', headerName: 'Amount Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
            cellRenderer: (e: any) => {
                return (
                    <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Payable Amount:</span> {e.data.paymentAmount}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Amount to Vendor:</span> {e.data.amountToVendor}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Amount Paid:</span> {e.data.amountPaid}
                        </div>
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span style={{ fontWeight: "500" }}>Amount Due:</span> {e.data.amountDue}
                        </div>
                    </div>
                )
            },
        },
        // {
        //     field: 'referenceNumber', headerName: 'Reference No', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'draft', headerName: 'Draft', headerClass: 'header-right-align', minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'invoiceNumber', headerName: 'Invoice Number', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },

        // {
        //     field: 'invoiceCount', headerName: '#Invoice', minWidth: 150, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'invoiceDate', headerName: 'Invoice Date', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: DateConverterAgGrid,
        // },
        // {
        //     field: 'dueDate', headerName: 'Due Date', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: DateConverterAgGrid,
        // },
        // {
        //     field: 'invoiceCount', headerName: 'Invoice Count', minWidth: 150, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'paymentAmount', headerName: 'Payment Amount', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'amountToVendor', headerName: 'Amount to Vendor', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },

        // {
        //     field: 'Scheduled Amount', headerName: 'Scheduled Amount', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'paid', headerName: 'Amount Paid', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'overdue', headerName: 'Overdue', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'outstandingDays', headerName: 'Outstanding Days', headerClass: 'header-right-align', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number",
        // },
        // {
        //     field: 'overdue', headerName: 'Overdue', headerClass: 'header-right-align', minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },

        // {
        //     field: 'scheduled', headerName: 'Scheduled', headerClass: 'header-right-align', minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'processing', headerName: 'Processing', headerClass: 'header-right-align',
        //     minWidth: 120, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'paid', headerName: 'Paid', headerClass: 'header-right-align', minWidth: 170, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
        // },
        // {
        //     field: 'checker', headerName: 'Checker', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'checkerStatus', headerName: 'Checker Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Approved',
        //         'submitted-cell': (params: any) => params.value === 'Submitted',
        //         'denied-cell': (params: any) => params.value === 'Declined',
        //         'registered-cell': (params: any) => params.value === 'Pending Approval',
        //     },
        // },
        // {
        //     field: 'recommender', headerName: 'Recommender', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'recommenderStatus', headerName: 'Recommender Status', minWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Approved',
        //         'submitted-cell': (params: any) => params.value === 'Submitted',
        //         'denied-cell': (params: any) => params.value === 'Declined',
        //         'registered-cell': (params: any) => params.value === 'Pending Approval',
        //     },
        // },
        // {
        //     field: 'approver', headerName: approvalProcessFlow.includes("singleUser") ? "Approver" : 'Approver', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        // {
        //     field: 'transactionStatus', headerName: 'Transaction Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Approved',
        //         'submitted-cell': (params: any) => params.value === 'Submitted',
        //         'denied-cell': (params: any) => params.value === 'Declined',
        //         'registered-cell': (params: any) => params.value === 'Pending Approval',
        //     },
        // }, 
        // {
        //     field: 'transactionStatus', headerName: 'Transaction Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Approved',
        //         'submitted-cell': (params: any) => params.value === 'Submitted',
        //         'denied-cell': (params: any) => params.value === 'Declined',
        //         'registered-cell': (params: any) => params.value === 'Pending Approval',
        //     },
        // },
        // {
        //     field: 'approvalStatus', headerName: 'Approval Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Completed',
        //         // 'submitted-cell': (params: any) => params.value === 'Submitted',
        //         'denied-cell': (params: any) => params.value === 'Declined',
        //         'registered-cell': (params: any) => params.value === 'Processing',
        //     },
        // },
        // {
        //     field: 'paymentStatus', headerName: 'Payment Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        //         'success-cell': (params: any) => params.value === 'Fully Paid',
        //         'submitted-cell': (params: any) => params.value === 'Initiated',
        //         'denied-cell': (params: any) => params.value === 'Failed',
        //         'registered-cell': (params: any) => params.value === 'Not Initiated' || params.value === 'Initiated',
        //     },
        // },
        {
            field: 'Action',
            headerName: 'Action',
            minWidth: 150,
            resizable: false,
            pinned: "right",
            suppressMovable: true,
            filter: false,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center', color: "#0055d5" },
            filtertype: "text",
            cellRendererFramework: cellEyeIcon,
            cellRendererParams: (params: any) => {
                const handleIconClick = async (iconType: string, deleteIcon: string) => {
                    if (iconType === "eye") {
                        try {
                            const response = await axiosPrivate.get(`/vendor-payable/vendor/invoices/${params.data.invoicePayableId}`);
                            SetEditData && SetEditData(response.data, false);
                            setVendorEyeShowModal(!vendorEyeShowmodal);
                        } catch (error) {
                        }
                    } else if (iconType === "remarks") {
                        try {
                            setShowRemarksModal(!showremarksModal)
                            setCurrentRemarks(params.data.remarks)
                        } catch (error: any) {

                        }
                    }
                    if (deleteIcon === "delete" || iconType === "delete") {
                        try {
                            setShowDeleteModel(!showDeleteModel)
                            setCurrentRemarks(params.data.draft)
                        } catch (error: any) { }
                    }
                };
                return {
                    handleIconClick: handleIconClick,
                    iconType: "remarks",
                    deleteIcon: "delete",
                    remarks: params.data.remarks,
                    isDraft: params.data.draft
                };
            }
        }
    ]

    // Function for Filter values
    const handleFilter = (value: any, filterType: string) => {
        Filter(value, filterType, orginalRowData, setRowData)
    }


    const groupInvoiceDeclain = async (remarks: string) => {
        setLoadingScreen(true);
        try {
            const res = await axiosPrivate.patch(`/vendor-payable/invoice/update-group-payment`,
                {
                    selectedPaymentMethod: {
                        PaymentMethod: "",
                        virtualcardId: "",
                        cardId: "",
                    },
                    approvalStatus: {
                        status: "Declined",
                        remarks: remarks,
                    },
                    groupInvoices: curruntData
                }
            );
            if (res.status === 200) {
                getInvoices()
                setCurruntData([])
                setDeclineRemarks('')
                setLoadingScreen(false);
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage('Your action has been successfully recorded.');
                clearAleart()
            }
        }
        catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.response.data.message)
        }
    };

    const handleDownloadExcel = () => {
        DownloadRef.current!.onBtExport();
    }

    // Download Excel Files 
    const handleDownloadSummary = () => {
        return;
        parentGridDownloadRef.current!.onBtExport();
    }


    async function getUserData(data: any) {
        try {
            const response = await axiosPrivate.get(`/vendor-payable/vendor/invoices/${data}`);

            console.log(currentActor, "currentActor")
            getEditData && getEditData(response.data, true, "form");
            if (response.data.bulkInvoices && response.data.bulkInvoices.length > 0) {
                SetIsBulkPayment(true);
            } else {
                SetIsBulkPayment(false);
            }
        } catch (error) {
        }
    }

    // vendor-payable/Summary/invoices
    // const fetchData = async () => {
    //     try {
    //         const response = await axiosPrivate.get(`/vendor-payable/invoices`);
    //         setSummaryRowData(response.data.payableInvoices);
    //         setOrginalSummaryRowData(response.data.payableInvoices);
    //     } catch (error) {
    //     }
    // };

    //clear popup 
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            // setOtpVerification(false) 
            clearTimeout(timer);
        }, 5000);
    }

    // delete function for Delete the grid Data (Single delete)
    const getDelete = async () => {
        if (curruntData.length === 1) {
            axiosPrivate.patch(`/vendor-payable/invoice/delete/${curruntData[0].invoicePayableId}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowAlertBox(true)
                        setShowType("success")
                        setShowMessage(response.data.message)
                        clearAleart()
                        setShowDeleteModel(false)
                        // fetchData()
                        setCurruntData([]);
                    }
                })
                .catch(error => {
                    setShowAlertBox(true);
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                    setShowDeleteModel(false)
                    clearAleart()
                });
        }
    };

    // To store the selected data 
    const getCurruntData = useCallback((val: any) => {
        setCurruntData(val);
    }, [])

    // To store the summary grid  selected data 
    const getVendor = useCallback(async (val: any) => {
        if (val.vendorName !== "Bulk Payment" || val.vendorOverviewId !== null) {
            if (val.vendorOverviewId) {
                try {
                    const response = await axiosPrivate.get(`/vendor-payable/invoice-details/${val.vendorOverviewId}`);
                    setRowData(response.data.payableInvoices);
                    setOrginalRowData(response.data.payableInvoices);
                    setCurruntSelectedVendorData(val);
                } catch (error) {
                }
            }
        } else {
            setCurruntSelectedVendorData("");
            getUserData(val.invoicePayableId)
        }
    }, [])

    const getInvoices = async () => {
        try {
            const response = await axiosPrivate.get(`/vendor-payable/vendor/invoice-details`);
            setRowData(response.data.payableInvoices);
            setOrginalRowData(response.data.payableInvoices);
        } catch (error) {
        }
    }

    // After success fulley done the file upload for bulk payment system  nav to bulk payment screen  
    const afterFileDownload = async (fileResponsce: any) => {
        if (fileResponsce.invoice_payable_id) {
            try {
                const response = await axiosPrivate.get(`/vendor-payable/invoices/${fileResponsce.invoice_payable_id}`);
                if (response.data.bulkInvoices && response.data.bulkInvoices.length > 0) {
                    getEditData && getEditData(response.data, false, "form");
                    SetIsBulkPayment(true)
                } else {
                    SetIsBulkPayment(false)
                }
            } catch (error) {
            }
        } else {
            setShowAlertBox(true);
            setShowType("warning");
            setShowMessage(fileResponsce.message);
            clearAleart()
        }
    }

    // show message while uploading file 
    const UploadMessage = (message: any, type: any) => {
        setShowMessage(message)
        setShowAlertBox(true);
        setShowType(type);
        clearAleart()
    }

    return (
        <>
            <MainPageCard >
                {
                    currentActor !== "" &&
                    <div>
                        <div className='header-grid-Payment'>
                            <div className='left-header'>
                                <NavigateHeader firstValue={"Payable"}
                                    secondValue={"Vendor"}
                                />
                            </div>
                            <div className='icon-buttons'>
                                <div style={{ display: "flex" }}>
                                    <IconButton
                                        iconName={"Filter"}
                                        height={"40px"}
                                        width={"40px"}
                                        fontSize={"30px"}
                                        margin={"0px 8px"}
                                        color={"white"}
                                        backgroundColor={"#0055D4"}
                                        hover={true}
                                        cursor={"pointer"}
                                        handleClick={(event: React.MouseEvent) => {
                                            event?.stopPropagation()
                                            setFilter(!filter);
                                        }}
                                    />
                                </div>
                                {/* <IconButton
                                    iconName={"Delete"}
                                    height={"40px"}
                                    width={"40px"}
                                    fontSize={"25px"}
                                    margin={"0px 8px"}
                                    color={"white"}
                                    backgroundColor={"#D60000"}
                                    hover={curruntData.length > 0 && currentActor !== "Maker"}
                                    opacity={(curruntData.length > 0 && currentActor !== "Maker") ? "1" : "0.5"}
                                    cursor={(curruntData.length > 0 && currentActor !== "Maker") ? "pointer" : "not-allowed"}
                                    handleClick={() => {
                                        if ((curruntData.length > 0 && currentActor !== "Maker")) {
                                            setShowDeleteModel(!showDeleteModel)
                                        }
                                    }}
                                /> */}
                                <IconButton
                                    iconName={"Invoice"}
                                    height={"40px"}
                                    width={"100px"}
                                    fontSize={"25px"}
                                    margin={"0px 8px"}
                                    opacity={(currentActor === "Maker" || currentActor === "singleUser") ? "1" : " 0.5"}
                                    color={"white"}
                                    backgroundColor={"#0055D4"}
                                    disabled={(currentActor === "Maker" || currentActor === "singleUser") ? false : true}
                                    hover={(currentActor === "Maker" || currentActor === "singleUser") ? true : false}
                                    cursor={(currentActor === "Maker" || currentActor === "singleUser") ? "pointer" : "not-allowed"}
                                    handleClick={() => {
                                        if (currentActor === "Maker" || currentActor === "singleUser") {
                                            vendorVewFun("form")
                                            getEditData && getEditData({}, false, "form");
                                            SetIsBulkPayment(false)
                                        }
                                    }}
                                />
                                <IconButton
                                    iconName={"Upload"}
                                    height={"40px"}
                                    width={"40px"}
                                    fontSize={"25px"}
                                    margin={"0px 0px 0px 8px"}
                                    color={"white"}
                                    backgroundColor={"#0055D4"}
                                    // opacity={(currentActor === "Maker" || currentActor === "singleUser") ? "1" : " 0.5"}
                                    opacity={"0.5"}
                                    // disabled={(currentActor === "Maker" || currentActor === "singleUser") ? false : true}
                                    disabled={true}
                                    // hover={(currentActor === "Maker" || currentActor === "singleUser") ? true : false}
                                    hover={false}
                                    // cursor={(currentActor === "Maker" || currentActor === "singleUser") ? "pointer" : "not-allowed"}
                                    cursor={"not-allowed"}
                                    handleClick={() => {
                                        return
                                        if (currentActor === "Maker" || currentActor === "singleUser") {
                                            setFileUpload(true);
                                        }
                                    }}
                                />
                                <IconButton
                                    iconName={"Download"}
                                    height={"40px"}
                                    width={"40px"}
                                    fontSize={"25px"}
                                    margin={"0px 8px"}
                                    color={"white"}
                                    backgroundColor={"#0055D4"}
                                    hover={false}
                                    disabled={true}
                                    cursor={"not-allowed"}
                                    opacity={"0.5"}
                                    handleClick={handleDownloadSummary}
                                />
                            </div>
                        </div>

                        <div>
                            <div key={modifiedParentColumn} className='summary-view'>
                                <AgGrid
                                    ref={parentGridDownloadRef}
                                    rowHeight={140}
                                    fileName={"Payment-processes-details"}
                                    tabelRowData={rowData}
                                    tableColumnData={currentActor === "singleUser" ? vendorColumnData.filter((obj: any) =>
                                        obj.field !== "approvalStatus" &&
                                        obj.field !== "checker" &&
                                        obj.field !== "recommender" &&
                                        obj.field !== "checkerStatus" &&
                                        obj.field !== "recommenderStatus" &&
                                        obj.field !== "approver"
                                    ) : vendorColumnData

                                    }

                                    displaylength={25}
                                    doubleClickUpdate={() => {
                                        // getEditData && getEditData("", false);
                                    }}
                                    // singleSelect={true}
                                    getEdit={(data) => getUserData(data.invoicePayableId)}
                                    getCurruntData={(data: any) => {
                                        if (Array.isArray(data)) {
                                            const foundedObj = data.find((Obj: any) => Obj.InvoiceCategory
                                                === "Bulk Summary");
                                            if (foundedObj) {
                                                getCurruntData([])
                                            } else {
                                                getCurruntData(data);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/* {
                            curruntData.length > 1 && (currentActor === "Approver" || currentActor === "singleUser") &&
                            <>
                                <div className='display-total'>
                                    <div className='contant'>
                                        Selected Invoices: {curruntData.length}
                                    </div>
                                    <div className='contant'>
                                        Total Selected Amount: ₹{formatAmount(curruntData.reduce((acc: any, obj: any) => { return acc + parseFloat(obj.amountToVendor); }, 0))}
                                    </div>
                                </div>
                                <div className='makepayment-button'>
                                    {
                                        currentActor !== "singleUser" &&
                                        <div className='new-clase'>
                                            <ButtonComponent
                                                title={<>
                                                    <RxCross1 color={true ? '#0055D4' : "#ffff"}
                                                        style={{ verticalAlign: "middle", marginRight: "5px" }}
                                                        className='add-icon' />   Decline
                                                </>}
                                                height={"50px"}
                                                width={"150px"}
                                                backgroundColor={"white"}
                                                border={!true ? "" : "1px solid #0055D4"}
                                                color={true ? '#0055D4' : "#ffff"}
                                                margin={"0px 8px"}
                                                className={!true ? "button-component-hover disabled" : "button-component common-btn"}
                                                handleClick={() => {
                                                    setDeclinePopUp(true);
                                                }}
                                            />
                                        </div>
                                    }
                                    <ButtonComponent
                                        title={"Proceed Payment"}
                                        height={"50px"}
                                        width={"160px"}
                                        backgroundColor={"#0055D4"}
                                        color={"white"}
                                        margin={"0px 8px"}
                                        className={"button-component common-btn"}
                                        handleClick={() => {
                                            getEditData && getEditData(curruntData, true, "groupPay");
                                        }}
                                    />
                                </div>
                            </>
                        } */}

                        {
                            filter &&
                            <div className='filter' style={{ zIndex: 2 }} key={uuidv4()} >
                                <GridFilter
                                    filterRestore={filterRestore}
                                    filterMethod={filterMethod}
                                    tabelRowData={rowData}
                                    data={vendorColumnData.slice(1)}
                                    handleClick={(Value: any, filterType: string) => {
                                        if (filterType !== "") {
                                            setFilterRestore(Value);
                                            setFilter(!filter);
                                            setFilterMethod(filterType)
                                            handleFilter(Value, filterType)
                                            setCurruntData([]);
                                        } else {
                                            setFilterMethod("")
                                            setFilterRestore({})
                                            setFilter(!filter)
                                            setRowData(orginalRowData)
                                            setCurruntData([]);
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                }
                {
                    currentActor === "" &&
                    <div key={uuidv4()} style={{ display: "flex", fontSize: "30px", justifyContent: "center", alignItems: "center", height: "63vh" }}>
                        No Access for this page. Please contact Administrator if needed.
                    </div>
                }
            </MainPageCard>
            {
                declinePopUp &&
                <InvoiceDeclinemodal
                    clearDeclinemodal={(val: boolean) => {
                        setDeclinePopUp(val)
                    }}
                    setDeclineRemarks={(val: string) => setDeclineRemarks(val)}
                    declineRemarks={declineRemarks}
                    Submit={() => {
                        groupInvoiceDeclain(declineRemarks);
                        setDeclinePopUp(false);
                    }}
                />
            }
            {showDeleteModel &&
                <DeleteModal
                    clearValue={(val: boolean) => { setShowDeleteModel(val); }}
                    getDelete={() => getDelete()}
                    modelType={"grid-delete"}
                />}

            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
            {vendorEyeShowmodal &&
                <VendorPaymentInvoice
                    type={"vendorLoginInvoice"}
                    invoice={editData.payableInvoice.invoice}
                    currentActor={currentActor}
                    approvalHistory={editData.approvalStatus}
                    paymentHistory={editData.paymentHistory}
                    invoiceDetails={editData.payableInvoice}
                    closeDocument={(val: boolean) => { setVendorEyeShowModal(val); }}
                />}
            {
                fileUpload &&
                <FileUpload
                    clearValue={clearValue}
                    headerData={modifiedParentColumn.slice(1)}
                    templateName={"User"}
                    template={templateLink}
                    excelFileName={"Bulk-Payment"}
                    getUploadedUserData={getUploadedUserData}
                    apiUrl={'/bulk-upload/vendor-payment'}
                    afterFileDownload={afterFileDownload}
                    loadingScreenFunction={(val: boolean) => { setLoadingScreen(val) }}
                    setshowUploadMessage={UploadMessage}
                />
            }
            {
                loadingScreen &&
                <Loading />
            }
            {
                messagePopUp &&
                <PaymentResultModal
                    closeModal={() => { setMessagePopUp(false) }}
                    handleOk={() => setMessagePopUp(false)}
                    transactionsID={transactionsID}
                    message={showMessage}
                    type={showType}
                    status={status}
                />
            }
            {showremarksModal &&
                <DoumentRemarksmodal
                    clearDoumentRemarksmodal={(val: boolean) => { setShowRemarksModal(val); }}
                    remarks={currentRemarks}
                />}
        </>
    )
}
