import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, } from 'react'
import InputComponent from '../../../components/common-component/form-elements/input-component'
import DropdownComponent from '../../../components/common-component/form-elements/dropdown-component'
import IconButton from '../../../components/common-component/form-elements/icon-button'
import ButtonComponent from '../../../components/common-component/form-elements/button-component'
import DirectorTable from '../../../config/organisation/vendor/director-info-grid'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import Changesmodal from '../../../components/common-component/modals/changes-modal';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import AlertBox from '../../../components/common-component/alert-box'
import DeleteModal from '../../../components/common-component/modals/delete-modal'
import Chart from 'chart.js/auto';
import Loading from '../../../components/common-component/modals/loading-screen'
import FileUpload from '../../../components/common-component/file-upload'
import DocumentModal from '../../../components/common-component/modals/document-modal'

export interface ChildRef {
  next: (val: any) => void;
}
interface DropdownItem {
  label: string;
  value: string;
}
interface props {
  mainApiData: any
  vendorBankApprovalDetails: any
  directorApiData: any;
  singleDirectorInfoPan: any;
  singleDirectorAddressInfoPan: any;
  directorInfoPan: any;
  fetchDirectorPan: (value: any) => void;
  setDirectorInfoPan: (value: any) => void;
  setDirectorApiData: (value: any) => void;
  setSingleDirectorInfoPan: (value: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  sendDataApi: () => void;
  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  editData: any;
  vendorDetails: any;
  setVendorDetails: any;
  stepperStatus: any;
  setStepperStatus: any;
  saveVendorDatas: () => void;
  getStackHolderId: (val: any) => void;
  setStackHolderId: any
  stackHolderId: any
}

const initData: any = {
  dateOfBirth: "",
  gender: "Select",
  email: "",
  contactNumber: "",
  aadhaarNumber: "",
  country: "",
  street: "",
  state: "Select",
  city: "Select",
  pincode: "",
  address: "",
  isAuthSignatory: "",
  name: "",
  din: "",
  designation: "",
  pan: "",
  index: 0
}

const StakeholderInfo = forwardRef<ChildRef, props>(({ getStackHolderId, stackHolderId, setStackHolderId, directorApiData, vendorBankApprovalDetails, mainApiData, isEditMode, setDirectorApiData, sendDataApi, setIconStatus, editData, setSingleDirectorInfoPan, setShowPage, iconStatus, fetchDirectorPan, directorInfoPan, onboardingViewfun, setDirectorInfoPan, singleDirectorInfoPan, singleDirectorAddressInfoPan, vendorDetails, setVendorDetails, stepperStatus, setStepperStatus, }, ref) => {

  const [showType, setShowType] = useState("danger") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [formData, setFormData] = useState(initData)
  const [address, setAddress] = useState<any>()
  const [stakeholderInfoErrors, setStakeholderInfoErrors] = useState<Record<string, boolean>>({})
  const [addButton, setAddButton] = useState(false)
  const [currentFormDataEdit, setCurrentFormDataEdit] = useState(false);
  const [currentDirector, setCurrentDirector] = useState<number>(0);
  const [directorInfoState, setDirectorInfoState] = useState<any[]>([]);
  const [directorInfoCities, setDirectorInfoCities] = useState<any[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [stakeholderInfoPercentage, setStakeholderInfoPercentage] = useState<any>(0)
  const dispatch = useDispatch();
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const [currIndex, setCurrIndex] = useState<number>()
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [saveBtnDisabledStatus, setSaveBtnDisabledStatus] = useState<boolean>(true);
  const [initialPage, setInitialPage] = useState<string>("Grid");
  const [stakeHolderDoc, setStakeHolderDoc] = useState<any>(
    vendorDetails?.vendorOverView?.businessType?.label == "Proprietorship" ? [
      { documentType: "Owner PAN / Signatory PAN", fileName: "", razoryPayDocumentType: "personal_pan", file: "" },
      { documentType: "Aadhar Front", fileName: "", razoryPayDocumentType: "aadhar_front", file: "" },
      { documentType: "Aadhar Back", fileName: "", razoryPayDocumentType: "aadhar_back", file: "" }
    ] : [
      { documentType: "Aadhar Front", fileName: "", razoryPayDocumentType: "aadhar_front", file: "" },
      { documentType: "Aadhar Back", fileName: "", razoryPayDocumentType: "aadhar_back", file: "" }
    ]
  );
  const [currentDoc, setCurrentDoc] = useState<any>()
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  function handleFileSelect(fileName: any, index: any) {
    const updatedKycDocuments = stakeHolderDoc?.map((doc: any, i: any) =>
      i === index ? { ...doc, fileName: fileName[0].name, file: fileName } : doc
    );
    setStakeHolderDoc(updatedKycDocuments);
  };
  const [formDataError, setFormDataError] = useState({
    name: false,
    din: false,
  })
  const [razorPayStakeholderInfoDetails, setRazorPayStakeholderInfoDetails] = useState('')
  const [stakeholderInfoGridEdit, setStakeholderInfoGridEdit] = useState({
    currentMode: false,
    currentEditId: ""
  })
  async function handelDirectorPan() {
    try {
      setLoadingScreen(true)
      const res = await axiosPrivate.post("pan-card/details", { "pan": vendorDetails?.stakeholderInfo?.directorsPAN })
      setLoadingScreen(false)
      const pandata = res.data.panData;
      const address = res.data.address;
      setVendorDetails({
        ...vendorDetails,
        stakeholderInfo: {
          name: pandata.name,
          directorsPAN: pandata.pan,
          relation: "",
          din: "",
          designation: "",
          ownership: "",
          uploadAadharFile: "",
          dateOfBirth: pandata.dateOfBirth,
          gender: "",
          email: pandata.email,
          primaryContactNumber: pandata.phone,
          secondaryContactNumber: "",
          aadhaarNumber: "",
          street: address.street,
          city: { label: address.city, value: address.city },
          state: { label: address.state, value: address.state },
          postalCode: address.pincode,
          country: "India",
          isAuthSignatory: "",
        },
      })
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("PAN Data has been fetched and filled successfully.");
      clearAlert('');
    } catch (error) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Could not fetch PAN data. Enter values manually to complete this operation.");
      clearAlert('');
    }
    return;
    // setFormData({
    //   ...formData,
    //   aadhaarNumber : formData.aadhaarNumber? formData.aadhaarNumber : formData.aadhaarLinked
    // })
    // && /^[A-Za-z][A-Za-z\s]*$/.test(formData.name)
    // && /^[0-9]{7,8}$/.test(formData.din)
    const isNameValid = formData.name !== undefined || formData.name !== "";
    const isDinValid = formData.din !== undefined || formData.din !== "";
    if (isNameValid && isDinValid) {
      fetchDirectorPan(directorInfoPan);
      setFormDataError({
        name: false,
        din: false,
      });
    } else {

      setFormDataError({
        name: !isNameValid,
        din: !isDinValid,
      });
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Mandatory field(s) should not be left blank.")
      clearAlert('');

    }
  }

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }
  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);

      }
    } catch (error) {
    }
  };
  // async function duplicateCheck() {
  //   const finalData = {
  //     "directorsInfo": {
  //       "din": formData.din,
  //       "email": formData.email,
  //       "contactNumber": formData.contactNumber ? formData.contactNumber : "",
  //       "aadhaarNumber": formData.aadhaarNumber ? formData.aadhaarNumber : "",
  //       "vendorOverviewId": editData?.vendorOverView?.vendorOverviewId ? editData?.vendorOverView?.vendorOverviewId : ""
  //     }

  //   }
  //   let statusMessage: boolean = false;
  //   try {

  //     const fieldsToCheck = ['din', 'pan', 'email', 'contactNumber'];
  //     const todisplay = ['Director Din', 'Director Pan', 'Email', 'Contact Number'];
  //     const failedFields: any[] = [];
  //     let isDuplicate = false;
  //     if (!currentFormDataEdit) {
  //       isDuplicate = directorApiData.some((item: any) => {
  //         const matches = fieldsToCheck.map((field, i) => {
  //           if (formData[field] && formData[field] !== '') {
  //             if (item[field] === formData[field]) {
  //               failedFields.push(todisplay[i]);
  //             }
  //             return item[field] === formData[field];
  //           }
  //           return false;
  //         });
  //         return matches.some(match => match);
  //       });
  //     } else {

  //       isDuplicate = directorApiData.some((item: any, index: any) => {
  //         const matches = fieldsToCheck.map((field, i) => {
  //           if (formData[field] && formData[field] !== '' && currIndex !== index) {
  //             if (item[field] === formData[field]) {
  //               failedFields.push(todisplay[i]);
  //             }
  //             return item[field] === formData[field];
  //           }
  //           return false;
  //         });
  //         return matches.some(match => match);
  //       });
  //     }
  //     if (!isDuplicate) {
  //       const res = await axiosPrivate.post("companyadmin/organisation/vendor/duplicate-checking", finalData)
  //       if (res.status === 200) {
  //         statusMessage = true;
  //       }
  //     } else {
  //       statusMessage = false
  //       setShowAlertBox(true)
  //       setShowType("danger")
  //       setShowMessage(`${failedFields[0]} already exists.`)
  //       clearAlert("")
  //     }
  //   }
  //   catch (error: any) {
  //     statusMessage = false
  //     setShowAlertBox(true)
  //     setShowType("danger")
  //     setShowMessage(error.response.data.error);
  //     clearAlert("")
  //   }
  //   return statusMessage;
  // }
  async function duplicateCheck() {
    const finalData = {
      "stakeholderInfo": {
        "directorsPAN": vendorDetails?.stakeholderInfo?.directorsPAN,
        "email": vendorDetails?.stakeholderInfo?.email,
        "primaryContactNumber": vendorDetails?.stakeholderInfo?.primaryContactNumber,
        "secondaryContactNumber": vendorDetails?.stakeholderInfo?.secondaryContactNumber,
        "aadhaarNumber": vendorDetails?.stakeholderInfo?.aadhaarNumber,
        'razorpayAccountId': vendorDetails?.primaryKeyOfCurrentDatas,
        'razorPayStakeholderId': stakeholderInfoGridEdit.currentEditId
      }
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post("razorpay/vendor/user-creation/duplicateChecking", finalData)

      if (res.status === 200) {
        statusMessage = true;
        return true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
      return false;
    }
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }
  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");

  }, [])

  const isDateValid = () => {
    const fromDate = new Date(formData.dateOfBirth);
    let isOk = false
    if (formData.dateOfBirth && fromDate.toString() == "Invalid Date") return false;
    if (formData.dateOfBirth) {
      if (fromDate < new Date() && fromDate > new Date("1900-01-01")) {
        isOk = true
        setStakeholderInfoErrors({ dateOfBirth: false })
      } else {
        isOk = false
        setStakeholderInfoErrors({ dateOfBirth: true })
      }
    } else {
      isOk = true
      setStakeholderInfoErrors({ dateOfBirth: false })
    }
    return isOk;
  }

  async function handelAdd() {

    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    let isvalidDOB = isDateValid()
    if (!isvalidDOB) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Some of the field(s) are not in required format.")
      clearAlert('');
      return;
    }



    let Duplicate = await duplicateCheck();
    setDirectorInfoPan("")
    setSingleDirectorInfoPan("")
    setAddress("")
    if (!Duplicate) return;
    if (currentFormDataEdit) {
      let data: any = [];
      directorApiData.length > 0 && directorApiData.map((ele: any, i: number) => {
        if (i === formData.index) {
          data.push(formData)
        } else {
          data.push(ele)
        }
      })
      setDirectorApiData(data)
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          aadhaarNumber: "",
          country: "",
          street: "",
          state: "Select",
          city: "Select",
          pincode: "",
          address: "",
          name: "",
          isAuthSignatory: "",
          din: "",
          designation: "",
          pan: "",
          index: 0
        }
      )
      setDropDownCities([]);
      setIsFormDirty(false);
      setCurrentFormDataEdit(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Director Info updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = directorApiData
      newrecord.push({ ...formData })
      setDirectorApiData(newrecord)
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          aadhaarNumber: "",
          country: "",
          street: "",
          state: "Select",
          city: "Select",
          pincode: "",
          address: "",
          isAuthSignatory: "",
          name: "",
          din: "",
          designation: "",
          pan: "",
          index: 0
        }
      )
      setIsFormDirty(false);
      setDropDownCities([]);

    }
    setCurrentFormDataEdit(false);

  }

  const userValidationRules: any = {
    directorsPAN: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    name: {
      regex: vendorDetails?.stakeholderInfo?.name !== "" ? /^[A-Za-z][A-Za-z\s]*$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    relation: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    din: {
      // regex: /^[0-9]{7,8}$/,
      regex: '',
      field: vendorDetails?.stakeholderInfo?.relation.label == "Executive" ? "" : "mandatory",
      shouldNotBe: ""
    },
    designation: {
      // regex:  /^[A-Za-z][A-Za-z\s]*$/ ,
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    ownership: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    // uploadAadharFile: {
    //   regex: '',
    //   field: "mandatory",
    //   shouldNotBe: ""
    // },
    email: {
      regex: vendorDetails?.stakeholderInfo?.email !== "" ? /^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    primaryContactNumber: {
      regex: /^[0-9]{10}$/,
      field: "mandatory",
      shouldNotBe: "sdffsd"
    },
    secondaryContactNumber: {
      regex: /^[0-9]{10}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    street: {
      regex: /^.{10,}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    city: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    state: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    postalCode: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    // country: {
    //   regex: '',
    //   field: "mandatory",
    //   shouldNotBe: ""
    // },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = vendorDetails?.stakeholderInfo[field];
      console.log(rule, value, field, "field")
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setStakeholderInfoErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  function next(next: any = "") {
    setShowPage('Address')
    // if (isEditMode && directorApiData.length > 0) {
    //   sendDataApi();
    // } else {
    //   // directorApiData.length > 0
    //   if (directorApiData.length > 0) {
    //     setShowPage(next != "" ? next : "StatutoryDetails")
    //     if (next != "") {
    //       switch (next) {
    //         case "VendorOverview":
    //           setIconStatus({
    //             ...iconStatus,
    //             companyIcon: "selected",
    //           })
    //           break;
    //         case "BankDetails":
    //           setIconStatus({
    //             ...iconStatus,
    //             companyIcon: "completed",
    //             adminIcon: "selected"

    //           })
    //           break;
    //         case "Address":
    //           setIconStatus({
    //             ...iconStatus,
    //             adminIcon: "completed",
    //             addressIcon: "selected",
    //           })
    //           break;
    //         case "DirectorsInfo":
    //           setIconStatus({
    //             ...iconStatus,
    //             directorIcon: "selected",
    //             addressIcon: "completed"
    //           })
    //           break;
    //         case "StatutoryDetails":
    //           setIconStatus({
    //             ...iconStatus,
    //             statutoryIcon: "selected",
    //             directorIcon: "completed"
    //           })
    //           break;
    //         case "PointOfContact":
    //           setIconStatus({
    //             ...iconStatus,
    //             PointOfContactIcon: "selected",
    //             statutoryIcon: "completed"
    //           })
    //           break;

    //         default:
    //           break;
    //       }
    //     } else {

    //       setIconStatus({
    //         ...iconStatus,
    //         directorIcon: "completed",
    //         statutoryIcon: "selected"
    //       })
    //     }
    //     return;
    //   }
    // }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function retriveData(value: any, index: number) {
    setCurrIndex(index)
    setCurrentFormDataEdit(true)
    setCurrentDirector(value.id)
    setStakeholderInfoErrors({})
    dispatch(resetFormModified(true));
    setIsFormDirty(true);
    fetchData(`state/cities/${value.stateId}`, setDropDownCities, 'city', "cityId", "name");
    // if (value) {
    //   setFormData(
    //     {
    //       ...value,
    //       dateOfBirth: value?.dateOfBirth,
    //       gender: value?.gender,
    //       email: value?.email,
    //       contactNumber: value?.contactNumber,
    //       aadhaarNumber: value?.aadhaarNumber,
    //       country: value?.country,
    //       street: value?.street,
    //       state: value?.state,
    //       city: value?.city,
    //       pincode: value?.pincode,
    //       address: value?.address,
    //       name: value?.name,
    //       din: value?.din,
    //       designation: value?.designation,
    //       pan: value?.pan,
    //       index: value?.index,
    //     }
    //   )
    // } 
    if (value) {
      setFormData(
        {
          ...value,
          dateOfBirth: value?.dateOfBirth,
          gender: value?.gender,
          email: value?.email,
          contactNumber: value?.contactNumber,
          aadhaarNumber: value?.aadhaarNumber,
          country: value?.country,
          street: value?.street,
          state: value?.state,
          city: value?.city,
          pincode: value?.pincode,
          address: value?.address,
          name: value?.name,
          din: value?.din,
          designation: value?.designation,
          pan: value?.pan,
          index: index,
        }
      )
    } else {

      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          aadhaarNumber: "",
          country: "",
          street: "",
          state: "Select",
          city: "Select",
          pincode: "",
          address: "",
          name: "",
          din: "",
          designation: "",
          pan: "",
          index: 0
        }
      )
    }
  }

  useEffect(() => {
    if (Object.values(singleDirectorInfoPan).length !== 0) {
      setFormData({
        ...formData,
        ...singleDirectorInfoPan.panData,
        address: `${singleDirectorInfoPan.address?.line1 || ""} ${singleDirectorInfoPan.address?.line2 || ""} ${singleDirectorInfoPan.address?.street || ""} ${singleDirectorInfoPan.address?.state},${singleDirectorInfoPan.address?.city} ${singleDirectorInfoPan.address?.pincode}`
      })
    }
  }, [singleDirectorInfoPan])


  useEffect(() => {
    if (Object.values(singleDirectorAddressInfoPan).length !== 0) {
      setFormData({
        ...formData,
        address: `${singleDirectorAddressInfoPan?.line1 || ""} ${singleDirectorAddressInfoPan?.line2 || ""} ${singleDirectorAddressInfoPan?.street || ""} ${singleDirectorAddressInfoPan?.state},${singleDirectorAddressInfoPan?.city} ${singleDirectorAddressInfoPan?.pincode}`
      })
    }
  }, [singleDirectorAddressInfoPan])


  useEffect(() => {
    fetchDirectorInfoState()
    fetchDirectorInfoCities()
  }, [])

  const axiosPrivate = useAxiosPrivate();

  const fetchDirectorInfoState = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        setDirectorInfoState([{ label: "Select", value: "Select", id: "", }, ...dropdownValues]);
      }
    } catch (error) {
    }
  };

  const fetchDirectorInfoCities = async () => {
    try {
      const response = await axiosPrivate.get("cities");
      if (response.status === 200) {
        const dropdownValues = response.data.cities.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.citiesId,
        }));
        setDirectorInfoCities([{ label: "Select", value: "Select", id: "", }, ...dropdownValues]);
      }
    } catch (error) {
    }
  };
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }
  const DirectorInfoDelete = async () => {
    try {
      const response = await axiosPrivate.post(`/razorpay/stakeHolderInfo/delete`, { razorPayStakeholderId: deleteIndex });
      if (response.status === 200) {
        setShowDeleteModel(false);
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(response.data.message)
        clearAleart();
        getStakeholderGridDetails()
      }
    } catch (error) {
    }
    // if (currentDeletVal.directorsInfoId) {
    //   try {
    //     const response = await axiosPrivate.delete(`/companyadmin/organisation/delete-director/${currentDeletVal.directorsInfoId}`);
    //     if (response.status === 200) {
    //       setShowDeleteModel(false);
    //       const newArray = [...directorApiData];
    //       newArray.splice(deleteIndex, 1);
    //       setDirectorApiData(newArray);
    //       setShowAlertBox(true)
    //       setShowType("success")
    //       setShowMessage(response.data.message)
    //       clearAleart();
    //       setFormData(initData);
    //       setCurrentFormDataEdit(false)
    //     }
    //   } catch (error) {
    //   }
    // } else if (currentDeletVal.index === 0) {
    //   const newArray = [...directorApiData];
    //   newArray.splice(deleteIndex, 1);
    //   setDirectorApiData(newArray)
    //   setShowDeleteModel(false)
    //   setShowAlertBox(true)
    //   setShowType("success")
    //   setShowMessage("The selected record(s) has been deleted.")
    //   clearAleart()
    //   setFormData(initData);
    //   setCurrentFormDataEdit(false)

    // } else if (currentDeletVal) {
    //   const newArray: any = [...directorApiData];
    //   newArray.splice(deleteIndex, 1);
    //   setDirectorApiData(newArray)
    //   setShowDeleteModel(false)
    //   setShowAlertBox(true)
    //   setShowType("success")
    //   setShowMessage("The selected record(s) has been deleted.")
    //   clearAleart()
    //   setFormData(initData);
    //   setCurrentFormDataEdit(false)

    // }
  };
  const handleCardNumberChange = (value: string) => {
    const number = value.replace(/\D/g, "");
    const formattedNumber = number
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .trim();
    setFormData((prevState: any) => ({
      ...prevState,
      aadhaarNumber: formattedNumber,
    }));
  };
  function cancel() {
    setShowPage("Address")
    setIconStatus({ ...iconStatus, addressIcon: "selected", })
  }
  // percentage chart
  const chartRef: any = useRef(null);
  useEffect(() => {
    const data = {
      value: stakeholderInfoPercentage,
      max: 100,
      label: "Progress"
    };

    const config: any = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [data.value, data.max - data.value],
          backgroundColor: [stakeholderInfoPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        rotation: -90,
        circumference: 180,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        animation: {
          animateRotate: true,
          animateScale: false
        },
        title: {
          display: true,
          text: data.label,
          fontSize: 16
        }
      }
    };

    if (chartRef.current) {
      const chartCtx = chartRef.current.getContext('2d');
      const gaugeChart = new Chart(chartCtx, config);

      // Cleanup on unmount
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [stakeholderInfoPercentage]);
  // percentage chart

  // useEffect(() => {
  //   let count = 0;
  //   let mandatoryList = ['name', 'relation', 'din', 'designation', 'ownership', 'uploadAadharFile', 'email', "primaryContactNumber", "secondaryContactNumber", 'street', 'city', 'state', 'postalCode', 'country']
  //   Object.keys(vendorDetails?.stakeholderInfo)?.forEach((item: any) => {
  //     let dropdownValueStatus = typeof (vendorDetails?.stakeholderInfo[item]) == 'object' ? vendorDetails?.stakeholderInfo[item].label !== "Select" : true;
  //     if (vendorDetails?.stakeholderInfo[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
  //       setStepperStatus({ ...stepperStatus, stakeholderInfo: "working" })
  //       count++;
  //     }
  //   });
  //   let percentage: any = (count / 14) * 100
  //   if (percentage.toFixed(0) == 100) {
  //     setStepperStatus({ ...stepperStatus, stakeholderInfo: "completed" })
  //   }
  //   setStakeholderInfoPercentage(percentage.toFixed(0))
  // }, [vendorDetails?.stakeholderInfo])

  useEffect(() => {
    // let count = 0;
    // let mandatoryList = ['name', 'relation', 'din', 'designation', 'ownership', 'uploadAadharFile', 'email', "primaryContactNumber", "secondaryContactNumber", 'street', 'city', 'state', 'postalCode', 'country']
    // Object.keys(vendorDetails?.stakeholderInfo)?.forEach((item: any) => {
    //   let dropdownValueStatus = typeof (vendorDetails?.stakeholderInfo[item]) == 'object' ? vendorDetails?.stakeholderInfo[item].label !== "Select" : true;
    //   if (vendorDetails?.stakeholderInfo[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
    //     setStepperStatus({ ...stepperStatus, stakeholderInfo: "working" })
    //     count++;
    //   }
    // });
    // let percentage: any = (count / 14) * 100
    // if (percentage.toFixed(0) == 100) {
    //   setStepperStatus({ ...stepperStatus, stakeholderInfo: "completed" })
    // }
    if (razorPayStakeholderInfoDetails.length > 0) {
      setStepperStatus({ ...stepperStatus, stakeholderInfo: "completed" })
      setStakeholderInfoPercentage(100)
    }
  }, [razorPayStakeholderInfoDetails])

  async function getStakeholderGridDetails() {
    try {
      const res = await axiosPrivate.post('razorpay/stakeHolderInfo/get', { "razorpayAccountId": vendorDetails?.primaryKeyOfCurrentDatas })
      setVendorDetails({
        ...vendorDetails,
        stakeholderInfoGridDetails: res.data.responseStakeHolderInfoDetails,
        stakeholderInfo: {
          directorsPAN: "",
          name: "",
          relation: "",
          din: "",
          designation: "",
          ownership: "",
          uploadAadharFile: "",
          dateOfBirth: "",
          gender: "",
          email: "",
          primaryContactNumber: "",
          secondaryContactNumber: "",
          aadhaarNumber: "",
          street: "",
          city: "",
          state: "",
          postalCode: "",
          country: "India",
          isAuthSignatory: "",
        },
      })
      console.log("res.data.responseStakeHolderInfoDetails", res.data.responseStakeHolderInfoDetails)
      // stakeholderInfoGridDetails(res.data.responseStakeHolderInfoDetails)
      setRazorPayStakeholderInfoDetails(res.data.responseStakeHolderInfoDetails)
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    getStakeholderGridDetails()
    // setVendorDetails({
    //   ...vendorDetails,
    //   stakeholderInfo: {
    //     directorsPAN: "",
    //     name: "",
    //     relation: "",
    //     din: "",
    //     designation: "",
    //     ownership: "",
    //     uploadAadharFile: "",
    //     dateOfBirth: "",
    //     gender: "",
    //     email: "",
    //     primaryContactNumber: "",
    //     secondaryContactNumber: "",
    //     aadhaarNumber: "",
    //     street: "",
    //     city: "",
    //     state: "",
    //     postalCode: "",
    //     country: "India",
    //     isAuthSignatory: "",
    //   },
    // })
  }, [])

  async function createStakeHolderInfo() {
    try {
      if (vendorDetails?.stakeholderInfo?.primaryContactNumber && vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
        if (vendorDetails?.stakeholderInfo?.primaryContactNumber === vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
          setShowAlertBox(true);
          setShowType("danger");
          setShowMessage("Primary and secondary contact numbers cannot be the same.");
          clearAlert('');
          return;
        }
      }
      if (!validateForm()) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
          "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
        clearAlert('');
        return;
      }
      if (await duplicateCheck()) {
        const formData = new FormData();
        // formData.append(`uploadAadhar`, vendorDetails?.stakeholderInfo?.uploadAadharFile ? vendorDetails?.stakeholderInfo?.uploadAadharFile[0] : "")
        formData.append(`stakeholderInfoDetails`, JSON.stringify(vendorDetails?.stakeholderInfo))
        formData.append(`razorpayAccountId`, vendorDetails?.primaryKeyOfCurrentDatas)
        const res = await axiosPrivate.post('razorpay/stakeHolderInfo/create', {
          stakeholderInfoDetails: JSON.stringify(vendorDetails?.stakeholderInfo),
          razorpayAccountId: vendorDetails?.primaryKeyOfCurrentDatas
        })
        getStakeholderGridDetails()
        setVendorDetails({
          ...vendorDetails,
          stakeholderInfo: {
            directorsPAN: "",
            name: "",
            relation: "",
            din: "",
            designation: "",
            ownership: "",
            uploadAadharFile: "",
            dateOfBirth: "",
            gender: "",
            email: "",
            primaryContactNumber: "",
            secondaryContactNumber: "",
            aadhaarNumber: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "India",
            isAuthSignatory: "",
          },
        })
        setInitialPage('Grid')
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(res.data.message);
        clearAlert('');

      }
    } catch (error) {
      console.log("Error in createStakeHolderInfo", error)
    }

  }
  async function updateStakeHolderInfo() {

    try {
      if (vendorDetails?.stakeholderInfo?.primaryContactNumber && vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
        if (vendorDetails?.stakeholderInfo?.primaryContactNumber === vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
          setShowAlertBox(true);
          setShowType("danger");
          setShowMessage("Primary and secondary contact numbers cannot be the same.");
          clearAlert('');
          return;
        }
      }
      if (!validateForm()) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
          "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
        clearAlert('');
        return;
      }
      if (await duplicateCheck()) {
        const formData = new FormData();
        formData.append(`uploadAadhar`, vendorDetails?.stakeholderInfo?.uploadAadharFile ? vendorDetails?.stakeholderInfo?.uploadAadharFile[0] : "")
        formData.append(`stakeholderInfoDetails`, JSON.stringify(vendorDetails?.stakeholderInfo))
        formData.append(`razorPayStakeholderId`, stakeholderInfoGridEdit.currentEditId)
        const res = await axiosPrivate.post('razorpay/stakeHolderInfo/update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
          },
        })
        setStakeholderInfoGridEdit({
          currentMode: false,
          currentEditId: ""
        })
        setVendorDetails({
          ...vendorDetails,
          stakeholderInfo: {
            directorsPAN: "",
            name: "",
            relation: "",
            din: "",
            designation: "",
            ownership: "",
            uploadAadharFile: "",
            dateOfBirth: "",
            gender: "",
            email: "",
            primaryContactNumber: "",
            secondaryContactNumber: "",
            aadhaarNumber: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "India",
            isAuthSignatory: "",
          },
        })
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(res.data.message);
        clearAlert('');
        getStakeholderGridDetails()
      }
    } catch (error) {
      console.log(error)
    }

  }

  const [mindate, setMindate] = useState<string>(todayDate);
  const currentyear = new Date().getFullYear();
  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMindate(moment(event.target.value).format("YYYY-MM-DD"))
    setIsFormDirty(true)
    dispatch(resetFormModified(true));
    const fromDate = event.target.value;
    const currentDate = moment().format('YYYY-MM-DD'); // Get the current date.
    // const fromDate = event.target.value;
    if (fromDate.length > 8) {
      const year = fromDate.substring(0, 4);
      if (parseInt(year, 10) > currentyear) { return; }
      if (parseInt(year) > 999) {
        if (parseInt(year, 10) < 1900) {
          return;
        }
      }
    }
    setVendorDetails({
      ...vendorDetails,
      stakeholderInfo: {
        ...vendorDetails.stakeholderInfo,
        dateOfBirth: fromDate,
      }
    })
    setIsFormDirty(true)
    dispatch(resetFormModified(true));
  };
  async function saveVendorDatas() {
    try {
      if (vendorDetails?.stakeholderInfo?.primaryContactNumber && vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
        if (vendorDetails?.stakeholderInfo?.primaryContactNumber === vendorDetails?.stakeholderInfo?.secondaryContactNumber) {
          setShowAlertBox(true);
          setShowType("danger");
          setShowMessage("Primary and secondary contact numbers cannot be the same.");
          clearAlert('');
          return;
        }
      }
      if (!validateForm()) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
          "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
        clearAlert('');
        return;
      }
      if (await duplicateCheck()) {
        // let status = stakeHolderDoc.every((e: any) => e.fileName !== "")
        // if (!status) {
        //   setShowAlertBox(true);
        //   setShowType("danger");
        //   setShowMessage("Mandatory field(s) should not be left blank.")
        //   clearAlert('');
        //   return;
        // }
        const formData = new FormData();
        if (stakeHolderDoc.length > 0) {
          stakeHolderDoc.map((doc: any, index: any) => {
            formData.append(`stakeholderDoc`, doc.file[0]);
          });
        }
        // pending percentage and list -- start
        let mandatoryListsAccountCreation = ['basicDetails', 'vendorOverView', 'pointOfContact', 'address']
        let mandatoryListsDocs = ['razorpayBankDetailsStatus', 'razorpayBusinessDocStatus', 'razorpayStakeholderDocStatus', 'razorpayStakeholderStatus']
        let mandatoryField: any = {
          basicDetails: 'emailId',
          vendorOverView: 'companyPAN',
          address: 'contact_street_1',
          pointOfContact: 'contactPersonName',
        }
        let final_percentage = 0;
        let final_list = [];
        for (const data of Object.keys(vendorDetails)) {
          if (mandatoryListsAccountCreation.includes(data)) {
            if (vendorDetails[data][mandatoryField[data]]) {
              final_percentage += 11.11
            } else {
              final_list.push(data)
            }
          }
        }
        if (vendorDetails?.statutoryGridDetails.length > 0) {
          final_percentage += 11.11
        } else {
          final_list.push('statutoryDetails')
        }
        for (const data of Object.keys(vendorDetails.basicDetails)) {
          if (mandatoryListsDocs.includes(data)) {
            if (vendorDetails.basicDetails[data] || data == 'razorpayStakeholderDocStatus' || data == 'razorpayStakeholderStatus') {
              final_percentage += 11.11
            } else {
              final_list.push(data)
            }
          }
        }
        // pending percentage and list -- end
        formData.append(`basicDetails`, JSON.stringify(vendorDetails.basicDetails))
        formData.append(`stakeholderInfoDetails`, JSON.stringify(vendorDetails?.stakeholderInfo))
        formData.append(`razorPayStakeholderInfoDetails`, JSON.stringify(razorPayStakeholderInfoDetails))
        formData.append(`stakeHolderDoc`, JSON.stringify(stakeHolderDoc))
        formData.append(`overAllPercentage`, JSON.stringify(final_percentage.toFixed(0)))
        formData.append(`pendingList`, JSON.stringify(final_list))
        setLoadingScreen(true)
        const response = await axiosPrivate.post(`vendor/razorpay/create/stake-holder-details-and-doc/${vendorDetails.primaryKeyOfCurrentDatas}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
          },
        });

        if (response.status == 200) {

          setLoadingScreen(false)
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage(`Record ${isEditMode ? 'updated' : 'saved'} successfully.`)
          clearAlert('');

          getStakeholderGridDetails()
          setInitialPage("Grid")
        }
      }
    } catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error?.response?.data?.error);
      clearAlert('');
    }
  }
  const [docUploadedStatus, setDocUploadedStatus] = useState('')
  useEffect(() => {
    const status = stakeHolderDoc.every((e: any) => e.fileName !== "")
    setDocUploadedStatus(status)
  }, [stakeHolderDoc])
  return (
    <>
      <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
        <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
          <canvas ref={chartRef}></canvas>
          <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{stakeholderInfoPercentage}%</strong></div>
          <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
          <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
        </div>
      </div>

      {
        initialPage === "Grid" &&
        <>
          <div style={{ width: "80%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className={"stackholderName"} style={{ fontSize: "22px", fontWeight: "600" }}>{"Stakeholder Info"}</div>
              <div className='icon-buttons'>
                <IconButton
                  iconName={"Filter"}
                  height={"40px"}
                  width={"40px"}
                  fontSize={"30px"}
                  margin={"0px 8px"}
                  color={"white"}
                  backgroundColor={"#0055D4"}
                  hover={false}
                  opacity={'0.5'}
                  cursor={"not-allowed"}
                  handleClick={(event: React.MouseEvent) => {
                    event?.stopPropagation()
                    // setFilter(!filter);
                  }}
                />
                {/* <IconButton
                  iconName={"Delete"}
                  height={"40px"}
                  width={"40px"}
                  fontSize={"25px"}
                  margin={"0px 8px"}
                  color={"white"}
                  backgroundColor={"#D60000"}
                  hover={false}
                  opacity={"0.5"}
                  cursor={"not-allowed"}
                  handleClick={() => { }}
                /> */}

                <IconButton
                  iconName={"Add"}
                  height={"40px"}
                  width={"75px"}
                  fontSize={"25px"}
                  margin={"0px 8px"}
                  color={"white"}
                  backgroundColor={"#0055D4"}
                  hover={true}
                  handleClick={() => {
                    // onboardingViewfun("form");
                    // addMode();
                    setInitialPage("Form")
                    setVendorDetails({
                      ...vendorDetails,
                      stakeholderInfo: {
                        directorsPAN: "",
                        name: "",
                        relation: "",
                        din: "",
                        designation: "",
                        ownership: "",
                        uploadAadharFile: "",
                        dateOfBirth: "",
                        gender: "",
                        email: "",
                        primaryContactNumber: "",
                        secondaryContactNumber: "",
                        aadhaarNumber: "",
                        street: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        country: "India",
                        isAuthSignatory: "",
                      },
                    })
                    setStakeholderInfoGridEdit({
                      currentMode: false,
                      currentEditId: ""
                    })

                  }}
                />
                <IconButton
                  iconName={"Upload"}
                  height={"40px"}
                  width={"40px"}
                  fontSize={"25px"}
                  margin={"0px 0px 0px 8px"}
                  color={"white"}
                  backgroundColor={"#0055D4"}
                  hover={false}
                  opacity={"0.5"}
                  cursor={"not-allowed"}
                  handleClick={() => {
                  }}
                />
                <IconButton
                  iconName={"Download"}
                  height={"40px"}
                  width={"40px"}
                  fontSize={"25px"}
                  margin={"0px 8px"}
                  color={"white"}
                  backgroundColor={"#0055D4"}
                  hover={true}
                  handleClick={() => { }}
                />
              </div>
            </div>
            <div style={{ marginRight: "5px" }}>
              <DirectorTable
                vendorBankApproval={vendorBankApprovalDetails?.status}
                directorApiData={razorPayStakeholderInfoDetails || []}
                retriveData={
                  (val, index) => {
                    return;
                    setStakeholderInfoGridEdit({
                      currentMode: true,
                      currentEditId: val.razorPayStakeholderId
                    })
                    setVendorDetails({
                      ...vendorDetails,
                      stakeholderInfo: {
                        directorsPAN: val.directorsPAN,
                        name: val.name,
                        relation: { label: val.relation || "Select", value: val.relation || "Select" },
                        din: val.din,
                        designation: val.designation,
                        ownership: val.ownership,
                        uploadAadharFile: val.uploadAadharFile,
                        dateOfBirth: val.dateOfBirth,
                        gender: { label: val.gender || "Select", value: val.gender || "Select" },
                        email: val.email,
                        primaryContactNumber: val.primaryContactNumber,
                        secondaryContactNumber: val.secondaryContactNumber,
                        aadhaarNumber: val.aadhaarNumber,
                        street: val.street,
                        city: { label: val.city || "Select", value: val.city || "Select" },
                        state: { label: val.state || "Select", value: val.state || "Select" },
                        postalCode: val.postalCode,
                        isAuthSignatory: val.isAuthSignatory,
                      },
                    })
                    setInitialPage('Form')
                  }
                }
                showmodal={(val: any, index: any) => {
                  return;
                  setShowDeleteModel(true);
                  setDeleteIndex(val.razorPayStakeholderId);
                }}
              />
            </div>
            <div>
              <div className='footer-company'>
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
                <ButtonComponent
                  title={"Previous"}
                  height={"50px"}
                  width={"150px"}
                  disabled={false}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={"button-component-hover common-btn"}
                  handleClick={() => { setShowPage('KYCDocuments') }}
                />
                <ButtonComponent
                  title={"Next"}
                  height={"50px"}
                  width={"150px"}
                  // disabled={showMessage && showType === "success" ? true : false}
                  disabled={false}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={
                    razorPayStakeholderInfoDetails?.length > 0 ?
                      "button-component-hover common-btn"
                      :
                      "button-component-hover disabled"
                  }
                  handleClick={() => {
                    // setShowPage("BankDetails")
                    setShowPage("StakeholderDocs")
                  }}
                // handleClick={next}
                />
              </div>
            </div>
          </div>

        </>
      }

      {
        initialPage === "Form" &&
        <>
          <div className='form-wrapper'>
            <div className='form-area'>
              <div
                style={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ?
                  { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
              >
                <div className='company-heading'>
                  Stakeholder Info
                </div>
                <div className='input-fetch-flex'>
                  <div>
                    <InputComponent
                      height={"40px"}
                      width={"200px"}
                      padding={"0px 0px 0px 10px"}
                      margin={"0px 0px 30px 0px"}
                      required={true}
                      autoFocus
                      border={stakeholderInfoErrors?.directorsPAN ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      color={"black"}
                      type={"text"}
                      placeHolder={"Enter Director's PAN"}
                      inputTitle={"Director's PAN"}
                      maxLength={10}
                      inputValue={vendorDetails?.stakeholderInfo?.directorsPAN}
                      getUser={(val) => {
                        setSaveBtnDisabledStatus(false)
                        let value = val.toUpperCase()
                        setDirectorInfoPan(value)
                        // setFormData({ ...formData, pan: value })
                        // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            directorsPAN: value,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={(RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(directorInfoPan)) ? {} : { pointerEvents: "none", opacity: 0.3 }}>
                    <IconButton
                      iconName={"Fetch"}
                      height={"40px"}
                      width={"100px"}
                      fontSize={""}
                      color={""}
                      border={""}
                      borderRadius={"0px"}
                      backgroundColor={"#0055D4"}
                      hover={RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(directorInfoPan) ? true : false}
                      disabled={RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(directorInfoPan) ? false : true}
                      margin={"4px 0px 0px 0px"}
                      handleClick={() => {
                        handelDirectorPan()
                      }}
                    />
                  </div>
                </div>
                <div className='form-main-wrapper'>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 10px"}
                      border={stakeholderInfoErrors?.name ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      color={"black"}
                      type={"text"}
                      inputTitle={"Name"}
                      placeHolder={"Enter Name"}
                      maxLength={100}
                      required={true}
                      inputValue={vendorDetails?.stakeholderInfo?.name}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const removeInitialSpaceAndAlphabetsOnly = value.replace(/^\s+/, "").replace(/[^a-zA-Z\s]/g, "");

                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            name: removeInitialSpaceAndAlphabetsOnly,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px", width: "300px" }} key={uuidv4()} className={stakeholderInfoErrors?.relation ? 'setError-vendorType' : 'dropdown-vendorType'}>
                    <DropdownComponent
                      width={"300px"}
                      title={"Relation"}
                      required={true}
                      isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      options={[{ label: 'Director', value: "Director" }, { label: 'Executive', value: "Executive" }]}
                      defaultValue={vendorDetails?.stakeholderInfo?.relation ? [vendorDetails?.stakeholderInfo?.relation] : [{ label: "Select", value: "Select" }]}
                      getData={(value) => {
                        setSaveBtnDisabledStatus(false)
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            relation: value,
                          }
                        })
                        // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px", opacity: vendorDetails?.stakeholderInfo?.relation.label == "Executive" ? "0.5" : "1" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 10px"}
                      border={stakeholderInfoErrors?.din ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      placeHolder={"Enter DIN"}
                      type={"text"}
                      inputTitle={"DIN"}
                      disabled={(vendorDetails?.stakeholderInfo?.relation.label === "Executive") ? true : false}
                      maxLength={8}
                      required={true}
                      inputValue={vendorDetails?.stakeholderInfo?.din}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        // const val = value.replace(/[^0-9]/g, "")
                        const numbersOnly = value.replace(/\D/g, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            din: numbersOnly,
                          }
                        })
                        // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                </div>
                <div className='form-main-wrapper'>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      padding={"0px 0px 0px 10px"}
                      border={stakeholderInfoErrors?.designation ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"text"}
                      placeHolder={"Enter Designation"}
                      required={true}
                      maxLength={100}
                      inputTitle={"Designation"}
                      inputValue={vendorDetails?.stakeholderInfo?.designation}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const removeInitialSpaceAndAlphabetsOnly = value.replace(/^\s+/, "").replace(/[^a-zA-Z\s]/g, "");
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            designation: removeInitialSpaceAndAlphabetsOnly,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 10px"}
                      border={stakeholderInfoErrors?.ownership ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      color={"black"}
                      type={"text"}
                      inputTitle={"Ownership %"}
                      placeHolder={"Enter Ownership %"}
                      maxLength={10}
                      required={true}
                      inputValue={vendorDetails?.stakeholderInfo?.ownership}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        // const numbersOnly = value.replace(/\D/g, "")
                        const numbersOnly = value
                          .replace(/^\D|(?<=\..*)\./g, "") // Remove non-digit characters and allow only one dot
                          .replace(/^(\d{0,3})(\.\d{0,2})?.*$/, "$1$2"); // Limit to 2 digits before and after the dot
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            ownership: numbersOnly,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  {/* <div >
                  <FileUpload
                    getFileName={(value: any) => {
                      setSaveBtnDisabledStatus(false)
                      setVendorDetails({
                        ...vendorDetails,
                        stakeholderInfo: {
                          ...vendorDetails.stakeholderInfo,
                          uploadAadharFile: value,
                        }
                      })
                    }}
                    aadharFileName={vendorDetails?.stakeholderInfo?.uploadAadharFile}
                    stakeholderInfoErrors={stakeholderInfoErrors}
                  />
                </div> */}
                </div>
                <div className='form-main-wrapper'>
                  <div style={{ marginRight: "45px" }}>
                    <div className='title'>Date Of Birth</div>
                    <input type='date' className='date-picker'
                      // style={{ border: stakeholderInfoErrors?.dateOfBirth ? "1px solid red" : "" }}

                      disabled={false}
                      value={vendorDetails?.stakeholderInfo?.dateOfBirth && moment(vendorDetails?.stakeholderInfo?.dateOfBirth).format('YYYY-MM-DD')}
                      min={todayDate}
                      max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                      // onKeyDown={(e) => e.preventDefault()}
                      onChange={(e: any) => {
                        setSaveBtnDisabledStatus(false)
                        handleFromDateChange(e)
                      }}
                    // onChange={(e: any) => {
                    //   if (e.target.value.length > 8) {
                    //     const year = e.target.value.substring(0, 4);

                    //     if (parseInt(year, 10) > 2023) {
                    //       return;
                    //     }
                    //     if (year > 999) {
                    //       if (parseInt(year, 10) < 1900) {
                    //         return;
                    //       }
                    //     }
                    //   }
                    //   setVendorDetails({
                    //     ...vendorDetails,
                    //     stakeholderInfo: {
                    //       ...vendorDetails.stakeholderInfo,
                    //       dateOfBirth: e.target.value,
                    //     }
                    //   })
                    //   setIsFormDirty(true)
                    //   dispatch(resetFormModified(true));
                    // }
                    // } 


                    />

                  </div>
                  <div style={{ marginRight: "45px" }} key={uuidv4()}>
                    <DropdownComponent
                      width={"300px"}
                      title={"Gender"}
                      isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      options={[{ label: 'Select', value: "Select" }, { label: 'Male', value: "Male" }, { label: 'Female', value: "Female" }]}
                      defaultValue={vendorDetails?.stakeholderInfo?.gender ? [vendorDetails?.stakeholderInfo?.gender] : [{ label: "Select", value: "Select" }]}
                      getData={(value) => {
                        setSaveBtnDisabledStatus(false)
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            gender: value,
                          }
                        })
                        // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 45px 0px"}
                      padding={"0px 40px 0px 10px"}
                      borderRadius={"0px"}
                      required={true}
                      backgroundColor={"white"}
                      color={"black"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      type={"text"}
                      placeHolder={"Enter Email"}
                      inputTitle={"Email"}
                      maxLength={254}
                      className={"address-input"}
                      inputValue={vendorDetails?.stakeholderInfo?.email}
                      // disabled={admin_details[i].isActive !== "No"}
                      inputField={"gmail"}
                      border={stakeholderInfoErrors?.email ? "1px solid red" : "1px solid #A9C3DC"}
                      getUser={(value) => {
                        setSaveBtnDisabledStatus(false)
                        const removeInitialSpace = value.replace(/^\s+/, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            email: removeInitialSpace,
                          }
                        })
                        // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                </div>
                <div className='form-main-wrapper'>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 70px"}
                      borderRadius={"0px"}
                      required={true}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"mobile"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      maxLength={10}
                      placeHolder={"Primary Contact Number"}
                      inputTitle={"Primary Contact Number"}
                      className={"mobilenumber-input"}
                      inputValue={vendorDetails?.stakeholderInfo?.primaryContactNumber}
                      // disabled={admin_details[i].isActive !== "No"}
                      border={stakeholderInfoErrors?.primaryContactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const val = value.replace(/[^0-9]/g, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            primaryContactNumber: val,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 70px"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      required={true}
                      color={"black"}
                      type={"mobile"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      maxLength={10}
                      placeHolder={"Secondary Contact Number"}
                      inputTitle={"Secondary Contact Number"}
                      className={"mobilenumber-input"}
                      inputValue={vendorDetails?.stakeholderInfo?.secondaryContactNumber}
                      // disabled={admin_details[i].isActive !== "No"}
                      border={stakeholderInfoErrors.secondaryContactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const val = value.replace(/[^0-9]/g, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            secondaryContactNumber: val,
                          }
                        })
                        setIconStatus({ ...iconStatus, directorIcon: "selected" })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"10px"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      border={"1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"text"}
                      maxLength={14}
                      inputTitle={"Aadhaar Number"}
                      placeHolder={"Enter Aadhaar Number"}
                      inputValue={vendorDetails?.stakeholderInfo?.aadhaarNumber}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        // Remove any non-digit characters
                        const numbersOnly = value.replace(/\D/g, "");
                        // Introduce space after every 4 digits
                        const formattedValue = numbersOnly.replace(/(.{4})/g, "$1 ").trim();
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            aadhaarNumber: formattedValue,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>

                </div>
                <div className='form-main-wrapper'>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"10px"}
                      border={stakeholderInfoErrors?.street ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      color={"black"}
                      type={"text"}
                      maxLength={100}
                      required={true}
                      placeHolder={"Enter Street"}
                      inputTitle={"Street"}
                      inputValue={vendorDetails?.stakeholderInfo?.street}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const removeInitialSpace = value.replace(/^\s+/, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            street: removeInitialSpace,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px", width: "300px" }} key={uuidv4()} className={stakeholderInfoErrors?.state ? 'setError-vendorType' : 'dropdown-vendorType'}>
                    <DropdownComponent
                      width={"300px"}
                      required={true}
                      isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      title={"State"}
                      defaultValue={vendorDetails?.stakeholderInfo?.state ? [vendorDetails?.stakeholderInfo?.state] : [{ label: "Select", value: "Select" }]}
                      options={dropDownState}
                      getData={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            state: value,
                          }
                        })
                        if (value.id == "") {
                          setDropDownCities([]);
                        } else {
                          setDropDownCities([]);
                          fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                        }
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px", width: "300px" }} key={uuidv4()} className={stakeholderInfoErrors?.city ? 'setError-vendorType' : 'dropdown-vendorType'}>
                    <DropdownComponent
                      width={"300px"}
                      title={"City"}
                      required={true}
                      isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      defaultValue={vendorDetails?.stakeholderInfo?.city ? [vendorDetails?.stakeholderInfo?.city] : [{ label: "Select", value: "Select" }]}
                      options={dropDownCities}
                      getData={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            city: value,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>


                </div>
                <div className='form-main-directors'>
                  <div style={{ marginRight: "45px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"300px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"10px"}
                      border={stakeholderInfoErrors?.postalCode ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                      type={"text"}
                      maxLength={6}
                      required={true}
                      inputTitle={"Postal Code"}
                      inputValue={vendorDetails?.stakeholderInfo?.postalCode}
                      placeHolder={"Enter Postal Code"}
                      getUser={(value: any) => {
                        setSaveBtnDisabledStatus(false)
                        const regex = value.replace(/[^0-9.]/g, "")
                        setVendorDetails({
                          ...vendorDetails,
                          stakeholderInfo: {
                            ...vendorDetails.stakeholderInfo,
                            postalCode: regex,
                          }
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ opacity: "0.7", margin: "0px 46px 26px 0px" }} className={stakeholderInfoErrors.Country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
                    <DropdownComponent
                      width={"300px"}
                      title={"Country"}
                      required={true}
                      isDisabled={true}
                      defaultValue={[{ label: "India", value: "India" }]}
                      options={[{ label: "India", value: "India" }]}
                      getData={(value: any) => {
                        // setVendorDetails({
                        //   ...vendorDetails,
                        //   stakeholderInfo: {
                        //     ...vendorDetails.stakeholderInfo,
                        //     country: value,
                        //   }
                        // })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "45px", marginBottom: "37px" }} className='checkbox-flex'>
                    <label className='label-flex'>
                      <input
                        disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                        type="checkbox" checked={vendorDetails?.stakeholderInfo?.isAuthSignatory === 'Yes'}
                        onClick={(e: any) => {
                          setSaveBtnDisabledStatus(false)
                          const value = e.target.checked ? 'Yes' : 'No';
                          setVendorDetails({
                            ...vendorDetails,
                            stakeholderInfo: {
                              ...vendorDetails.stakeholderInfo,
                              isAuthSignatory: value,
                            }
                          })

                        }} />
                      <span className='signatory-checkbox'>Is Auth signatory?</span></label>
                  </div>
                </div>
                {/* <div>
              <div className='title'>Address</div>
              <textarea className='address-textarea'
                disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                placeholder=' Enter Address'
                maxLength={500} value={formData && formData?.address}
                style={{ padding: "0px 0px 0px 10px" }}
                onChange={(e: any) => {
                  const val = e.target.value.replace(/^\s+/, "");
                  setFormData({ ...formData, address: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div> */}
                {/* <div style={{ marginTop: "20px" }}>
                <ButtonComponent
                  title={stakeholderInfoGridEdit.currentMode ? 'Update' : 'Add'}
                  height={"50px"}
                  width={"150px"}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={"button-component-hover common-btn"}
                  handleClick={() => {
                    if (stakeholderInfoGridEdit.currentMode) {
                      updateStakeHolderInfo()
                    } else {
                      createStakeHolderInfo()
                    }
                  }}
                />
              </div> */}
                {/* <div style={{ marginRight: "5px" }}>
                <DirectorTable
                  vendorBankApproval={vendorBankApprovalDetails?.status}
                  directorApiData={razorPayStakeholderInfoDetails || []}
                  retriveData={
                    (val, index) => {
                      setStakeholderInfoGridEdit({
                        currentMode: true,
                        currentEditId: val.razorPayStakeholderId
                      })
                      setVendorDetails({
                        ...vendorDetails,
                        stakeholderInfo: {
                          directorsPAN: val.directorsPAN,
                          name: val.name,
                          relation: { label: val.relation || "Select", value: val.relation || "Select" },
                          din: val.din,
                          designation: val.designation,
                          ownership: val.ownership,
                          uploadAadharFile: val.uploadAadharFile,
                          dateOfBirth: val.dateOfBirth,
                          gender: { label: val.gender || "Select", value: val.gender || "Select" },
                          email: val.email,
                          primaryContactNumber: val.primaryContactNumber,
                          secondaryContactNumber: val.secondaryContactNumber,
                          aadhaarNumber: val.aadhaarNumber,
                          street: val.street,
                          city: { label: val.city || "Select", value: val.city || "Select" },
                          state: { label: val.state || "Select", value: val.state || "Select" },
                          postalCode: val.postalCode,
                          isAuthSignatory: val.isAuthSignatory,
                        },
                      })
                    }
                  }
                  showmodal={(val: any, index: any) => {
                    setShowDeleteModel(true);
                    setDeleteIndex(val.razorPayStakeholderId);
                  }}
                />
              </div> */}
              </div>

              {/* <div>
                <div className='company-heading'>
                  Stakeholder Documents
                </div>
                <div className='kyc-doc-header'>
                  <div style={{ width: "40%", paddingLeft: "20px" }}>Document Type</div>
                  <div style={{ width: "35%" }}>File Name</div>
                  <div style={{ width: "25%" }}>Action</div>
                </div>
                {
                  stakeHolderDoc?.map((e: any, i: any) => (

                    <div className='kyc-doc-tbody'>
                      <div style={{ width: "40%", paddingLeft: "20px" }}>{e.documentType}<span style={{ color: "red" }}>*</span></div>
                      <div style={{ width: "35%" }}>{e.fileName}</div>
                      <div style={{ width: "25%", display: "flex" }}>
                        <div>
                          <FileUpload
                            type={"browse-file-only"}
                            width={'120px'}
                            getFileName={(file: any) => {
                              setSaveBtnDisabledStatus(false)
                              handleFileSelect(file, i)
                            }}
                          />
                        </div>
                        <IconButton
                          iconName={"ShowEye"}
                          height={"40px"}
                          width={"40px"}
                          fontSize={"30px"}
                          margin={"0px 8px"}
                          color={"white"}
                          backgroundColor={e?.fileName == "" ? "grey" : "#0055D4"}
                          hover={e?.fileName == "" ? false : true}
                          cursor={e?.fileName == "" ? 'default' : "pointer"}
                          handleClick={(event: React.MouseEvent) => {
                            if (e?.fileName !== "") {
                              setShowDocumentModal(true)
                              setCurrentDoc(e?.file ? e.file[0] : e.fileLocation)
                            }
                          }}
                        />
                      </div>
                    </div>

                  )
                  )

                }
              </div> */}

              <div>
                <div className='footer-company'>
                  {/* <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                /> */}
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    disabled={false}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    margin={"0px 8px"}
                    className={"button-component-hover common-btn"}
                    handleClick={() => { setInitialPage("Grid") }}
                  />
                  <ButtonComponent
                    title={'Submit'}
                    height={"50px"}
                    width={"150px"}
                    disabled={saveBtnDisabledStatus}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    margin={"0px 8px"}
                    className={saveBtnDisabledStatus ? "button-component disabled" : "button-component-hover common-btn"}
                    handleClick={() => {
                      if (stakeholderInfoGridEdit.currentMode) {
                        updateStakeHolderInfo()
                      } else {
                        saveVendorDatas()
                        // createStakeHolderInfo()
                      }
                      setSaveBtnDisabledStatus(true)
                    }}
                  />
                  {/* <ButtonComponent
                  title={"Next"}
                  height={"50px"}
                  width={"150px"}
                  disabled={false}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={"button-component-hover common-btn"}
                  handleClick={() => {
                    saveVendorDatas()
                    setInitialPage("Document")
                  }}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {/* {
        initialPage === "Document" &&
        <div style={{ width: "80%" }}>
          <div className='company-heading'>
            Stakeholder Documents
          </div>
          <div>
            <div className='kyc-doc-header'>
              <div style={{ width: "40%", paddingLeft: "20px" }}>Document Type</div>
              <div style={{ width: "35%" }}>File Name</div>
              <div style={{ width: "25%" }}>Action</div>
            </div>
            {
              stakeHolderDoc?.map((e: any, i: any) => (

                <div className='kyc-doc-tbody'>
                  <div style={{ width: "40%", paddingLeft: "20px" }}>{e.documentType}<span style={{ color: "red" }}>*</span></div>
                  <div style={{ width: "35%" }}>{e.fileName}</div>
                  <div style={{ width: "25%", display: "flex" }}>
                    <div>
                      <FileUpload
                        type={"browse-file-only"}
                        width={'120px'}
                        getFileName={(file: any) => {
                          setSaveBtnDisabledStatus(false)
                          handleFileSelect(file, i)
                        }}
                      />
                    </div>
                    <IconButton
                      iconName={"ShowEye"}
                      height={"40px"}
                      width={"40px"}
                      fontSize={"30px"}
                      margin={"0px 8px"}
                      color={"white"}
                      backgroundColor={e?.fileName == "" ? "grey" : "#0055D4"}
                      hover={e?.fileName == "" ? false : true}
                      cursor={e?.fileName == "" ? 'default' : "pointer"}
                      handleClick={(event: React.MouseEvent) => {
                        if (e?.fileName !== "") {
                          setShowDocumentModal(true)
                          setCurrentDoc(e?.file ? e.file[0] : e.fileLocation)
                        }
                      }}
                    />
                  </div>
                </div>

              )
              )

            }
          </div>
          <div>
            <div className='footer-company'>
             
              <ButtonComponent
                title={"Previous"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => { setInitialPage("Grid") }}
              />
              <ButtonComponent
                title={"Submit"}
                height={"50px"}
                width={"150px"}
                disabled={showMessage && showType === "success" ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={
                  showMessage && showType === "success" ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={() => {
                  saveVendorDatas()
                }}
              // handleClick={next}
              />
            </div>
          </div>

        </div>
      } */}

      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        loadingScreen &&
        <Loading />
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
      {
        showDocumentModal &&
        <DocumentModal
          modalType={'Vendor Details'}
          closeDocument={(val: boolean) => { setShowDocumentModal(false) }}
          document={currentDoc}
          showDocumentModal={showDocumentModal}
        />
      }
    </>
  )
})
export default StakeholderInfo

