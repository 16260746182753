import React, { useState, useEffect } from 'react'
import MainPageCard from '../../components/common-component/cards/main-page-card';
import NavigateHeader from '../../components/common-component/navigate-header';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import InputComponent from '../../components/common-component/form-elements/input-component';
import ButtonComponent from '../../components/common-component/form-elements/button-component';
import DocumentModal from '../../components/common-component/modals/document-modal';
import DoumentRemarksmodal from '../../components/common-component/modals/document-remarks-modal';
import DocumentUploadModal from '../../components/common-component/modals/document-upload-modal';
import DropdownComponent from '../../components/common-component/form-elements/dropdown-component';
import IconButton from '../../components/common-component/form-elements/icon-button';
import AlertBox from '../../components/common-component/alert-box';
import Loading from '../../components/common-component/modals/loading-screen';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../redux/action";
import { v4 as uuidv4 } from "uuid"
import RadioOrCheckbox from '../../components/common-component/form-elements/radio-or-checkbox';
import DeleteModal from '../../components/common-component/modals/delete-modal';
import { useNavigate } from 'react-router-dom'
import { BsPlusLg } from 'react-icons/bs';
import Changesmodal from '../../components/common-component/modals/changes-modal';
import "../../styles/product-admin/kyc-table.scss"
import FileUpload from '../../components/common-component/file-upload';


interface Option {
  value: string;
  label: string;
  id: string;
}

interface DocumentData {
  value: string;
  id: string;
  file: string;
  fileName: string;
  remarks: string;
  approvalStatus: string;
  companyKYCId: string;
  fileLocation: string;
  description: string;
}

const KycUpload: React.FC = () => {

  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // States for Input Fields
  const [kycData, setKycData] = useState({
    companyid: "",
    Companyname: "",
    Companyconstitution: ""
  })

  // State for overAll tableDetails Main State.
  const [documenttableDetails, setDocumentTableDetails] = useState<any>({
    value: "",
    id: "",
    file: "",
    fileName: "",
    remarks: "",
    approvalStatus: "",
    companyKYCId: "",
    fileLocation: "",
    description: "",
  })

  const [businessType, setBusinessType] = useState<any>([
    {
      label: "Proprietorship",
      value: "Proprietorship",
      id: 1
    },
    {
      label: "Partnership",
      value: "Partnership",
      id: 2
    },
    {
      label: "Private_limited",
      value: "Private_limited",
      id: 3
    },
    {
      label: "Public_limited",
      value: "Public_limited",
      id: 4
    },
    {
      label: "LLP",
      value: "LLP",
      id: 5
    },
    {
      label: "NGO",
      value: "NGO",
      id: 6
    },
    {
      label: "Trust",
      value: "Trust",
      id: 7
    },
    {
      label: "Society",
      value: "Society",
      id: 8
    },
  ]);

  const [documents, setDocuments] = useState<any>({
    1: [
      // { "documentType": 'Owner PAN/Signtory PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
      // { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
      { "documentType": "GST", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "documentType": "MSME", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "documentType": "Shops Establishment", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    2: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Partnership Deed", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    3: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Certificate of Incorporation", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    4: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Certificate of Incorporation", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    5: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Certificate of Incorporation", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    6: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "NGO certificate", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": '80G / 12A Form', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    7: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Trust Certificate", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
    8: [
      { "value": 'Business PAN', "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      // { "value": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Society Certificate", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
      { "value": "Cancelled Cheque", "fileName": "", "file": "", "remarks": '', "approvalStatus": "", "companyKYCId": "", "fileLocation": "", "description": "" },
    ],
  });

  const [documentTableMap, setDocumentTableMap] = useState<DocumentData[]>([])
  const [companyConstitution, setCompanyConstitution] = useState<any>([])
  // Options Pushing for director Option List
  const [directors, setDirectors] = useState<any>([])
  // Options Pushing for Company Option List
  const [companyNames, setcompanyNames] = useState<any>([])
  // States for Modal 
  const [currentFile, SetCurrentFile] = useState<any>({})
  const [currentRemarks, setCurrentRemarks] = useState("")
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [showDocumentRemarkModal, setShowDocumentRemarkModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<string>("")
  const [showType, setShowType] = useState<string>("warning")
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showChangesModal, setShowChangesModal] = useState(false);
  const [currentDeleteKyc, setCurrentDeleteKyc] = useState<any>("");
  const [userType, setUserType] = useState<any>("");
  const [userPrivilege, setuserPrivilege] = useState<any>("");
  const [selectedBusinessType, setSelectedBusinessType] = useState<number | null>(null);
  const [changedFiles, setChangedFiles] = useState<number[]>([]);

  // useEffect for getting dropdown values initially --------------------------------------------->
  useEffect(() => {
    getCompanyDetails();
  }, []);

  // UseEffect for Set a Business type ------------------------------>
  useEffect(() => {
    if (selectedBusinessType) {
      setDocumentTableMap(documents[selectedBusinessType]);
    }
  }, [selectedBusinessType, documents]);

  // UseEffect for Calling a User Type ------------------------------>
  useEffect(() => {
    getUsertype()
  }, []);

  // Use useEffect to call getOverallDetails when selectedBusinessType changes ------------->
  useEffect(() => {
    if (selectedBusinessType !== null) {
      getOverallDetails(parseInt(kycData.companyid))
    }
  }, [selectedBusinessType, kycData.companyid]);

  // Function for get user type --------------------------------------------->
  const getUsertype = async () => {
    try {
      const res = await axiosPrivate.get(`/user/get-privilege`)
      if (res.status === 200) {
        setUserType(res.data.UserType)
        setuserPrivilege(res.data.UserPrivilege)
      }
    }
    catch (error) {
    }
  }

  // Function for getting company details --------------------------------------------->
  const getCompanyDetails = async () => {
    try {
      const response = await axiosPrivate.get(`/customeronboarding/company-dropdown-list`);
      if (response.status === 200) {
        if (response.data.decryptedCompanys.length === 1 && userType !== "FingerTipUser") {
          setKycData({
            Companyname: response.data.decryptedCompanys[0].name,
            companyid: response.data.decryptedCompanys[0].id,
            Companyconstitution: response.data.decryptedCompanys[0].constitution
            // Companyconstitution: "Private Company"
          })
          getOverallDetails(response.data.decryptedCompanys[0].id)
          // const constitution = "Private Company";
          const constitution = response.data.decryptedCompanys[0].constitution;
          console.log(constitution, "constitution")
          const business = businessType.find((type: any) => type.value === constitution);
          if (business) {
            setSelectedBusinessType(business.id);
          }
        } else {
          let options: any = [];
          response.data.decryptedCompanys.map((item: any) => {
            options.push({
              label: item.name,
              value: item.name,
              id: item.id,
            })
          })
          setCompanyConstitution(response.data.decryptedCompanys)
          setcompanyNames(options)
        }
      }
    } catch (error: any) {

      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.message)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);

    }
  };

  // Fuinction for getting overall company details via API --------------------------------------------->
  const getOverallDetails = async (id: number) => {
    try {
      const response = await axiosPrivate.get(`/customeronboarding/documentproof/${id}`);
      if (response.status === 200) {
        if (response.data.DropDownData.kycRecordDetails) {

          console.log(response.data.kycRecordDetails)
          // setDocumentTableMap(response.data.DropDownData.kycRecordDetails);

          const fetchedDocuments = response.data.DropDownData.kycRecordDetails;

          if (selectedBusinessType !== null) {
            const updatedDocuments = documents[selectedBusinessType].map((doc: DocumentData) => {
              const matchingDocument = fetchedDocuments.find((fetchedDoc: any) => fetchedDoc.value === doc.value);

              if (matchingDocument) {
                return {
                  ...doc,
                  approvalStatus: matchingDocument.approvalStatus || doc.approvalStatus,
                  companyKYCId: matchingDocument.companyKYCId || doc.companyKYCId,
                  file: matchingDocument.file || doc.file,
                  fileName: matchingDocument.fileName || doc.fileName,
                  remarks: matchingDocument.description || doc.remarks,
                };
              }

              return doc;
            });
            setDocumentTableMap(updatedDocuments);
          }
        }
      }
    } catch (error: any) {

      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.message)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    }
  };

  // function for deleting the row --------------------------------------------->
  const handleDeleteRow = async () => {
    if (currentDeleteKyc) {
      try {
        const response = await axiosPrivate.patch(`/customeronboarding/kyc-files/${currentDeleteKyc}`);
        if (response.status === 200) {
          setCurrentDeleteKyc("")
          getOverallDetails(parseInt(kycData.companyid))
          setShowDeleteModal(false)

          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)

          setTimeout(() => {
            setShowAlertBox(false);
          }, 5000);
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("warning")
        setShowMessage(error.message)

        setTimeout(() => {
          setShowAlertBox(false);
        }, 5000);
      }
    } else {
      // const updatedDocumentTableMap = documentTableMap.filter((_, index) => index !== currentIndex);
      // setDocumentTableMap(updatedDocumentTableMap);
      const updatedDocumentTableMap = documentTableMap.map((doc, index) => {
        if (index === currentIndex) {
          return { ...doc, file: "", fileName: "" }; // Clear the file and fileName
        }
        return doc;
      });
      setDocumentTableMap(updatedDocumentTableMap);
      setShowDeleteModal(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("Record(s) successfully deleted.")

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    }
  };

  // Function for changing the company constituition by company name onchange --------------------------------------------->
  const CompanyDetails = (value: any, id: number) => {
    const foundPrivilege: any = companyConstitution.find((privilegeObj: any) => privilegeObj.id === id);
    if (foundPrivilege) {
      setKycData((prevState: any) => ({
        ...prevState,
        Companyconstitution: foundPrivilege.constitution,
      }));

      console.log(foundPrivilege.constitution, "foundPrivilege.constitution")

      const business = businessType.find((type: any) => type.value === foundPrivilege.constitution);
      if (business) {
        setSelectedBusinessType(business.id);
      }
      // getOverallDetails(id);
    };
  }

  // function for checking the updated the file before sending it  --------------------------------------------->
  const checkIfFile = (obj: any): boolean => {
    return obj instanceof File;
  }

  // Function for Submit Button --------------------------------------------->
  const handleSubmitApprove = async () => {
    setLoadingScreen(true)
    const formData = new FormData();

    // documentTableMap.forEach((doc, index) => {
    //   console.log(`Document ${index}:`, doc);
    // });

    try {
      documentTableMap.forEach((document, index) => {
        formData.append(`proofOfValue-file${index + 1}`, "");
        formData.append(`directorNameValue-file${index + 1}`, "");
        formData.append(`value-file${index + 1}`, document.value);

        if (checkIfFile(document.file)) {
          formData.append(`file${index + 1}`, document.file);
        } else {
          formData.append(`file${index + 1}`, '');
        }

        formData.append(`fileName-file${index + 1}`, document.fileName);
        formData.append(`remarks-file${index + 1}`, document.remarks);
        formData.append(`approvalStatus-file${index + 1}`, document.approvalStatus);
        formData.append(`companyKYCId-file${index + 1}`, document.companyKYCId);
        formData.append(`fileLocation-file${index + 1}`, document.fileLocation);
        formData.append(`description-file${index + 1}`, document.description);
      });

      const response = await axiosPrivate.post(`customeronboarding/add/kyc/${kycData.companyid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
        },
      });
      if (response.status === 200) {

        setLoadingScreen(false)
        dispatch(resetFormModified(false));
        setButtonDisabled(false);
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(response.data.message)

        setTimeout(() => {
          setShowAlertBox(false);
        }, 5000);

        getOverallDetails(parseInt(kycData.companyid))
      }

      // getDropdownValues();

    } catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.message)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    }
  }

  // Function for Cancel Button --------------------------------------------->
  const kycUploadCancel = () => {
    if (isFormDirty) {
      setShowChangesModal(true)
    } else {
      setDocumentTableDetails({
        value: "",
        id: "",
        file: "",
        remarks: "",
        approvalStatus: "",
        companyKYCId: "",
        fileLocation: "",
        description: "",
      })
      navigate("/product-admin/Customer-onboarding");
    }
  }

  // Onchange the Browsing File Section ---------------------------------------------------->
  function handleFileSelect(fileName: any, index: any) {

    const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    const validFormat = allowedFormats.includes(fileName[0].type);

    if (!validFormat) {
      setShowAlertBox(true);
      setShowType("warning");
      setShowMessage("Unsupported file format. Upload PDF or JPG or PNG file format.");

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);

      return; // Stop execution if the format is not valid
    }

    if (fileName[0].size > 2000000) {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage("File size exceeds the maximum 2MB. Compress the file and upload again")

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    }

    const updatedKycDocuments = documentTableMap.map((doc: any, i: any) => {
      if (i === index) {
        const message = doc.fileName ? `${fileName[0].name} updated successfully.` : `${fileName[0].name} uploaded successfully.`;
        setShowType("success")
        setShowMessage(message);
        setShowAlertBox(true);
        setTimeout(() => {
          setShowAlertBox(false);
        }, 5000);

        if (doc.file !== fileName[0]) {
          setChangedFiles((prev: any) => {
            // Avoid duplicate indexes
            if (!prev.includes(index)) {
              return [...prev, index];
            }
            return prev;
          });
        }

        return { ...doc, fileName: fileName[0].name, file: fileName[0] };
      }
      return doc;
    });
    setDocumentTableMap(updatedKycDocuments);

    // Clear the input value to ensure onChange is triggered for the same file
    const inputElement = document.querySelector(`#file-upload-${index}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  };

  // Button Disabled Condition Area ---------------------------->
  let allDocumentsHaveFiles = true;
  let allDocumentsRemarksStatus = false;
  // let fileChanged = false;
  documentTableMap.forEach((doc, index) => {
    if (doc.file === "" || doc.file === null) {
      allDocumentsHaveFiles = false;
    }
    if (doc.approvalStatus === "Declined") {
      allDocumentsRemarksStatus = true;
    }
    // if (doc.file !== documentTableMap[index].file) {
    //   fileChanged = true;
    // }
  });

  return (
    <>
      <MainPageCard>
        <div className='navigater sub-navigator'>
          <NavigateHeader
            style={userPrivilege === "FingertipAdmin" ? {
              cursor: "pointer"
            } : {}}
            firstValue={userPrivilege === "FingertipAdmin" ? "Fingertip Admin" : userPrivilege === "Customer maker"
              ? "Customer Maker" : "Organisation"}
            secondValue={"KYC"}
            navigatePage={() => {
              if (userPrivilege === "FingertipAdmin") {
                kycUploadCancel()
              }
            }}
          />
        </div>

        <div className={"main-div"}>
          <div className='form-top-main'>
            <div className='main-header'>
              {"Upload KYC"}
            </div>
            <div className='form-input'>
              {userType === "FingerTipUser" &&
                <div style={{ cursor: "pointer", zIndex: "2" }} key={uuidv4()}>
                  <DropdownComponent
                    options={companyNames}
                    title={"Company Name"}
                    required={true}
                    className={"proof-dropdown-fingertip-user"}
                    getData={(value: any) => {
                      setIsFormDirty(true)
                      setKycData((prevState) => ({
                        ...prevState,
                        Companyname: value.value,
                        companyid: value.id
                      }));
                      setDocumentTableDetails(
                        {
                          value: "",
                          id: "",
                          file: "",
                          remarks: "",
                          approvalStatus: "",
                          companyKYCId: "",
                          fileLocation: "",
                          description: "",

                        });
                      CompanyDetails(value.value, value.id,);
                      // getOverallDetails(value.id);
                    }}
                    defaultValue={[
                      {
                        label: kycData.Companyname === "" ? "Select" : kycData.Companyname,
                        value: kycData.Companyname === "" ? "Select" : kycData.Companyname,
                      }
                    ]}
                  />
                </div>
              }
              {userType === "FingerTipUser" &&
                <div className={"company-constitution"}>
                  <InputComponent
                    height={"40px"}
                    width={"650px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={"1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={50}
                    inputTitle={"Company Constitution"}
                    placeHolder={kycData.Companyconstitution === null || kycData.Companyconstitution === "" ? "Company Constitution" : kycData.Companyconstitution}
                    // placeHolder={kycData.Companyconstitution === null || kycData.Companyconstitution === "" ? "Business Type" : "Private Limited"}
                    readOnly
                    getUser={(value: any) => {
                      setIsFormDirty(true)
                      setKycData((prevState) => ({
                        ...prevState,
                        Companyconstitution: value,
                        // Companyconstitution: "Private Limited",
                      }));
                    }}
                  />
                </div>
              }
            </div>
          </div>

          <div className='document-table-component' >
            <table>
              <thead>
                <tr>
                  <th className='document-type'>Document Type</th>
                  <th className='document-type'>File Name</th>
                  <th className='approval-status'>Approval Status</th>
                  <th className='action'>Action</th>
                </tr>
              </thead>

              <tbody>
                {documentTableMap?.length === 0 ?
                  <div className='main-body-header'>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>No records to show</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div> :

                  documentTableMap.map((data: any, i: any) => {
                    return (
                      <div className='main-body-header' key={i}>
                        <tr>
                          <td className='document-type document-name'>{data.value}<span style={{ color: "red", fontWeight: "bold" }}>*</span></td>
                          <td className='document-type'>{data.fileName}</td>
                          <td className={`approval-status ${data.approvalStatus}`}>{data.approvalStatus}</td>
                          <td className='action' style={{ display: "flex", gap: "10px" }}>
                            <div>
                              <FileUpload
                                id={`file-upload-${i}`}
                                type={"browse-file-only"}
                                width={'120px'}
                                getFileName={(file) => {
                                  handleFileSelect(file, i)
                                }}
                              />
                            </div>
                            <IconButton
                              iconName={(showDocumentModal && currentFile === data.file) ? "Eye" : "ShowEye"}
                              height={"35px"}
                              width={"30px"}
                              fontSize={""}
                              className={"icons"}
                              cursor={data.file ? "pointer" : "not-allowed"}
                              opacity={data.file ? "1" : "0.5"}
                              color={"white"}
                              backgroundColor={""}
                              disabled={!data.file ? false : true}
                              hover={!data.file ? false : true}
                              handleClick={() => {
                                if (data.file) {
                                  setShowDocumentModal(!showDocumentModal);
                                  SetCurrentFile(data.file)
                                }
                                // SetCurrentFile('VendorOverview/KycDocuments/rb@gmail.com/image-520x100.jpg')
                              }}
                            />

                            <IconButton
                              iconName={(data.remarks === "" || data.remarks === null) ? "MessageNotify" : "Message"}
                              height={"35px"}
                              width={"30px"}
                              fontSize={""}
                              className={"icons"}
                              cursor={(data.remarks === "" || data.remarks === null) ? "not-allowed" : "pointer"}
                              disabled={(data.remarks === "" || data.remarks === null) ? true : false}
                              opacity={(data.remarks === "" || data.remarks === null) ? "0.5" : "1"}
                              color={"white"}
                              backgroundColor={""}
                              hover={false}
                              handleClick={() => {
                                if (data.remarks === "" || data.remarks === null) return;
                                setShowDocumentRemarkModal(!showDocumentRemarkModal)
                                setCurrentRemarks(data.remarks)
                              }}
                            />

                            <IconButton
                              iconName={"Delete"}
                              height={"30px"}
                              width={"35px"}
                              fontSize={"20"}
                              className={"icons"}
                              cursor={data.approvalStatus === "Approved" || data.approvalStatus === "Pending Approval" ? "not-allowed" : "pointer"}
                              disabled={data.approvalStatus === "Approved" || data.approvalStatus === "Pending Approval" ? true : false}
                              opacity={data.approvalStatus === "Approved" || data.approvalStatus === "Pending Approval" ? "0.5" : "1"}
                              color={"white"}
                              backgroundColor={"red"}
                              hover={false}
                              handleClick={() => {
                                if (data.approvalStatus === "Approved" || data.approvalStatus === "Pending Approval"
                                  // || (data.value === "GST" && data.proofOfValue === "Company") || (data.value === "PAN" && data.proofOfValue === "Company")
                                ) {
                                  return
                                }
                                setShowDeleteModal(true)
                                setCurrentIndex(i)
                                setCurrentDeleteKyc(data.companyKYCId)
                              }}
                            />
                          </td>
                        </tr>
                      </div>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className={"kyc-buttons"}>

          {userPrivilege === "FingertipAdmin" &&
            <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"130px"}
              margin={"0px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={kycUploadCancel}
            />
          }

          <ButtonComponent
            title={"Save"}
            height={"50px"}
            width={"130px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            margin={"0px"}
            disabled={!(allDocumentsHaveFiles && changedFiles.length > 0)}
            className={(allDocumentsHaveFiles && changedFiles.length > 0) ? "button-component-hover common-btn" : "button-component-hover disabled"}
            handleClick={handleSubmitApprove}
          />
        </div>

        {showDocumentModal &&
          <DocumentModal                     //Eye button Component
            closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
            document={currentFile}
            showDocumentModal={showDocumentModal}
          />}

        {showDocumentRemarkModal &&          // Remarks Button Component
          <DoumentRemarksmodal
            clearDoumentRemarksmodal={(val: boolean) => { setShowDocumentRemarkModal(val); }}
            remarks={currentRemarks}
          />}

        {showDeleteModal && <DeleteModal     //Delete Button Component
          clearValue={(val: boolean) => { setShowDeleteModal(val); }}
          getDelete={handleDeleteRow}
          modelType={"grid-delete"}
        />}

        {
          showChangesModal &&
          <Changesmodal
            closeModal={() => setShowChangesModal(false)}
            leavePage={() => {
              setShowChangesModal(false);
              setIsFormDirty(false)
              navigate("/product-admin/Customer-onboarding");
            }}
            handleClose={() => setShowChangesModal(false)}
          />
        }

      </ MainPageCard >

      {showAlertBox && <AlertBox type={showType} message={showMessage} />
      }
      {loadingScreen && <Loading />}
    </>
  )
}

export default KycUpload;