import { useState, useEffect, useRef } from 'react';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import SliderComponent from '../../common-component/slider-component';
import "../../../styles/payable/vendor/vendor-form.scss"
import EnterInvoiceDetails from './enter-invoice-details';
import ReviewDetails from './review-details';
import SelectPaymentMethod from './select-payment-method';
import AlertBox from '../../common-component/alert-box';
import { axiosPrivate } from '../../../middleware/axois-api';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormModified } from '../../../redux/action';
import VendorPaymentInvoice from '../../common-component/modals/vendor-payment-invoice';
import DeleteModal from '../../common-component/modals/delete-modal';
import Changesmodal from '../../common-component/modals/changes-modal';
import Loading from '../../common-component/modals/loading-screen';
import BulkPaymentAgGrid from './bulk-payment-ag-grid';
import CustomEllipsisRenderer from '../../../config/dashboard/customers-vs-transaction-value-config';
import ButtonComponent from '../../common-component/form-elements/button-component';
import NumericCellEditor from './amount-Input-ag-grid';
import { NumericEditor } from './numericeditor';
import InputComponent from '../../common-component/form-elements/input-component';
import IconButton from '../../common-component/form-elements/icon-button';
import { AmountRenderer } from '../../../helpers/amountRenderer';
import EditbulkPopup from './edit-bulk-Popup';
import AgGrid from '../../common-component/grids-and-tables/ag-grid';
import PaymentResultModal from './payment-result-popup';
import axios from 'axios';
import RupayOtpModal from '../../common-component/modals/rupay-otp-modal';
import UpiwaitingModal from '../../common-component/modals/upi-waiting-modal';

interface Props {
    vendorVewFun: (val: any) => void;
    approvalProcessFlow: any[];
    currentActor: string;
    isEditMode: boolean;
    isBulkPayment: boolean;
    editData: any
}

export interface cardDetails {
    bankName: string;
    percentage: number;
    cardId: string | number,
    limit: number;
    insideCardBalance: number;
    lastBilledDue: number;
    cardNumber: number;
    cardNetwork: string;
    dueIn: number | string;
    billingCycle: string;
    processing: number;
    outsideCardBalance: number;
    backgroundColor: string;
}

const VendorForm: React.FC<Props> = ({ vendorVewFun, isBulkPayment, currentActor, isEditMode, editData, approvalProcessFlow }) => {
    const dispatch = useDispatch();
    const AgGridRefPaymentform = useRef<any>(null);
    const [invoice, SetInvoice] = useState<any>(); // store invoice copy as file | url
    const [bulktotal, setTotal] = useState<any>("");
    const [bulkPaymenttotal, setBulkPaymentTotal] = useState<any>("");
    const [gstinDD, setGstinDD] = useState<any[]>([]);
    const [activeTab, SetActiveTab] = useState<number>(0); //setcurrent tab
    const [cards, setCards] = useState<cardDetails[]>([]); //store company cards
    const [showMessage, setShowMessage] = useState<string>("") //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning") //AlertBox warning Statef
    const [status, setStatus] = useState<string>("")
    const [transactionUuid, SetTransactionUuid] = useState<string>("")
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false) //AlertBox open State
    const [openRupayOtpModal, setOpenRupayOtpModal] = useState<boolean>(false)
    const [openUpiWaitingModal, setOpenUpiWaitingModal] = useState<boolean>(false)
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false) //Loading Screen
    const [approvalHistory, setApprovalHistory] = useState<any[]>([]); // approvalHistory 
    const [paymentHistory, setPaymentHistory] = useState<any[]>([]); // paymentHistory 
    const [invoiceShowmodal, setInvoiceShowmodal] = useState<any>(false); // open invoice 
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // Delete Modal 
    const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
    const [currentEditBulk, setCurrentEditBulk] = useState<any>({});
    const [editBulk, setEditBulk] = useState<boolean>(false);
    const [partPaymetFieldsBlock, setpartPaymetFieldsBlock] = useState<boolean>(false);
    const [invoiceDataErrors, setInvoiceDataErrors] = useState<Record<string, boolean>>({});// error displayer 
    const [messagePopUp, setMessagePopUp] = useState<boolean>(false);
    const [invoiceDetails, SetInvoiceDetails] = useState<any>({
        invoicePayableId: "",
        invoiceNumber: "",
        invoiceDate: "",
        expenseType: "",
        expenseTypeID: "",
        dueDate: "",
        vendorType: "Registered",
        vendorName: "",
        vendorNameID: "",
        GSTIN: "",
        accountNo: "",
        IFSC: "",
        paymentFrequeny: "Immediate",
        scheduledDate: "",
        paymentTerms: "Full Payment",
        baseAmount: "",
        discount: "",
        subTotal: "",
        GST: "",
        GSTpercentage: "",
        invoiceAmount: "",
        TDS: "",
        TDSpercentage: "",
        paymentAmount: "",
        amountToVendor: "",
        amountPaid: "",
        amountDue: "",
        vendorEmail: "",
        companyName: "",
        companyEmail: "",
        companyContactNumber: "",
        vendorRazorpayNameId: "",
        vendorRazorpayName: ""
    }); // store invoice details  

    const [transactionDetails, setTransactionDetails] = useState<any>({})
    const [rateCardDetails, setRateCardDetails] = useState<any>([])

    const [Banks, setBanks] = useState<any[]>([]);
    const [Upis, setUpis] = useState<any[]>([]);
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [bulkInvoices, setBulkInvoices] = useState<any[]>([]);
    const [cardCvv, setCardCvv] = useState<string>('');
    const [rupaytranctionDetails, setRupaytranctionDetails] = useState<any>({});
    const [upiTranctionDetails, setUpiTranctionDetails] = useState<any>({});

    const [selectedPaymentMethod, SetSelectedPaymentMethod] = useState({
        PaymentMethod: "Corporate Card",
        virtualcardId: "",
        cardId: "",
        bankCode: "",
        bankName: "",
        UpiCode: "",
        UpiId: ""
    }); // store PaymentMethod & catd id

    const [BulkColumnHeader, setBulkColumnHeader] = useState([
        {
            field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: true, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
        },
        {
            field: 'vendorName', headerName: 'Vendor Name', minWidth: 280, resizable: false, suppressMovable: true, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'invoiceNumber', headerName: 'Invoice Number', minWidth: 220, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        // { 
        //     field: '', headerName: 'GSTIN', minWidth: 180, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, headerClass: 'header-text-align', filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        // },
        {
            field: 'accountNo', headerName: 'Account Number', minWidth: 200, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'IFSC', headerName: 'IFSC Code', minWidth: 200, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'baseAmount', headerName: 'Payment Amount', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: {
                textAlign: 'right',
            }, cellClassRules: {
                'baseAmount-cell': (params: any) => params.value,
            },
            filtertype: "number", cellRendererFramework: AmountRenderer,
        },
        {
            field: 'amountToVendor', headerName: 'Amount to Vendor', headerClass: 'header-right-align', minWidth: 280, resizable: false, suppressMovable: false, filter: true, suppressSizeToFit: false, cellStyle: { textAlign: 'right' },
            filtertype: "number", cellRendererFramework: AmountRenderer,
            // editable: true,
            // cellEditor: NumericEditor,
            // cellRendererFramework: (params: any) => { return <NumericCellEditor onUpdate={onUpdate} highlightedRows={highlightedRows} {...params} /> },
        },

    ]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]); // setSelected Rows in bulk grid 

    // mandatory fields are Vendor Name, A/c No, ifsc code, Amount, Inv No, 
    const validInvoice: any = {
        isValid: {
            invoiceNumber: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            invoiceDate: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            expenseType: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            dueDate: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            vendorType: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            vendorName: {
                regex: "",
                field: invoiceDetails.vendorRazorpayNameId ? "" : "mandatory",
                shouldNotBe: ""
            },
            GSTIN: {
                regex: invoiceDetails.vendorType === "Registered" ? "" : /^[0-9]{2}[A-Z]{5}[0-9]{4}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$/,
                field: invoiceDetails.vendorType === "Registered" ? "" : "mandatory",
                shouldNotBe: ""
            },
            accountNo: {
                regex: invoiceDetails.vendorType === "Registered" ? "" : /^\d+$/,
                field: invoiceDetails.vendorType === "Registered" ? "" : "mandatory",
                shouldNotBe: ""
            },
            IFSC: {
                regex: invoiceDetails.vendorType === "Registered" ? "" : /^[A-Z]{4}0[A-Z0-9]{6}$/,
                field: invoiceDetails.vendorType === "Registered" ? "" : "mandatory",
                shouldNotBe: ""
            },
            paymentFrequeny: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            scheduledDate: {
                regex: invoiceDetails.paymentFrequeny === "Immediate" ? "" : "",
                field: invoiceDetails.paymentFrequeny === "Immediate" ? "" : "mandatory",
                shouldNotBe: ""
            },
            paymentTerms: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            baseAmount: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            discount: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            subTotal: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            GST: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            GSTpercentage: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            invoiceAmount: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            TDS: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            TDSpercentage: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            paymentAmount: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            amountToVendor: {
                regex: "",
                field: invoiceDetails.paymentTerms === "Full Payment" ? "" : "mandatory",
                shouldNotBe: ""
            },
            amountPaid: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            amountDue: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
        }
    }; // invoice details  validator

    useEffect(() => {
        if (currentActor === "Approver" || currentActor === "singleUser") {
            fetchCard();
            fetchBanks();
            getUpi()
        }
        handleTransactionData()
    }, []);

    // useEffect(() => {
    //     const rowIndex = bulkInvoices.findIndex(invoice =>
    //         invoice.invoice_payable_bulk_id === currenteditedRow.invoice_payable_bulk_id);
    //     // Create a copy of bulkInvoices to avoid mutating state directly
    //     let updatedBulkInvoices = [...bulkInvoices];
    //     // Update the value in the copy if the rowIndex is valid
    //     if (rowIndex !== -1) {
    //         updatedBulkInvoices[rowIndex].amountToVendor = currentedited;
    //         setBulkInvoices(updatedBulkInvoices)
    //         let total = updatedBulkInvoices.reduce((sum: any, item: any) => (sum + parseFloat(item.amountToVendor)), 0);
    //         setTotal(total.toFixed(2))
    //     } else {
    //         // console.error(`Row with invoice_payable_bulk_id ${currenteditedRow.invoice_payable_bulk_id} not found.`);
    //     }
    // }, [currentedited, currenteditedRow])


    // Function for get transaction datas---------------->
    const handleTransactionData = async () => {
        try {
            const response = await axiosPrivate.get(`/get/company/transaction-charges/details/${editData.payableInvoice.companyID}`)
            if (response.status === 200) {
                setTransactionDetails(response.data.transactionData)
                setRateCardDetails(response.data?.rateCardDetails)

                console.log(response)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    useEffect(() => {
        let total = bulkInvoices.reduce((sum: any, item: any) => (sum + parseFloat(item.amountToVendor)), 0);
        let bulkPaymentTotal = bulkInvoices.reduce((sum: any, item: any) => (sum + parseFloat(item.baseAmount)), 0);
        setTotal(total.toFixed(2))
        setBulkPaymentTotal(bulkPaymentTotal.toFixed(2))
    }, [bulkInvoices])

    // isEditMode useEffect
    useEffect(() => {
        if (editData.bulkInvoices && editData.bulkInvoices.length > 0) {
            // The editData.bulkInvoices array has a length greater than 0
            setBulkInvoices(editData.bulkInvoices);
            let total = editData.bulkInvoices.reduce((sum: any, item: any) => (sum + parseFloat(item.amountToVendor)), 0);
            let bulkPaymentTotal = editData.bulkInvoices.reduce((sum: any, item: any) => (sum + parseFloat(item.baseAmount)), 0);
            setTotal(total.toFixed(2));
            setBulkPaymentTotal(bulkPaymentTotal.toFixed(2))

            setApprovalHistory(editData.approvalStatus);
            setPaymentHistory(editData.paymentHistory);
            SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                ...selectedPaymentMethod,
                PaymentMethod: editData.payableInvoice.PaymentMethod ? editData.payableInvoice.PaymentMethod : "Corporate Card",
                cardId: editData.payableInvoice.cardId ? editData.payableInvoice.cardId : "",
            }));
        } else {
            if (!isEditMode) return;
            SetInvoiceDetails((invoiceDetails: any) => ({
                ...invoiceDetails,
                companyID: editData.payableInvoice.companyID || "",
                invoicePayableId: editData.payableInvoice.invoicePayableId || "",
                invoiceNumber: editData.payableInvoice.invoiceNumber || "",
                invoiceDate: editData.payableInvoice.invoiceDate || "",
                expenseType: editData.payableInvoice.expenseType || "",
                expenseTypeID: editData.payableInvoice.expenseTypeID || "",
                dueDate: editData.payableInvoice.dueDate || "",
                vendorType: editData.payableInvoice.vendorType || "",
                vendorName: editData.payableInvoice.vendorName || "",
                vendorEmail: editData.payableInvoice.vendorEmail || "",
                vendorContactNumber: editData.payableInvoice.vendorContactNumber || "",
                vendorNameID: editData.payableInvoice.vendorNameID || "",
                GSTIN: editData.payableInvoice.GSTIN || "",
                accountNo: editData.payableInvoice.accountNo || "",
                IFSC: editData.payableInvoice.IFSC || "",
                paymentFrequeny: editData.payableInvoice.paymentFrequeny || "",
                scheduledDate: editData.payableInvoice.scheduledDate || "",
                paymentTerms: editData.payableInvoice.paymentTerms || "",
                baseAmount: editData.payableInvoice.baseAmount || "",
                discount: editData.payableInvoice.discount || "",
                subTotal: editData.payableInvoice.subTotal || "",
                GST: editData.payableInvoice.GST || "",
                GSTpercentage: editData.payableInvoice.GSTpercentage || "",
                invoiceAmount: editData.payableInvoice.invoiceAmount || "",
                TDS: editData.payableInvoice.TDS || "",
                TDSpercentage: editData.payableInvoice.TDSpercentage || "",
                paymentAmount: editData.payableInvoice.paymentAmount || "",
                amountToVendor: editData.payableInvoice.amountToVendor || "",
                amountPaid: editData.payableInvoice.amountPaid || "",
                amountDue: editData.payableInvoice.amountDue || "",
                vendorCompanyId: editData.payableInvoice.vendorCompanyId || "",
                companyId: editData.payableInvoice.companyId || "",
                companyName: editData.payableInvoice.companyName || "",
                companyEmail: editData.payableInvoice.companyEmail || "",
                companyContactNumber: editData.payableInvoice.companyContactNumber || "",
                vendorRazorpayName: editData.payableInvoice.vendorRazorpayName || "",
                vendorRazorpayNameId: editData.payableInvoice.vendorRazorpayNameId || ""
            }));

            if (editData.payableInvoice.amountPaid) {
                setpartPaymetFieldsBlock(true);
            }
            SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                ...selectedPaymentMethod,
                PaymentMethod: editData.payableInvoice.PaymentMethod ? editData.payableInvoice.PaymentMethod : "Corporate Card",
                cardId: editData.payableInvoice.cardId || "",
            }));
            if (editData.payableInvoice.vendorNameID) {
                getVendorAccountData(editData.payableInvoice.vendorNameID);
            }
            SetInvoice(editData.payableInvoice.invoice);
            setApprovalHistory(editData.approvalStatus);
            setPaymentHistory(editData.paymentHistory);
        }

        if (editData.payableInvoice.canEditable === false && isEditMode) {
            SetActiveTab(2);
        }

    }, [editData])


    const getVendorAccountData = async (id: number) => {
        const response = await axiosPrivate.get(`/vendor-payable/Gstin-Account-Ifsc/${id}`);
        if (response.status === 200) {
            if (response.data.decryptGstn.length === 1) {
                SetInvoiceDetails((invoiceDetails: any) => ({
                    ...invoiceDetails,
                    GSTIN: response.data.decryptGstn[0],
                }));
                setGstinDD([]);
            } else {
                const dropdownVendorName = response.data.decryptGstn.map((item: string) => ({
                    label: item,
                    value: item,
                    id: item,
                }));
                setGstinDD(dropdownVendorName);
            }
            SetInvoiceDetails((invoiceDetails: any) => ({
                ...invoiceDetails,
                accountNo: response.data.accountNo,
                IFSC: response.data.ifscCode,
            }));
        }
    };

    // Warning Msg clearAleart
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            if (status === 200) {
                dispatch(resetFormModified(false));
            }
            if (status === "grid") {
                vendorVewFun("grid")
                dispatch(resetFormModified(false));
            }
            clearTimeout(timer);
        }, 5000);
    };

    // invoice details  validator function
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(validInvoice.isValid).forEach((field) => {
            const rule = validInvoice.isValid[field];
            const value = invoiceDetails[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If   a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setInvoiceDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    const getCurruntData = (val: any) => {
        setSelectedRows(val)
    }

    const getUpi = async () => {
        try {
            const response = await axiosPrivate.get(`list/upi-id`);
            if (response.status === 200) {
                const dropdownExpenceDD = response.data.upis.map((item: any) => ({
                    label: item.decrypted_upi,
                    value: item.decrypted_upi,
                    id: item.upi_id,
                }));
                const sortedExpenceDD = [...dropdownExpenceDD].sort((a, b) => a.label.localeCompare(b.label));
                setUpis(sortedExpenceDD);
            }
        } catch (error) {
        }
    };

    const fetchCard = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.get(`/vendor-payable/card-details/get`);
            setCards(response.data.decryptCardDetails)
            setLoadingScreen(false);
        } catch (error) {
            setLoadingScreen(false);;
        }
    };

    const fetchBanks = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.get(`/vendor-payable/banks/get`);
            if (response.status === 200) {
                let allBanks = response.data.banks.map((item: any) => ({
                    label: item.bankName,
                    value: item.bankName,
                    id: item.bankCode,
                }));
                setBanks(allBanks)
            }
            setLoadingScreen(false);
        } catch (error) {
            setLoadingScreen(false);;
        }
    };

    const DeleteBulkInvoice = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.patch("vendor-payable/invoice/delete-bulk", { selectedRows });
            if (response.status === 200) {
                setLoadingScreen(false);
                if (response.data.deletedStatus) {
                    const updatedBulkInvoices = bulkInvoices.filter((invoice) => {
                        return !selectedRows.some((data) => data.invoice_payable_bulk_id === invoice.invoice_payable_bulk_id);
                    });
                    setBulkInvoices(updatedBulkInvoices);
                    setShowMessage(response.data.message);
                    setShowType("success");
                    setShowAlertBox(true);
                    setShowDeleteModal(false);
                    if (response.data.isAllDeleted === true) {
                        clearAleart("grid");
                    } else {
                        clearAleart("");
                    }
                }
            }
        } catch (error) {
            setLoadingScreen(false);
            setShowDeleteModal(false);
        }


        // try {
        //     const response = await axiosPrivate.delete(`/v1/vendor-payable/invoice/delete-bulk/:id`);
        //     // selectedRows
        //     setLoadingScreen(false);
        // } catch (error) {
        //     setLoadingScreen(false);;
        // }
    };

    const isDateValid = () => {
        const invoiceDate: Date = new Date(invoiceDetails.invoiceDate);
        const scheduledDate: Date = new Date(invoiceDetails.scheduledDate);
        const dueDate: Date = new Date(invoiceDetails.dueDate);
        if (invoiceDetails.invoiceDate || invoiceDetails.scheduledDate || invoiceDetails.dueDate) {
            const today = new Date();
            const lastYear = new Date(today);
            lastYear.setFullYear(today.getFullYear() - 1);
            if (invoiceDate < today && invoiceDate > lastYear) {
                if (invoiceDetails.invoiceDate && invoiceDetails.dueDate && !invoiceDetails.scheduledDate) {
                    if (invoiceDate > dueDate) {
                        setInvoiceDataErrors((prevErrors) => ({
                            ...prevErrors,
                            dueDate: true,
                        }));
                        return false;
                    } else {
                        return true;
                    }
                } else if (invoiceDetails.invoiceDate && !invoiceDetails.dueDate && invoiceDetails.scheduledDate) {
                    if (invoiceDate > scheduledDate) {
                        setInvoiceDataErrors((prevErrors) => ({
                            ...prevErrors,
                            scheduledDate: true,
                        }));
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (invoiceDate > dueDate && invoiceDate > scheduledDate) {
                        setInvoiceDataErrors((prevErrors) => ({
                            ...prevErrors,
                            dueDate: true,
                            scheduledDate: true,
                        }));
                        return false;
                    } else if (invoiceDate > scheduledDate) {
                        setInvoiceDataErrors((prevErrors) => ({
                            ...prevErrors,
                            scheduledDate: true,
                        }));
                        return false;
                    } else if (invoiceDate > dueDate) {
                        setInvoiceDataErrors((prevErrors) => ({
                            ...prevErrors,
                            dueDate: true,
                        }));
                    } else {
                        return true;
                    }
                }
            } else {
                setInvoiceDataErrors((prevErrors) => ({
                    ...prevErrors,
                    invoiceDate: true,
                }));
                return false;
            }
        } else {
            return true;
        }
    };

    // function for Enter invoice next button
    const enterInvoiceNext = async () => {
        const isvalidInvoice = validateForm();
        if (isvalidInvoice) {
            const isvalidDate = isDateValid();
            if (isvalidDate === false) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Some of the field(s) are not in required format.");
                clearAleart('');
                return;
            }
            if (parseFloat(invoiceDetails.baseAmount) <= 0) {
                setInvoiceDataErrors((prevErrors) => ({
                    baseAmount: true,
                }));
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Base Amount cannot be less than 0.");
                clearAleart('');
                return;
            }
            if (parseFloat(invoiceDetails.baseAmount) <= parseFloat(invoiceDetails.discount)) {
                setInvoiceDataErrors((prevErrors) => ({
                    discount: true,
                }));
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Discount amount should be less than base amount.");
                clearAleart('');
                return;
            }
            if (parseFloat(invoiceDetails.amountToVendor) <= 0) {
                setInvoiceDataErrors((prevErrors) => ({
                    amountToVendor: true,
                }));
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Amount to vendor cannot be less than 0.");
                clearAleart('');
                return;
            }
            let partPaymentAmount = parseFloat((parseFloat(invoiceDetails.paymentAmount) - (parseFloat(invoiceDetails.amountPaid) || 0)).toFixed(2))
            if (partPaymentAmount < parseFloat(invoiceDetails.amountToVendor)) {
                setInvoiceDataErrors((prevErrors) => ({
                    amountToVendor: true,
                }));
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Amount to vendor cannot exceed Payment amount.");
                clearAleart('');
                return;
            }

            try {
                const response = await axiosPrivate.patch(`/vendor-payable/invoice/validate-payable-amount`, {
                    amountToVendor: invoiceDetails.amountToVendor,
                    vendorNameID: invoiceDetails.vendorNameID,
                    invoiceNumber: invoiceDetails.invoiceNumber,
                    invoicePayableId: editData?.payableInvoice?.invoicePayableId ? editData?.payableInvoice?.invoicePayableId : "",
                });
                if (response.status === 200) {
                    if (response.data.isValid) {
                        if (currentActor !== "Approver" && currentActor !== "singleUser") {
                            SetActiveTab(2);
                        } else {
                            SetActiveTab(1);
                        }

                    } else {
                        setShowAlertBox(true);
                        setShowType("warning")
                        setShowMessage(response.data.message)
                        clearAleart('');
                    }
                }
            } catch (error: any) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('');
        }
    };

    // function for Enter invoice next button
    const BulkInvoiceNext = async () => {
        const highlightedRows: number[] = [];
        for (const row of bulkInvoices) {
            if (row.amountToVendor === '' || parseFloat(row.amountToVendor) === 0 || parseFloat(row.baseAmount) < parseFloat(row.amountToVendor)) {
                highlightedRows.push(row.invoice_payable_bulk_id);
            }
        }
        if (highlightedRows && highlightedRows.length === 0) {
            try {
                const response = await axiosPrivate.patch(`/vendor-payable/invoice/validate-payable-amount`, {
                    amountToVendor: bulktotal
                });
                if (response.status === 200) {
                    if (response.data.isValid) {
                        if (currentActor !== "Approver" && currentActor !== "singleUser") {
                            SetActiveTab(2);
                        } else {
                            SetActiveTab(1);
                        }
                    } else {
                        setShowAlertBox(true);
                        setShowType("warning")
                        setShowMessage(response.data.message)
                        clearAleart('');
                    }
                }
            } catch (error: any) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Amount to vendor should not be zero and greater than Payment Amount")
            clearAleart('');
        }
    };

    const paymentMethodValidator = () => {
        const { PaymentMethod, cardId, virtualcardId, bankName, UpiCode } = selectedPaymentMethod;

        if (PaymentMethod === "") {
            return { result: false, message: "Select Payment Method" };
        }

        if (PaymentMethod === "Corporate Card") {
            return { result: cardId !== "", message: "Select a Corporate card to proceed" };
        }

        if (PaymentMethod === "Virtual Card") {
            return { result: virtualcardId !== "", message: "Select a virtual card to proceed" };
        }

        if (PaymentMethod === "Net Banking") {
            return { result: bankName !== "", message: "Select a bank to proceed" };

        }
        if (PaymentMethod === "UPI") {
            return { result: UpiCode !== "", message: "Select a UPI to proceed" };

        }

        // Handle unknown PaymentMethod
        return { result: false, message: "Select Payment Method" };
    };

    // function for select payment next button 
    const paymentNext = async () => {
        let goNext = paymentMethodValidator();
        if (goNext.result) {

            try {
                const response = isEditMode ?
                    await axiosPrivate.patch(`/vendor-payable/invoice/validate-payable-card/${editData.payableInvoice.invoicePayableId}`, {
                        cardId: selectedPaymentMethod.cardId,
                        expenseType: invoiceDetails.expenseTypeID,
                        amountToVendor: invoiceDetails.amountToVendor
                    }) :
                    await axiosPrivate.patch(`/vendor-payable/invoice/validate-payable-card`, {
                        cardId: selectedPaymentMethod.cardId,
                        expenseType: invoiceDetails.expenseTypeID,
                        amountToVendor: invoiceDetails.amountToVendor
                    });
                if (response.status === 200) {
                    if (response.data.isValid) {
                        SetActiveTab(2);
                    } else {
                        setShowAlertBox(true);
                        setShowType("warning")
                        setShowMessage(response.data.message)
                        clearAleart('');
                    }
                }
            } catch (error: any) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(goNext.message)
            clearAleart('');
        }
    };

    const enterInvoiceBack = () => {
        vendorVewFun("grid");
        dispatch(resetFormModified(false));
    };

    const paymentBack = () => {
        SetActiveTab(0)
    };


    const reviewDetailsBack = () => {
        if (editData?.payableInvoice?.canEditable !== undefined && editData.payableInvoice.canEditable === false) {
            vendorVewFun("grid");
        } else {
            (currentActor !== "Approver" && currentActor !== "singleUser") ?
                SetActiveTab(0) : SetActiveTab(1);
        }
    };

    // Function for save the form data.
    const handleSaveForm = async () => {
        const isvalidInvoice = validateForm();
        const formData = new FormData();
        formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
        formData.append('invoice', invoice);
        formData.append('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
        if (isvalidInvoice) {
            try {
                const res = isEditMode ? await axiosPrivate.patch(`/vendor-payable/invoice/draft/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file uploads
                    },
                }) : await axiosPrivate.post(`/vendor-payable/invoice/draft`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file uploads
                    },
                });
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                console.log(error)
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.message)
                clearAleart('');
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('');
        }
    };

    // function to check file is a file or not
    const checkIfFile = (obj: any): boolean => {
        return obj instanceof File;
    };

    const SingleUserPayMent = async (val: any, elem: any) => {
        if (isBulkPayment) {
            setLoadingScreen(true)
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice-bulk/update/${editData.payableInvoice.invoicePayableId}`,
                    {
                        selectedPaymentMethod: { ...selectedPaymentMethod, cardCvv: cardCvv },
                        approvalStatus: {
                            status: "Approved",
                            remarks: "",
                        },
                        bulkInvoices: bulkInvoices
                    }
                );
                if (res.status === 200) {
                    setLoadingScreen(false);
                    // callPayment(res);
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)
                clearAleart('');
            }
        } else {
            setLoadingScreen(true);
            const formData = new FormData();
            formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
            formData.append('paymentStatus', JSON.stringify({
                razorpay_payment_id: val,
                razorpay_order_id: elem,
            }));
            formData.append('invoice', invoice);
            formData.append('selectedPaymentMethod', JSON.stringify({ ...selectedPaymentMethod, cardCvv: cardCvv }));
            if (isEditMode) {
                formData.append('approvalStatus', JSON.stringify({
                    status: "Updated",
                    remarks: "",
                }));
            }
            try {
                const res = !isEditMode ? await axiosPrivate.post(`/vendor-payable/invoice/create`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file uploads
                    },
                }) : await axiosPrivate.patch(`/vendor-payable/invoice/update/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
                    },
                });

                if (res.status === 200) {
                    setLoadingScreen(false);
                    vendorVewFun("grid")
                    // callPayment(res);
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.message);
            }
        }
    };

    const MakerSubmit = async () => {
        if (isBulkPayment) {
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice-bulk/update/${editData.payableInvoice.invoicePayableId}`,
                    {
                        selectedPaymentMethod: selectedPaymentMethod,
                        approvalStatus: {
                            status: "Initiated",
                            remarks: "",
                        },
                        bulkInvoices: bulkInvoices
                    }
                );
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.message)
                clearAleart('');
            }

        } else {
            setLoadingScreen(true);
            const formData = new FormData();
            formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
            if (checkIfFile(invoice)) {
                formData.append(`invoice`, invoice);
            } else {
                formData.append(`invoice`, '');
            }
            formData.append('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
            if (isEditMode) {
                formData.append('approvalStatus', JSON.stringify({
                    status: "Resubmited",
                    remarks: "",
                }));
            }
            try {
                const res = !isEditMode ? await axiosPrivate.post(`/vendor-payable/invoice/create`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file uploads
                    },
                }) : await axiosPrivate.patch(`/vendor-payable/invoice/update/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
                    },
                });

                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.message);
            }

        }
    };

    const checkerRecommenderApproval = async () => {
        if (isBulkPayment) {
            setLoadingScreen(true)
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice-bulk/update/${editData.payableInvoice.invoicePayableId}`,
                    {
                        selectedPaymentMethod: selectedPaymentMethod,
                        approvalStatus: {
                            status: "Approved",
                            remarks: "",
                        },
                        bulkInvoices: bulkInvoices
                    }
                );
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.message)
                clearAleart('');
            }
        } else {
            setLoadingScreen(true)
            const formData = new FormData();
            formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
            if (checkIfFile(invoice)) {
                formData.append(`invoice`, invoice);
            } else {
                formData.append(`invoice`, '');
            }
            formData.append('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
            formData.append('approvalStatus', JSON.stringify({
                status: "Approved",
                remarks: "",
            }));

            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice/update/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
                    },
                });
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }

            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.message);
            }
        }
    };

    const Declain = async (remarks: any) => {
        if (isBulkPayment) {
            setLoadingScreen(true);
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice-bulk/update/${editData.payableInvoice.invoicePayableId}`,
                    {
                        selectedPaymentMethod: selectedPaymentMethod,
                        approvalStatus: {
                            status: "Declined",
                            remarks: "",
                        },
                        bulkInvoices: bulkInvoices
                    }
                );
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.message)
                clearAleart('');
            }
        } else {
            const formData = new FormData();
            formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
            formData.append('invoice', invoice);
            formData.append('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
            formData.append('approvalStatus', JSON.stringify({
                status: "Declined",
                remarks: remarks,
            }));
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice/update/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads 
                    },
                });
                if (res.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart("grid")
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.message);
            }
        }
    };


    const callPayment = async (res: any) => {
        console.log(res)
        if (res?.data.response.message === "Method form execution") {
            const page = `<html>${res.data.response.methodForm}</html>`;
            const newTab: any = window.open('', '_blank');
            newTab.document.write(page);
            setTimeout(async () => {
                newTab.close();
                cRes(res?.data.response.ipgTransactionId, res?.data.response.payment_history_id);
            }, 1000);
        } else if (res?.data.response.message === "cRes form execution") {
            let page = `<html>
                            <form id="acs-form" name="frm" method="POST" action="${res.data.response.storedAcsURL}">
                                <input type="hidden" name="creq" value="${res.data.response.cReq}">
                                <input type="hidden" name="termUrl" value="${res.data.response.termUrl}">
                                <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
                            </form><script> document.getElementById("acs-form").submit();</script>
                        </html>`;
            // var popupWindow: any = window.open('', '_blank');
            // popupWindow.document.write(page)
            document.open();
            document.write(page);
            document.close();
        } else if (res?.data.response.message === "Lyra charge created") {
            // @ts-ignore
            threeds.authenticate({
                uuid: res?.data.response.transactionUuid,
                callback: async (err: any) => {
                    if (err) {

                        // console.log("Authentication failed due to a technical error: " + err);
                        // setLoadingScreen(false)
                        // setShowType("Authentication failed due to a technical error:")
                        // // let causes = (res?.data.response.causes).join()
                        // setShowMessage(err.message)
                        // setMessagePopUp(true)
                        // setStatus('Failed')
                        // SetTransactionUuid(res?.data.response.transactionUuid)
                        try {
                            const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${res?.data.response.uuid}`);
                            setMessagePopUp(true)
                            if (uuidRes.data.status === "PAID") {
                                setMessagePopUp(true)
                                setShowType("Payment Success!")
                                setShowMessage(`Your transaction has been executed flawlessly.`)
                                setStatus('Paid')
                                SetTransactionUuid(res?.data.response.transactionUuid)
                            } else {
                                setMessagePopUp(true)
                                setStatus('Failed')
                                setShowType("Payment Declined")
                                setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
                                SetTransactionUuid(res?.data.response.transactionUuid)
                            }
                        } catch (error) {
                            console.error("Error while checking payment status:", error);

                        }
                    } else {
                        try {
                            const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${res?.data.response.uuid}`);
                            setMessagePopUp(true)
                            if (uuidRes.data.status === "PAID") {

                                setMessagePopUp(true)
                                setShowType("Payment Success!")
                                setShowMessage(`Your transaction has been executed flawlessly.`)
                                setStatus('Paid')
                                SetTransactionUuid(res?.data.response.transactionUuid)
                            } else {
                                setMessagePopUp(true)
                                setStatus('Failed')
                                setShowType("Payment Declined")
                                setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
                                SetTransactionUuid(res?.data.response.transactionUuid)
                            }
                        } catch (error) {
                            console.error("Error while checking payment status:", error);

                        }
                    }
                }
            });
        } else if (res?.data.response.message === "Netbanking Redirect" || res?.data.response.message === "Rupay Card Redirect") {
            let formData = "";
            Object.keys(res?.data.response.params).map((key: string) => {
                formData += ` <input type="hidden" name="${key}" value="${res?.data.response.params[key]}" />`;
            });
            // console.log(formData)
            let page = `<html>
                            <form id="acs-form" name="frm" method="POST" action="${res?.data.response.url}">
                                ${formData}
                                <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
                            </form><script> document.getElementById("acs-form").submit();</script>
                        </html>`;
            // var popupWindow: any = window.open('', '_blank');
            // popupWindow.document.write(page)
            console.log(page)
            document.open();
            document.write(page);
            document.close();

            // try {
            //     const netbankingRes = await axios.post(res?.data.response.url, formData, res?.data.response.axiosConfig);
            //     console.log("Response Status:", netbankingRes.status);
            //     console.log(netbankingRes.data);
            // } catch (error) {
            //     console.log("Axios Error:", error);
            // }
        } else if (res?.data.response.message === "Rupay H2HOTP") {
            setOpenRupayOtpModal(true);
            setRupaytranctionDetails(res?.data.response)
        } else if (res?.data.response.message === "UPI charge created") {
            setOpenUpiWaitingModal(true);
            setUpiTranctionDetails(res?.data.response);
        } else if (res?.data.response) {
            setLoadingScreen(false)
            setShowType(res?.data.response.message)
            let causes = res?.data.response.causes ? (res?.data.response.causes).join() : ""
            setShowMessage(causes)
            setMessagePopUp(true)
            // clearAleart('');
        }

        // else if (res?.data.response.message == "Net Banking Execution") {
        //     let reqJson = res.data.response.reqJson;
        //     reqJson.consumerData.responseHandler = handleInternetBankingResponse;
        //     // @ts-ignore
        //     $.pnCheckout(reqJson);
        //     if (reqJson.features.enableNewWindowFlow) {
        //         // @ts-ignore
        //         pnCheckoutShared.openNewWindow();
        //     }
        // }
    }


    const ApproverPayMent = async (val: any, elem: any) => {
        if (isBulkPayment) {
            setLoadingScreen(true)
            try {
                const res = await axiosPrivate.patch(`/vendor-payable/invoice-bulk/update/${editData.payableInvoice.invoicePayableId}`,
                    {
                        selectedPaymentMethod: { ...selectedPaymentMethod, cardCvv: cardCvv },
                        approvalStatus: {
                            status: "Approved",
                            remarks: "",
                        },
                        bulkInvoices: bulkInvoices
                    }
                );
                if (res.status === 200) {
                    setLoadingScreen(false);
                    // callPayment(res);
                }
            }
            catch (error: any) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.message)
                clearAleart('');
            }
        } else {
            const formData = new FormData();
            formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
            formData.append('paymentStatus', JSON.stringify({
                razorpay_payment_id: val,
                razorpay_order_id: elem,
            }));
            if (checkIfFile(invoice)) {
                formData.append(`invoice`, invoice);
            } else {
                formData.append(`invoice`, '');
            }
            formData.append('selectedPaymentMethod', JSON.stringify({ ...selectedPaymentMethod, cardCvv: cardCvv }));
            formData.append('approvalStatus', JSON.stringify({
                status: "Approved",
                remarks: "",
            }));
            setLoadingScreen(true)
            try {
                const res: any = await axiosPrivate.patch(`/vendor-payable/invoice/update/${editData.payableInvoice.invoicePayableId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
                    },
                });
                if (res.status === 200) {
                    setLoadingScreen(false);
                    vendorVewFun("grid")
                    // callPayment(res);
                }
            }
            catch (error: any) {
                console.log("ApproverPayMent", error)
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error?.response?.data.message)
                clearAleart('');
            }
        }
    };


    const cRes = async (ipgTransactionId: any, payment_history_id: any) => {
        const resMethod: any = await axiosPrivate.post(`/vendor-payable/invoice/method-form/${ipgTransactionId}`, { payment_history_id }, {
            headers: {
                'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
            },
        });
        if (resMethod.data.response.cReq) {
            let page = `<html><form id="acs-form" name="frm" method="POST" action="${resMethod.data.response.storedAcsURL}">
        <input type="hidden" name="creq" value="${resMethod.data.response.cReq}">
        <input type="hidden" name="termUrl" value="${resMethod.data.response.termUrl}">
        <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
        </form><script> document.getElementById("acs-form").submit();</script></html>`;
            // var popupWindow: any = window.open('', '_blank');
            // popupWindow.document.write(page)
            document.open();
            document.write(page);
            document.close();
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Transaction Failed")
            clearAleart('');
        }
    };

    const getFile = (file: any) => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        const validFormat = allowedFormats.includes(file.type);
        if (!validFormat) {
            setShowAlertBox(true);
            setShowType("warning");
            setShowMessage("Unsupported file format. Upload PDF or JPG or PNG file format.");
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
            return; // Stop execution if the format is not valid
        }
        if (file.size <= 2000000) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(invoice === "" ? `${file.name} has been uploaded successfully.` : `${file.name} has been updated successfully.`)
            SetInvoice(file);
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
            dispatch(resetFormModified(true));
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("File size exceeds the maximum 2MB. Compress the file and upload again.")
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    };

    // File delete for Local and backend database permanently 
    const fileDelete = async () => {
        let isfile = checkIfFile(invoice)
        if (isfile || invoice === "") {
            setShowDeleteModal(false);
            SetInvoice("");
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage("Invoice copy deleted successfully");
            clearAleart('');
        } else {
            try {
                const res = await axiosPrivate.delete(`/vendor-payable/invoice/delete-invoice-copy/${editData.payableInvoice.invoicePayableId}`);
                if (res.status === 200) {
                    SetInvoice("")
                    setShowDeleteModal(false);
                    setLoadingScreen(false);
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    clearAleart('');
                }
            }
            catch (error: any) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)
                clearAleart('');
            }
        }
        // if (isEditMode) {
        //     try {
        //         const res = await axiosPrivate.delete(`/vendor-payable/invoice/delete-invoice-copy/${editData.payableInvoice.invoicePayableId}`);
        //         if (res.status === 200) {
        //             SetInvoice("")
        //             setShowDeleteModal(false);
        //             setLoadingScreen(false);
        //             setShowAlertBox(true);
        //             setShowType("success");
        //             setShowMessage(res.data.message);
        //             clearAleart('');
        //         }
        //     }
        //     catch (error: any) {
        //         setShowAlertBox(true)
        //         setShowType("warning")
        //         setShowMessage(error.message)
        //         clearAleart('');
        //     }
        // } else if (!isEditMode) {
        //     SetInvoice("");
        //     setShowAlertBox(true);
        //     setShowType("success");
        //     setShowMessage("Deleted successfully");
        //     clearAleart('');
        //     setShowDeleteModal(false);
        // }
    }

    // this is used to show  the amount as thousand separator 
    const formatAmount = (amount: any) => {
        if (amount && amount !== ".") {
            const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
            // Handle empty input
            if (onlyNumber === "") {
                return "";
            }
            // Split input into integer and decimal parts
            const parts = onlyNumber.split(".");
            const integerPart = parts[0];
            const decimalPart = parts[1] || "";
            // Format the integer part with commas
            const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
            // Handle complete decimal input (e.g., "5000.50")
            if (decimalPart !== "") {
                return `${formattedInteger}.${decimalPart}`;
            }
            // Handle incomplete decimal input (e.g., "5000.")
            if (amount.toString().endsWith(".")) {
                return `${formattedInteger}.`;
            }
            // Return formatted integer
            return formattedInteger;
        }
        return "";
    };

    // this is the function to update the state that sent to grid for bulk upload  
    // const onUpdate = (updatedValue: any, row: any) => {
    //     setCurrenteditedRow(row)
    //     setCurrentedited(updatedValue)
    // };

    return (
        <>
            <MainPageCard>
                <NavigateHeader firstValue={"Payable"}
                    secondValue={"Vendor"}
                    navigatePage={isFormModified ? () => { setShowChangesModal(true) } : enterInvoiceBack}
                    style={{ cursor: "pointer" }}
                />
                {/* if (currentActor !== "Approver" && currentActor !== "singleUser") {
                    SetActiveTab(2);
                        } else {
                    SetActiveTab(1);
                        } */}
                <div style={{ marginTop: "-15px" }}>
                    <SliderComponent
                        marks={(currentActor !== "Approver" && currentActor !== "singleUser") ? ["Enter Invoice Details", "Review Details"] : ["Enter Invoice Details", "Select Payment Method", "Review Details"]}
                        max={(currentActor !== "Approver" && currentActor !== "singleUser") ? 1 : 2}
                        value={activeTab}
                    />
                </div>
                <div className='vendor-form-warraper'>
                    {
                        activeTab === 0 &&
                        <>
                            {
                                !isBulkPayment &&
                                <EnterInvoiceDetails
                                    invoiceDetails={invoiceDetails}
                                    partPaymetFieldsBlock={partPaymetFieldsBlock}
                                    invoice={invoice}
                                    editData={editData}
                                    gstinDD={gstinDD}
                                    setGstinDD={(val: any) => setGstinDD(val)}
                                    SetInvoiceDetails={(val: any) => SetInvoiceDetails(val)}
                                    enterInvoiceNext={enterInvoiceNext}
                                    enterInvoiceBack={isFormModified ? () => { setShowChangesModal(true) } : enterInvoiceBack}
                                    handleSaveForm={handleSaveForm} // save button function for save the form data 
                                    getFile={(val: File) => { getFile(val) }}
                                    setShowDeleteModal={(val: boolean) => { setShowDeleteModal(val) }}
                                    setInvoiceShowmodal={(val: File) => { setInvoiceShowmodal(val) }}
                                    invoiceDataErrors={invoiceDataErrors}
                                    currentActor={currentActor}
                                    isEditMode={isEditMode}
                                    setRateCardDetails={setRateCardDetails}
                                    setTransactionDetails={setTransactionDetails}
                                />
                            }
                            {
                                isBulkPayment &&
                                <div style={{ position: "relative" }}>
                                    <div style={{ right: "0", top: "-43px", position: "absolute" }}>
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={selectedRows.length > 0}
                                            opacity={(selectedRows.length > 0) ? "1" : "0.5"}
                                            cursor={(selectedRows.length > 0) ? "pointer" : "not-allowed"}
                                            handleClick={() => {
                                                if ((selectedRows.length > 0)) {
                                                    setShowDeleteModal(!showDeleteModal)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='aggrid-used-bulk-grid'>
                                        <BulkPaymentAgGrid
                                            ref={AgGridRefPaymentform}
                                            tableColumnData={BulkColumnHeader}
                                            tabelRowData={bulkInvoices}
                                            getEdit={(data: any) => {
                                                setEditBulk(true)
                                                setCurrentEditBulk(data)
                                            }}
                                            singleSelect={false}
                                            getCurruntData={getCurruntData}
                                        // singleSelect={true}
                                        />
                                        <div className='total-amount-warpper'>
                                            <div>
                                                {"Total Payment Amount"} : {formatAmount(bulkPaymenttotal)}
                                            </div>
                                            <div>
                                                {"Total Amount to Vendor"} : {formatAmount(bulktotal)}
                                            </div>
                                        </div>
                                        <div className='footer-vendor-pb'>
                                            <ButtonComponent
                                                title={"Back"}
                                                height={"50px"}
                                                width={"150px"}
                                                backgroundColor={"#0055D4"}
                                                color={"white"}
                                                margin={"0px 8px"}
                                                className={"button-component-hover common-btn"}
                                                handleClick={() => {
                                                    enterInvoiceBack()
                                                }}
                                            />
                                            <ButtonComponent
                                                title={"Next"}
                                                height={"50px"}
                                                width={"150px"}
                                                backgroundColor={"#0055D4"}
                                                color={"white"}
                                                margin={"0px 8px"}
                                                className={"button-component common-btn"}
                                                handleClick={() => {
                                                    BulkInvoiceNext()
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {
                        activeTab === 1 &&
                        <SelectPaymentMethod
                            cards={cards}
                            Banks={Banks}
                            getUpi={() => getUpi()}
                            Upis={Upis}
                            selectedPaymentMethod={selectedPaymentMethod}
                            SetSelectedPaymentMethod={(val: any) => {
                                SetSelectedPaymentMethod(val)
                            }}
                            paymentNext={paymentNext}
                            paymentBack={paymentBack}
                        />
                    }
                    {
                        activeTab === 2 &&
                        <ReviewDetails
                            rateCardDetails={rateCardDetails}
                            transactionDetails={transactionDetails}
                            cards={cards}
                            cardCvv={cardCvv}
                            showType={showType}
                            editData={editData}
                            bulktotal={bulktotal}
                            isEditMode={isEditMode}
                            MakerSubmit={MakerSubmit}
                            showMessage={showMessage}
                            currentActor={currentActor}
                            showAlertBox={showAlertBox}
                            isBulkPayment={isBulkPayment}
                            loadingScreen={loadingScreen}
                            paymentHistory={paymentHistory}
                            invoiceDetails={invoiceDetails}
                            approvalHistory={approvalHistory}
                            ApproverPayMent={(val: any, elem: any) => ApproverPayMent(val, elem)}
                            reviewDetailsBack={reviewDetailsBack}
                            SingleUserPayMent={(val: any, elem: any) => SingleUserPayMent(val, elem)}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setCardCvv={(val: any) => { setCardCvv(val) }}
                            Declain={(remarks: any) => { Declain(remarks) }}
                            checkerRecommenderApproval={checkerRecommenderApproval}
                            setInvoiceShowmodal={(val: boolean) => { setInvoiceShowmodal(val) }}
                        />
                    }
                </div>
            </MainPageCard>
            {
                showAlertBox &&
                <AlertBox type={showType} message={showMessage} />
            }
            {
                invoiceShowmodal &&
                <VendorPaymentInvoice
                    invoice={invoice}
                    currentActor={currentActor}
                    approvalHistory={approvalHistory}
                    paymentHistory={paymentHistory}
                    invoiceDetails={invoiceDetails}
                    closeDocument={(val: boolean) => { setInvoiceShowmodal(val); }}
                />
            }
            {
                showDeleteModal &&
                <DeleteModal
                    clearValue={(val: boolean) => { setShowDeleteModal(val); }}
                    getDelete={isBulkPayment ? DeleteBulkInvoice : fileDelete}
                    modelType={"grid-delete"}
                />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    handleClose={() => {
                        setShowChangesModal(false);
                    }}
                    leavePage={() => enterInvoiceBack()} />
            }
            {
                loadingScreen &&
                <Loading />
            }
            {
                editBulk &&
                <EditbulkPopup
                    closeModal={() => setEditBulk(false)}
                    leavePage={() => {
                        setEditBulk(false);
                    }}
                    bulkInvoices={bulkInvoices}
                    handleSave={() => { }}
                    currentEditBulk={currentEditBulk}
                    setCurrentEditBulk={(val: any) => setCurrentEditBulk(val)}
                    setBulkInvoices={(val: any) => setBulkInvoices(val)}
                    savedMessage={() => {
                        setShowAlertBox(true)
                        setShowType("success");
                        setShowMessage("Updated Successfully.")
                        const timer = setTimeout(() => {
                            setShowAlertBox(false)
                            setShowMessage("");
                            clearTimeout(timer);
                        }, 5000);
                    }}
                />
            }
            {
                messagePopUp &&
                <PaymentResultModal
                    closeModal={() => { vendorVewFun("grid") }}
                    handleOk={() => vendorVewFun("grid")}
                    message={showMessage}
                    type={showType}
                    status={status}
                    transactionsID={transactionUuid}
                // modelType={"grid-delete"}
                />
            }
            {
                openRupayOtpModal &&
                <RupayOtpModal
                    executeResponce={(res: any) => {
                        console.log(res)
                        setMessagePopUp(true);
                        if (res.status === "PAID" || res.status === "Paid") {
                            setStatus("Paid")
                            setShowType(res.type)
                            setShowMessage(res.message)
                            SetTransactionUuid(res?.transactionUuid)
                        } else {
                            setStatus("Failed")
                            setShowType(res.type)
                            setShowMessage(res.message)
                            SetTransactionUuid(res?.transactionUuid)
                        }

                    }}
                    clearValue={(val: boolean) => { setOpenRupayOtpModal(val) }}
                    tranctionFailed={() => vendorVewFun("grid")}
                    rupaytranctionDetails={rupaytranctionDetails}
                />
            }
            {
                openUpiWaitingModal &&
                <UpiwaitingModal
                    clearValue={(val: boolean) => {
                        setOpenUpiWaitingModal(val)
                    }}
                    executeResponce={(res: any) => {
                        setMessagePopUp(true);
                        if (res.status === "PAID" || res.status === "Paid") {
                            setShowType("Payment Success!")
                            setShowMessage(`Your transaction has been executed flawlessly.`)
                        } else {

                            setShowType("Payment Declined")
                            setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
                        }

                    }}
                    upiTranctionDetails={upiTranctionDetails}
                />
            }

        </>
    )
}

export default VendorForm;


{/* {
                    activeTab === 0 &&
                    <div className='add-invoice-tab'>
                        <IconButton
                            iconName={"Invoice"}
                            height={"40px"}
                            width={"100px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={() => {
                                SetInvoiceDetails({
                                    invoice: "",
                                    invoiceNumber: "",
                                    invoiceDate: "",
                                    expenseType: "",
                                    expenseTypeID: "",
                                    dueDate: "",
                                    vendorType: "",
                                    vendorName: "",
                                    vendorNameID: "",
                                    GSTIN: "",
                                    accountNo: "",
                                    IFSC: "",
                                    paymentFrequeny: "",
                                    scheduledDate: "",
                                    paymentTerms: "",
                                    baseAmount: "",
                                    discount: "",
                                    subTotal: "",
                                    GST: "",
                                    GSTpercentage: "",
                                    invoiceAmount: "",
                                    TDS: "",
                                    TDSpercentage: "",
                                    paymentAmount: "",
                                    amountToVendor: "",
                                    amountPaid: "",
                                    amountDue: "",
                                })
                            }}
                        />
                    </div>
} */}