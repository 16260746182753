import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../common-component/form-elements/button-component'
import { RxCross1 } from 'react-icons/rx';
import InvoiceDeclinemodal from '../../common-component/modals/invoice-decline-modal';
import Loading from '../../common-component/modals/loading-screen';
import { axiosPrivate, BASE_URL } from '../../../middleware/axois-api';
import Cvv from '../../common-component/modals/cvv-modal';
import { DateConverter, TitleCase } from '../../../helpers/capitalizetext';
import { formatAmount } from '../../../helpers/amountRenderer';
import { decryptToken } from '../../../services/utils/token-utils';
import useAuth from '../../../services/hooks/useauth';
import axios from 'axios';

interface Props {
    currentActor: string;
    reviewDetailsBack: () => void;
    setInvoiceShowmodal: (val: any) => void;
    MakerSubmit: () => void;
    Declain: (val: string) => void;
    checkerRecommenderApproval: () => void;
    ApproverPayMent: (val: any, elem: any) => void;
    SingleUserPayMent: (val: any, elem: any) => void;
    invoiceDetails: any;
    approvalHistory: any;
    paymentHistory: any;
    selectedPaymentMethod: any;
    isEditMode: boolean,
    loadingScreen: boolean,
    showAlertBox: boolean,
    isBulkPayment: boolean,
    showMessage: string,
    showType: string,
    editData: any,
    bulktotal: any,
    cards: any,
    cardCvv: any,
    rateCardDetails: any,
    transactionDetails: any,
    setCardCvv: (val: any) => void,
    // selectedCard: any
}
// razorpay payment 
declare global {
    interface Window {
        Razorpay: {
            new(options: Razorpay.Options): Razorpay.RazorpayInstance;
        };
    }
}

namespace Razorpay {
    export interface Options {
        key: string;
        amount: string;
        currency: string;
        // name: string;
        description: string;
        image: string;
        order_id: string;
        callback_url: string;
        prefill: any;
        notes: any;
        theme: any;
        // 'card[name]'?:string;
        // 'card[number]'?:string;
        // 'card[cvv]'?:string;
        // 'card[expiry_month]'?:string;
        // 'card[expiry_year]'?:string;
    }

    export interface RazorpayInstance {
        open(): void;
        on(event: string, callback: (response: any) => void): void;
    }
}
// razorpay payment 

function ReviewDetails({
    reviewDetailsBack,
    MakerSubmit,
    SingleUserPayMent,
    checkerRecommenderApproval,
    Declain,
    ApproverPayMent,
    currentActor,
    invoiceDetails,
    selectedPaymentMethod,
    isEditMode,
    approvalHistory,
    paymentHistory,
    editData,
    bulktotal,
    cards,
    setInvoiceShowmodal,
    loadingScreen,
    showAlertBox,
    isBulkPayment,
    showMessage,
    showType,
    cardCvv,
    setCardCvv,
    rateCardDetails,
    transactionDetails
}: Props) {

    const [declinePopUp, setDeclinePopUp] = useState<boolean>(false);
    const [declineRemarks, setDeclineRemarks] = useState<string>("");
    const [selectedCard, setselectedCard] = useState<any>({});
    const [isChecked, setIsChecked] = useState(false);
    const [transactionCharges, setTransactionCharges] = useState<any>();
    const [gstTransactionCharges, setGstTransactionCharges] = useState<any>();
    const [total, setTotal] = useState<any>();
    const [closeCvvPopUP, setCloseCvvPopUP] = useState<any>(false);

    useEffect(() => {
        if ((currentActor === "Approver" || currentActor === "singleUser") && selectedPaymentMethod.PaymentMethod === "Corporate Card") {
            let displayCard = cards.find((data: any) => selectedPaymentMethod.cardId === data.cardId);
            setselectedCard(displayCard);
        }
    }, [cards])

    useEffect(() => {
        if (currentActor === "Approver" || currentActor === "singleUser") {
            getTransactionCharges();
        }
    }, [])

    // console.log(invoiceDetails, "invoiceDetails.amountToVendor")

    const getTransactionCharges = async () => {
        try {

            const res = await axiosPrivate.get(`/customer-onboarding/card-details`);
            if (res.status === 200) {
                let amountDebitFor = selectedPaymentMethod.PaymentMethod === "Corporate Card" ? res.data.getData.commercialCard : selectedPaymentMethod.PaymentMethod === "Net Banking" ? res.data.getData.netbanking : 0
                if (isBulkPayment) {
                    let transactionAmount = (parseFloat(bulktotal) * parseFloat(amountDebitFor)) / 100
                    let transactionGst = (transactionAmount * parseFloat(res.data.getData.Gst)) / 100
                    let transactionCharges = (transactionAmount + transactionGst).toFixed(2)
                    let total = (parseFloat(transactionCharges) + parseFloat(bulktotal)).toFixed(2)
                    setTransactionCharges(transactionCharges);
                    setTotal(total)
                } else {
                    // old calculation
                    // pg means paymentGatway
                    // const vendorPaymentAmount = invoiceDetails.amountToVendor;
                    // const pgChargePercentage: any = parseFloat(transactionDetails.pgCharge).toFixed(2);
                    // const pgChargeAmount: any = (vendorPaymentAmount * pgChargePercentage) / 100;
                    // const pgGst: any = parseFloat(transactionDetails.pgGst).toFixed(2);
                    // const pgGstAmount = (pgChargeAmount * pgGst) / 100;
                    // const platformCharges: any = parseFloat(transactionDetails.platformCharge).toFixed(2);
                    // const platformChargesAmount = (vendorPaymentAmount * platformCharges) / 100;
                    // const platformGst: any = parseFloat(transactionDetails.platformGst).toFixed(2);
                    // const platformGstAmount = (platformChargesAmount * platformGst) / 100;
                    // const totalCharges = pgChargeAmount + pgGstAmount + platformChargesAmount + platformGstAmount;
                    // const amountToDebitted = totalCharges + vendorPaymentAmount;
                    // const amountPostCharges = vendorPaymentAmount - totalCharges;
                    // const reverseCalculation = (vendorPaymentAmount / amountPostCharges) * vendorPaymentAmount;
                    // // const reverseCalculation = vendorPaymentAmount;
                    // //showing charges
                    // const pgCharges = (reverseCalculation * (pgChargePercentage + platformCharges)) / 100;
                    // const pgChargesGst = (pgCharges * pgGst) / 100;
                    // const CRinEscrow = reverseCalculation - (pgCharges + pgChargesGst);
                    // setTransactionCharges((pgCharges)); //working 
                    // // setTransactionCharges(0); //test 
                    // setGstTransactionCharges(pgChargesGst)
                    // setTotal(reverseCalculation)

                    const vendorPaymentAmount = invoiceDetails.amountToVendor ? parseFloat(invoiceDetails.amountToVendor) : 0;
                    const pgChargePercentage = transactionDetails.pgCharge ? parseFloat(transactionDetails.pgCharge) : 0;
                    const pgChargeAmount = (vendorPaymentAmount * pgChargePercentage) / 100;

                    const pgGst = transactionDetails.pgGst ? parseFloat(transactionDetails.pgGst.replace('%', '')) : 0; // Strip "%" from pgGst if needed
                    const pgGstAmount = (pgChargeAmount * pgGst) / 100;

                    const platformCharges = transactionDetails.platformCharge ? parseFloat(transactionDetails.platformCharge) : 0;
                    const platformChargesAmount = (vendorPaymentAmount * platformCharges) / 100;

                    const platformGst = transactionDetails.platformGst ? parseFloat(transactionDetails.platformGst.replace('%', '')) : 0; // Strip "%" from platformGst if needed
                    const platformGstAmount = (platformChargesAmount * platformGst) / 100;

                    const totalCharges = pgChargeAmount + pgGstAmount + platformChargesAmount + platformGstAmount;

                    const amountToDebitted = totalCharges + vendorPaymentAmount;
                    const amountPostCharges = vendorPaymentAmount - totalCharges;

                    const reverseCalculation = (vendorPaymentAmount / amountPostCharges) * vendorPaymentAmount;

                    // Correct calculation for pgCharges
                    const pgCharges = (reverseCalculation * (pgChargePercentage + platformCharges)) / 100;
                    const pgChargesGst = (pgCharges * pgGst) / 100;

                    const CRinEscrow = reverseCalculation - (pgCharges + pgChargesGst);

                    setTransactionCharges(pgCharges);
                    setGstTransactionCharges(pgChargesGst);

                    setTotal(reverseCalculation);
                }
            }
        }
        catch (error: any) {
        }
    }

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    // razorpay payment start
    useEffect(() => {
        // Load Razorpay script dynamically
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        // Clean up script on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    async function createOrderForPayment() {
        try {
            let amount = (parseFloat(total) * 100).toFixed(0)
            let createOrder = {
                "amount": Number(amount),
                "currency": "INR",
                "receipt": "receipt#1",
                "notes": {
                    "key1": "value3",
                    "key2": "value2"
                },
                'email': invoiceDetails.vendorEmail
            }
            // console.log(createOrder, "createOrder")
            const res = await axiosPrivate.post('vendor/razorpay/create-order', createOrder)
            let orderId = res.data.order.id
            let accessToken = res.data.accessToken

            // let accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJhdWQiOiJPOTVTRVhpYjRvYUJDTiIsImp0aSI6Ik90cmxNbWtaS1Q0VUw0IiwiaWF0IjoxNzI1NjIyNTE5LCJuYmYiOjE3MjU2MjI1MTksInN1YiI6IiIsImV4cCI6MTczMzQ4NDkxOCwidXNlcl9pZCI6Ik5hRnpMTzFXSE9oaDZMIiwibWVyY2hhbnRfaWQiOiJOYUZ6TFNiYmRHMktFSyIsInNjb3BlcyI6WyJyZWFkX3dyaXRlIl19.JKPgMiJ8TMzR1z-5eUsnuy8UvmPYxzB9ycNH3ncNje9_ofcFuyYtSxXHsI0_NRPRZ4Ka2KOnNHC-VmioTJvWqw'

            return [orderId, accessToken]
        } catch (error) {
            return '';
        }

    }
    const handlePayment = async (type: any) => {


        let [order_id, accessTokens]: any = await createOrderForPayment()
        if (window.Razorpay) {
            let amount = (parseFloat(total) * 100).toFixed(2)
            // const razorpay: any = new window.Razorpay({
            //     // key: 'rzp_test_ve87Lf9GefpQ4W',
            //     // key: 'rzp_test_oauth_OhbEaITbqCdBF8', //public token
            //     key: '', //public token
            //     amount: amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            //     "currency": "INR",
            //     // "name": "Transcenz", //your business name
            //     "description": "Test Transaction",
            //     "image": "https://example.com/your_logo",
            //     "order_id": order_id.toString(), //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            //     "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
            //     "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
            //         "name": selectedCard?.nameOnCard, //your customer's name
            //         "email": invoiceDetails.vendorEmail,
            //         "contact": '9788244826', //Provide the customer's phone number for better conversion rates 
            //         'card[number]': selectedCard?.cardNumber,
            //         'card[expiry]': selectedCard?.validThru,
            //         'card[cvv]': ''
            //     },
            //     "notes": {
            //         "address": "Razorpay Corporate Office"
            //     },
            //     "theme": {
            //         "color": "#3399cc"
            //     }
            // });

            // // prefill: {   
            // //     name: 'QARazorpay',
            // //          email: 'qa.testing@razorpay.com',     contact: '+918888888888',     'card[number]': '4111111111111111',     'card[expiry]': '1123',     'card[cvv]': '123'   } }
            // razorpay.open(); // Open Razorpay checkout

            // razorpay.on('payment.success', (response: any) => {
            //     alert(`Payment Successful! Payment ID: ${response.razorpay_payment_id}`);
            //     alert(`Order ID: ${response.razorpay_order_id}`);
            //     alert(`Signature: ${response.razorpay_signature}`);
            // });

            // razorpay.on('payment.error', (response: any) => {
            //     alert(`Payment Error: ${response.error.description}`);
            // });


            // ---- new version 
            const options: any = {
                key: '',
                amount: amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                // "name": "Acme Corp", //your business name
                "description": "Test Transaction",
                "image": "https://example.com/your_logo",
                "order_id": order_id && order_id.toString(),//This is a sample Order ID. Pass the `id` obtained in the response of Step 1 
                // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
                "callback_url": `${BASE_URL}/razorpay/payment-success/?id=${invoiceDetails.invoiceNumber}`,
                // "callback_url": `${BASE_URL}/razorpay/payment-success/?id=${invoiceDetails.invoiceNumber}`,
                "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                    "name": selectedCard?.nameOnCard, //your customer's name
                    "email": invoiceDetails.vendorEmail,
                    "contact": selectedCard.companyAdminMobileNumber, //Provide the customer's phone number for better conversion rates 
                    // "contact": '9443486802', //Provide the customer's phone number for better conversion rates 
                    'card[number]': decryptToken(selectedCard?.encryptCardNumber),
                    'card[expiry]': selectedCard?.validThru,
                    'card[cvv]': ''
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                },
                "handler": async function (response: any) {
                    // Razorpay payment success handler
                    const { razorpay_payment_id, razorpay_order_id } = response;
                    if (type == "Approve") {
                        ApproverPayMent(razorpay_payment_id, razorpay_order_id)
                    } else {
                        SingleUserPayMent(razorpay_payment_id, razorpay_order_id)
                    }

                    console.log(razorpay_payment_id, razorpay_order_id, type, "ID")
                    await axiosPrivate.post(`${BASE_URL}/razorpay/payment-success?id=${invoiceDetails.invoiceNumber}`, {
                        razorpay_payment_id, razorpay_order_id, type
                    })

                    // Send data to the backend `PaymentSucessPage` controller
                    // try {
                    //     const res = await fetch(`${BASE_URL}/razorpay/payment-success/?id=${invoiceDetails.invoiceNumber}`, {
                    //         method: 'POST',
                    //         headers: {
                    //             'Content-Type': 'application/json',
                    //             'Authorization': `Bearer ${accessTokens}`
                    //         },
                    //         body: JSON.stringify({
                    //             razorpay_payment_id,
                    //             razorpay_order_id,
                    //             type
                    //         })
                    //     });

                    //     // console.log(res, "res")
                    //     const result = await res.json();
                    //     // console.log(result, "result")
                    //     // console.log(type, "type")
                    //     if (res.ok && result.message === 'Success') {
                    //         // Call the respective function basedmaker@gmai.com on 'type'
                    //         if (type === "Approve") {
                    //             ApproverPayMent();
                    //         } else {
                    //             SingleUserPayMent();
                    //         }
                    //     } else {
                    //         console.log('Payment Success but error in backend processing');
                    //     }
                    // } catch (error) {
                    //     console.error("Error during payment success handling", error);
                    // }
                },
                // "modal": {
                //     "ondismiss": function () {
                //         alert("Payment Cancelled");
                //     }
                // }
            };

            // Assuming you have the access token, set it in the Razorpay headers
            const accessToken: any = accessTokens; // Replace with your actual access token

            // Initialize the Razorpay payment with access token
            const razorpayInstance: any = new window.Razorpay(options);
            const response = razorpayInstance.open({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            // if (razorpay_payment_id) {
            //     if (type == "Approve") {
            //         ApproverPayMent()
            //     } else {
            //         SingleUserPayMent()
            //     }
            // }
        } else {
            alert('Razorpay SDK failed to load. Please try again.');
        }
    };
    // razorpay payment end

    return (
        <div>
            <div className={"review-Details-wrapper"}>
                <div className={"review-details-main"}>
                    <div className={"review-details"} style={isBulkPayment ? { justifyContent: "center" } : {}}>
                        {
                            !isBulkPayment &&
                            <>
                                <div className={"vendor-details-main"}>
                                    <p className={"vendor-details-heading"}>{"Vendor Details"}</p>
                                    <div className={"vendor-details-sub"}>
                                        <p>{"Vendor Name"}</p>
                                        {
                                            invoiceDetails.vendorName ?
                                                <p>: {invoiceDetails.vendorName}</p> :
                                                <p>: {invoiceDetails.vendorRazorpayName}</p>
                                        }
                                    </div>
                                    {/* <div className={"vendor-details-sub"}>
                                <p>{"Vendor ID"}</p>
                                <p>: {invoiceDetails.vendorNameID}</p>
                            </div> */}
                                    <div className={"vendor-details-sub"}>
                                        <p>{"Account Number"}</p>
                                        <p>: {invoiceDetails.accountNo}</p>
                                    </div>
                                    <div className={"vendor-details-sub"}>
                                        <p>{"IFSC"}</p>
                                        <p>: {invoiceDetails.IFSC}</p>
                                    </div>
                                    {/* <div className={"vendor-details-sub"}>
                                <p>{"UPI"}</p>
                                <p>: {invoiceDetails.upi}</p>
                            </div> */}
                                </div>
                                <div className={"invoice-details-main"}>
                                    <p className={"invoice-details-heading"}>{"Invoice Details"}</p>

                                    <div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"GSTIN"}</p>
                                            <p>: {invoiceDetails.GSTIN}</p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Invoice Number"}</p>
                                            <p>: {invoiceDetails.invoiceNumber}</p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Invoice Date"}</p>
                                            <p>: {invoiceDetails.invoiceDate ? DateConverter(invoiceDetails.invoiceDate) : ""}</p>
                                        </div>

                                        <div className={"invoice-details-sub"}>
                                            <p>{"Due Date"}</p>
                                            <p>: {invoiceDetails.dueDate ? DateConverter(invoiceDetails.dueDate) : ""}</p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Base Amount"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.baseAmount)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Discount"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.discount)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Sub Total"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.subTotal)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"GST"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.GST)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Invoice Amount"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.invoiceAmount)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"TDS"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.TDS)}</div>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Amount to Vendor"}</p>
                                            <p>
                                                <div className='display-as-amount'>
                                                    <div>: </div>
                                                    <div>{formatAmount(invoiceDetails.amountToVendor)}</div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={"buttons review-details-buttons"}>
                                        <ButtonComponent
                                            title={"View Invoice"}
                                            height={"50px"}
                                            width={"150px"}
                                            backgroundColor={"#0055D4"}
                                            color={"white"}
                                            margin={"0px 8px"}
                                            className={"button-component-hover common-btn"}
                                            handleClick={() => { setInvoiceShowmodal(true) }}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {
                            (currentActor === "Approver" || currentActor === "singleUser") &&
                            <div className={"payment-details-main"}>
                                <p className={"payment-details-heading"}>{"Payment Details"}</p>
                                <div className={"payment-details-sub"}>
                                    <p>{"Payment Method"}</p>
                                    <p>: {selectedPaymentMethod.PaymentMethod}</p>
                                </div>
                                {
                                    selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                                    <div className={"payment-details-sub"} key={selectedCard?.cardNumber}>
                                        <p>{"Card Number"}</p>
                                        <p>: {selectedCard?.cardNumber}</p>
                                    </div>
                                }
                                {
                                    selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                                    <div className={"payment-details-sub"} key={selectedCard?.validThru}>
                                        <p>{"Card Valid upto"}</p>
                                        <p>: {selectedCard?.validThru}</p>
                                    </div>
                                }
                                {
                                    selectedPaymentMethod.PaymentMethod === "Net Banking" &&
                                    <div className={"payment-details-sub"} key={selectedPaymentMethod?.bankName}>
                                        <p>{"Bank"}</p>
                                        <p>: {selectedPaymentMethod?.bankName}</p>
                                    </div>
                                }
                                {
                                    selectedPaymentMethod.PaymentMethod === "UPI" &&
                                    <div className={"payment-details-sub"} key={selectedPaymentMethod?.UpiCode}>
                                        <p>{"UPI ID"}</p>
                                        <p>: {selectedPaymentMethod?.UpiCode}</p>
                                    </div>
                                }
                                <div className={"payment-details-sub"}>
                                    <p>{"Invoice Amount"}</p>
                                    <p>
                                        <div className='display-as-amount'>
                                            <div>: </div>
                                            <div>{!isBulkPayment ? formatAmount(invoiceDetails.amountToVendor) : formatAmount(bulktotal)}</div>
                                        </div>
                                    </p>
                                </div>
                                <div className={"payment-details-sub"}>
                                    <p>{"Transaction Charges"}</p>
                                    <p>
                                        <div className='display-as-amount'>
                                            <div>: </div>
                                            {/* <div>{formatAmount(transactionCharges)}</div> */}
                                            <div>{parseFloat(transactionCharges).toFixed(2)}</div>
                                        </div>
                                    </p>
                                </div>
                                <div className={"payment-details-sub"}>
                                    {/* <p>{`GST 18% for ${formatAmount(transactionCharges)}`}</p> */}
                                    <p>{`GST 18% for ${parseFloat(transactionCharges).toFixed(2)}`}</p>
                                    <p>
                                        <div className='display-as-amount'>
                                            <div>: </div>
                                            <div>{formatAmount(gstTransactionCharges)}</div>
                                            {/* <div>{0}</div> */}
                                        </div>
                                    </p>
                                </div>
                                <div className={"payment-details-sub"}>
                                    <p>{"Total"}</p>
                                    <p>
                                        <div className='display-as-amount'>
                                            <div>: </div>
                                            <div>{formatAmount(total)}</div>
                                        </div> </p>
                                </div>
                                {/* <div className={"payment-details-sub"}>
                                <p>{"UPI"}</p>
                                <p>: {invoiceDetails.upi}</p>
                            </div> */}
                            </div>
                        }

                    </div>
                </div>
                {
                    isBulkPayment &&
                    <div className={"review-details-table-main"} style={{ border: "1px solid black", marginTop: "40px", position: "relative" }}>
                        <p className={"review-details-table-title"}>{"Payment History"}</p>
                        <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "210px", overflow: "auto" }}>
                            <table>
                                <thead className='vendor-payment-approval-status-head'>
                                    <th>Date</th>
                                    <th>Payment Method</th>
                                    <th style={{ textAlign: "center", paddingRight: "20px" }}>Amount</th>
                                    <th>Transaction Ref No</th>
                                    <th>Payment status</th>
                                </thead>
                                <tbody >
                                    {paymentHistory.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        :
                                        paymentHistory.map((data: any, index: number) => (
                                            <tr className='main-body-header' key={index}>
                                                <td>{DateConverter(data.date)}</td>
                                                <td>{TitleCase(data.payment_type)}</td>
                                                <td style={{ textAlign: "right", paddingRight: "20px" }}>{formatAmount(data.payment_amount)}</td>
                                                <td>{data.transaction_ref_no}</td>
                                                <td >{TitleCase(data.transactionResult)}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    currentActor !== "singleUser" &&
                    <div className={"review-details-table-main"} style={{ border: "1px solid black", marginTop: "40px", position: "relative" }}>
                        <p className={"review-details-table-title"}>{"Approval Status"}</p>
                        <div className='view-table-component' style={{ marginTop: "30px", marginBottom: "25px", maxHeight: "146px", overflow: "auto" }}>
                            <table>
                                <thead className='vendor-payment-approval-status-head'>
                                    <th style={{ paddingLeft: "10px" }}>Date</th>
                                    <th>Actor</th>
                                    <th>Status</th>
                                    <th style={{ width: "32%" }}>Remarks</th>
                                </thead>
                                <tbody >
                                    {approvalHistory.length === 0 ?
                                        <tr className='main-body-header' style={{ height: "35px" }}>
                                            <td></td>
                                            <td colSpan={2} style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                        </tr>
                                        :
                                        approvalHistory.map((data: any, index: number) => (
                                            <tr className='main-body-header vendor-payment-table-body' key={index} style={{ height: "46px" }}>
                                                <td style={{ paddingLeft: "10px" }}>{data?.date}</td>
                                                <td>{data?.actor}</td>
                                                <td>{data?.status}</td>
                                                <td>{data?.remarks}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

            </div>

            <div className='review-verfy-checkbox'>
                {
                    <>
                        <input type="checkbox" name="" disabled={(editData?.payableInvoice?.canEditable === false) ? true : false} id="review-verfy" checked={isChecked} onChange={toggleCheckbox} />
                        <label htmlFor="review-verfy">
                            I have reviewed all the details presented above.
                        </label>
                    </>
                }
            </div>
            <div>
                <div className='footer-vendor-pb'>
                    <ButtonComponent
                        title={"Back"}
                        height={"50px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 8px"}
                        className={"button-component-hover common-btn"}
                        handleClick={() => {
                            reviewDetailsBack()
                            setIsChecked(false);
                        }}
                    />
                    {
                        currentActor === "Maker" &&
                        <ButtonComponent
                            title={"Submit for Approval"}
                            height={"50px"}
                            width={"250px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={!isChecked ? true : false}
                            className={!isChecked ? "button-component-hover disabled" : "button-component common-btn"}
                            handleClick={() => {
                                if (editData?.payableInvoice?.canEditable === false) return;
                                MakerSubmit();
                                setIsChecked(false);
                            }}
                        />
                    }
                    {
                        currentActor !== "Maker" && currentActor !== "singleUser" && isEditMode &&
                        <div className='new-clase'>
                            <ButtonComponent
                                title={
                                    <>
                                        <RxCross1 color={isChecked ? '#0055D4' : "#ffff"}
                                            style={{ verticalAlign: "middle", marginRight: "5px" }}
                                            className='add-icon' />   Decline
                                    </>}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"white"}
                                border={!isChecked ? "" : "1px solid #0055D4"}
                                color={isChecked ? '#0055D4' : "#ffff"}
                                margin={"0px 8px"}
                                className={!isChecked ? "button-component-hover disabled" : "button-component common-btn"}
                                handleClick={() => {
                                    if (editData?.payableInvoice?.canEditable === false) return;
                                    setDeclinePopUp(true);
                                }}
                            />
                        </div>
                    }
                    {
                        (currentActor === "Checker" || currentActor === "Recommender") && (
                            <ButtonComponent
                                title={"Submit for Approval"}
                                height={"50px"}
                                width={"250px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={isChecked ? false : true}
                                className={isChecked ? "button-component common-btn" : "button-component-hover disabled"}
                                handleClick={() => {
                                    if (editData?.payableInvoice?.canEditable === false) return;
                                    checkerRecommenderApproval();
                                    setIsChecked(false);
                                }}
                            />
                        )
                    }
                    {
                        currentActor === "Approver" &&
                        <>
                            <ButtonComponent
                                title={"Make Payment"}
                                height={"50px"}
                                width={"250px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={isChecked ? false : true}
                                className={isChecked ? "button-component common-btn" : "button-component-hover disabled"}
                                handleClick={() => {
                                    if (editData?.payableInvoice?.canEditable === false) return;
                                    // ApproverPayMent();

                                    //old code
                                    // selectedPaymentMethod.PaymentMethod === "Corporate Card" ? setCloseCvvPopUP(true) : ApproverPayMent();
                                    // setIsChecked(false);
                                    //new code
                                    handlePayment('Approve')
                                    // alert("Approve");
                                }}
                            />
                        </>
                    }
                    {
                        currentActor === "singleUser" &&
                        <>
                            <ButtonComponent
                                title={"Make Payment"}
                                height={"50px"}
                                width={"250px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={isChecked ? false : true}
                                className={isChecked ? "button-component common-btn" : "button-component-hover disabled"}
                                handleClick={() => {
                                    if (editData?.payableInvoice?.canEditable === false) return;
                                    // SingleUserPayMent();

                                    //old code
                                    // selectedPaymentMethod.PaymentMethod === "Corporate Card" ? setCloseCvvPopUP(true) : SingleUserPayMent();
                                    // setIsChecked(false);

                                    //new code 
                                    handlePayment('Payment')
                                    // alert('Payment')

                                }}
                            />
                        </>
                    }
                </div>
            </div>
            {
                declinePopUp &&
                <InvoiceDeclinemodal
                    clearDeclinemodal={(val: boolean) => {
                        setDeclinePopUp(val)
                    }}
                    setDeclineRemarks={(val: string) => setDeclineRemarks(val)}
                    declineRemarks={declineRemarks}
                    Submit={() => {
                        Declain(declineRemarks);
                        setDeclinePopUp(false)
                        setIsChecked(false);
                    }}
                />
            }
            {
                loadingScreen && <Loading />
            }
            {
                closeCvvPopUP &&
                <Cvv
                    cardDetails={selectedCard}
                    leavePage={(val: any) => setCloseCvvPopUP(val)}
                    cardCvv={cardCvv}
                    setCardCvv={(val: any) => { setCardCvv(val); }}
                    ApproverPayMent={() => {
                        setCloseCvvPopUP(false);
                        currentActor === "Approver" && ApproverPayMent("", "");
                        currentActor === "singleUser" && SingleUserPayMent("", "");
                    }}
                />
            }
        </div >
    )
}

export default ReviewDetails