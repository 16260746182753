import React, { useEffect, useState } from 'react'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import { axiosPrivate } from '../../../middleware/axois-api'
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
interface Props {
    mainApiData: any;
    adminDetailsMain: any;
    statutoryDetailsMain: any;
}
export default function CompanyMIDCreation({ mainApiData, adminDetailsMain, statutoryDetailsMain }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [razorPayMerchantId, setRazorPayMerchantId] = useState('') // error message ShowAlertBox
    const [razorPaySubmitedStatus, setRazorPaySubmitedStatus] = useState({
        razorpayAccountCreationStatus: mainApiData?.companyData?.razorpayAccountCreationStatus || false,
        razorpayBusinessDocStatus: mainApiData?.companyData?.razorpayBusinessDocStatus || false,
        razorpayStakeholderDocStatus: mainApiData?.companyData?.razorpayStakeholderDocStatus || false,
        razorpayStakeholderStatus: mainApiData?.companyData?.razorpayStakeholderStatus || false,
        razorpayBankDetailsStatus: mainApiData?.companyData?.razorpayBankDetailsStatus || false,
    })
    console.log(razorPaySubmitedStatus)
    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }
    let customStyle = {
        display: "flex",
        width: "500px",
        backgroundColor: "#F3F8FF",
        justifyContent: "space-between",
        padding: "10px",
        alignItems: "center",
        borderRadius: "8px",
        marginBottom: "10px",
    }

    async function createRazorPayAccount() {
        try {
            const payload = {
                basicDetails: {
                    emailId: adminDetailsMain?.filter((e: any) => e.userIsActive == "Yes")[0].email,
                    contactNumber: adminDetailsMain?.filter((e: any) => e.userIsActive == "Yes")[0].mobileNumber
                },
                vendorOverView: {
                    businessType: { label: mainApiData?.companyData?.CompanyClass, value: mainApiData?.companyData?.CompanyClass },
                    businessCategory: { label: mainApiData?.companyData?.CompanyCategory, value: mainApiData?.companyData?.CompanyCategory },
                    businessSubCategory: { label: mainApiData?.companyData?.CompanySubCategory, value: mainApiData?.companyData?.CompanySubCategory },
                    description: mainApiData?.companyData?.Description,
                    companyPAN: mainApiData?.companyData?.pan,
                    legalBusinessName: mainApiData?.companyData?.CompanyName,
                },
                address: {
                    contact_street_1: mainApiData?.companyData?.street_1,
                    contact_street_2: mainApiData?.companyData?.street_2,
                    contact_city: { label: mainApiData?.companyData?.CompanyRegCity, value: mainApiData?.companyData?.CompanyRegCity },
                    contact_state: { label: mainApiData?.companyData?.CompanyRegState, value: mainApiData?.companyData?.CompanyRegState },
                    contact_postalCode: mainApiData?.companyData?.CompanyRegPinCode,
                    contact_country: "India",
                    sameAsContactAddress: "No",
                    registered_street_1: mainApiData?.companyData?.street_1,
                    registered_street_2: mainApiData?.companyData?.street_2,
                    registered_city: { label: mainApiData?.companyData?.CompanyRegCity, value: mainApiData?.companyData?.CompanyRegCity },
                    registered_state: { label: mainApiData?.companyData?.CompanyRegState, value: mainApiData?.companyData?.CompanyRegState },
                    registered_postalCode: mainApiData?.companyData?.CompanyRegPinCode,
                    registered_country: "India",
                },
                pointOfContact: {
                    email: mainApiData?.companyData?.CompanyEmail,
                    contactPersonName: mainApiData?.companyData?.ContactPersonName
                },
                primaryKeyOfCurrentDatas: "",
                statutoryGridDetails: [
                    {
                        companyGSTIN: statutoryDetailsMain[0]?.document_id
                    }
                ],
                userType: "Company",
                companyId: mainApiData?.companyData?.id
            }
            setIsLoading(true)
            const res = await axiosPrivate.post('razorpay/vendor-details/mid-creation', {
                vendorDetails: payload
            })
            setRazorPaySubmitedStatus({
                ...razorPaySubmitedStatus,
                razorpayAccountCreationStatus: true,
            })
            setRazorPayMerchantId(res.data.razorPayMerchantId)
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage(`Basic Details submitted successfully.`)
            clearAlert('');
        } catch (error: any) {
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
            console.log("Error in createRazorPayAccount function", error)
        }
    }
    async function createRazorPayKycDetails() {
        try {
            setIsLoading(true)
            const res = await axiosPrivate.post(`vendor/razorpay/product-admin/upload-kyc-docs/${mainApiData?.companyData?.id}`, {
                razorPayMerchantId: razorPayMerchantId
            })
            setRazorPaySubmitedStatus({
                ...razorPaySubmitedStatus,
                razorpayBusinessDocStatus: true,
            })
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage(`KYC Documents submitted successfully.`)
            clearAlert('');
        } catch (error: any) {
            console.log("Error in createRazorPayKycDetails function", error)
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    async function createRazorPayStakeHolderDataAndDoc() {
        try {
            setIsLoading(true)
            const res = await axiosPrivate.post(`vendor/razorpay/product-admin/stake-holder-details-and-doc/${mainApiData?.companyData?.id}`, {
                merchantId: razorPayMerchantId
            })
            setRazorPaySubmitedStatus({
                ...razorPaySubmitedStatus,
                razorpayStakeholderDocStatus: true,
                razorpayStakeholderStatus: true,
            })
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage(`Directors info & Documents submitted successfully.`)
            clearAlert('');

        } catch (error: any) {
            console.log("Error in createRazorPayStakeHolderDataAndDoc function", error)
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    async function createRazorPayBankDetails() {
        try {
            setIsLoading(true)
            const res = await axiosPrivate.post(`vendor/razorpay/upload/product-admin/bank-details/${mainApiData?.companyData?.id}`, {
                basicDetails: {
                    emailId: adminDetailsMain?.filter((e: any) => e.userIsActive == "Yes")[0].email,
                    contactNumber: adminDetailsMain?.filter((e: any) => e.userIsActive == "Yes")[0].mobileNumber,
                    merchantId: razorPayMerchantId
                },
            })
            setRazorPaySubmitedStatus({
                ...razorPaySubmitedStatus,
                razorpayBankDetailsStatus: true,
            })
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage(`Bank Details submitted successfully.`)
            clearAlert('');
        } catch (error: any) {
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
            console.log("Error in createRazorPayBankDetails function", error)
        }
    }
    useEffect(() => {
        setRazorPayMerchantId(mainApiData?.companyData?.razorPayMerchantId || "")
    }, [mainApiData])
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }} className='form-wrapper'>
                <div className='company-heading'>
                    MID Creation
                </div>
                <div>
                    <InputComponent
                        height={"40px"}
                        width={"300px"}
                        margin={"0px 45px 30px 0px"}
                        padding={"0px 40px 0px 10px"}
                        border={"1px solid #A9C3DC"}
                        disabled={true}
                        required={true}
                        borderRadius={"0px"}
                        backgroundColor={"white"}
                        placeHolder={'MID '}
                        color={"black"}
                        type={"text"}
                        inputTitle={"Company MID"}
                        // maxLength={11}
                        inputValue={razorPayMerchantId}
                        getUser={(value: any) => {
                        }}
                    />
                </div>
                <div style={customStyle}>
                    <div style={{ fontWeight: "bold" }}>Basic Details</div>
                    <div>
                        <ButtonComponent
                            title={razorPayMerchantId == "" ? "Submit" : "Submitted"}
                            height={"45px"}
                            width={"140px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={razorPayMerchantId == "" ? "button-component common-btn" : "button-component disabled"}
                            handleClick={() => {
                                if (razorPayMerchantId == "") {
                                    createRazorPayAccount()
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={customStyle}>
                    <div style={{ fontWeight: "bold" }}>KYC Documents</div>
                    <div>
                        <ButtonComponent
                            title={razorPaySubmitedStatus?.razorpayBusinessDocStatus ? "Submitted" : "Submit"}
                            height={"45px"}
                            width={"140px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={razorPaySubmitedStatus?.razorpayBusinessDocStatus ? "button-component disabled" : razorPaySubmitedStatus?.razorpayAccountCreationStatus ? "button-component common-btn" : "button-component disabled"}
                            handleClick={() => { createRazorPayKycDetails() }}
                        />
                    </div>
                </div>
                <div style={customStyle}>
                    <div style={{ fontWeight: "bold" }}>Directors Info & Documents</div>
                    <div>
                        <ButtonComponent
                            title={razorPaySubmitedStatus?.razorpayStakeholderStatus ? "Submitted" : "Submit"}
                            height={"45px"}
                            width={"140px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={razorPaySubmitedStatus?.razorpayStakeholderStatus ? "button-component disabled" : razorPaySubmitedStatus?.razorpayAccountCreationStatus ? "button-component common-btn" : "button-component disabled"}
                            handleClick={() => { createRazorPayStakeHolderDataAndDoc() }}
                        />
                    </div>
                </div>
                <div style={customStyle}>
                    <div style={{ fontWeight: "bold" }}>Bank Details</div>
                    <div>
                        <ButtonComponent
                            title={razorPaySubmitedStatus?.razorpayBankDetailsStatus ? "Submitted" : "Submit"}
                            height={"45px"}
                            width={"140px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={razorPaySubmitedStatus?.razorpayBankDetailsStatus ? "button-component disabled" : razorPaySubmitedStatus?.razorpayAccountCreationStatus ? "button-component common-btn" : "button-component disabled"}
                            handleClick={() => { createRazorPayBankDetails() }}
                        />
                    </div>
                </div>
            </div>
            {
                isLoading &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
        </>
    )
}
