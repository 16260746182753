import React, { useCallback, useEffect, useState, useRef } from 'react'
import OrganizationVsCompanies from '../organization-vs-companies';
import FilterHeader from '../filter-header';
// import '../../../styles/dashboard/product-admin-dashboard.scss';
import '../../../styles/dashboard/company-admin-dashboard/company-admin-dashboard.scss';
import CardPanel from '../../common-component/cards/card-panel';
import PaymentReminder from '../../common-component/payment-reminder';
import VerticalBarChart from '../../common-component/charts/vertical-barchart';
import { billGenerationDateData, billGenerationDateOptions, paymentData, paymentOptions, utilizationCardData, utilizationCardOptions, utilizationSegmentsData, utilizationSegmentsEmptyData, utilizationSegmentsOptions, utilizationVendorData, utilizationVendorOptions } from '../../../config/dashboard/company-admin/company-admin-datas';
import dummyCard from '../../../assets/images/dummy-card.png'


import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import PieChart from '../../common-component/charts/pie-chart';
import { format } from "date-fns/esm";
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg'
import { ReactComponent as GraphIcon } from '../../../assets/icons/graph-icon.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import ApexChart from '../../common-component/charts/bar-chart-v1';
import ChartAgGrid, { AgGridRef } from '../../common-component/charts/chart-ag-grid';
import axios from 'axios';
import { PiechartVersion } from '../../common-component/charts/pie-chart-v1';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AgGrid from '../../common-component/grids-and-tables/ag-grid';
import CustomEllipsisRenderer from '../../../config/dashboard/customers-vs-transaction-value-config';
import { DateConverter } from '../../../helpers/capitalizetext';


interface Props {
  currentActor: string
}
function VendorAdminDashboard({ currentActor }: Props) {

  const parentGridDownloadRef = useRef<AgGridRef>(null);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [payementReminderAmount, setPayementReminderAmount] = useState<any>();
  const [approvalStatusDatas, setApprovalStatusDatas] = useState<any>([]);
  const [approvalStatusDisableDatas, setApprovalStatusDisableDatas] = useState<any>([]);
  const [paymentCompletedCount, setPaymentCompletedCount] = useState<any>(0);
  const utilizationCardRef = useRef<AgGridRef>(null);
  const billgenerationDateRef = useRef<AgGridRef>(null);
  const utilizationPaymentRef = useRef<AgGridRef>(null);
  const utilizationSegmentsRef = useRef<AgGridRef>(null);
  const utilizationVendorRef = useRef<AgGridRef>(null);

  //RowDatas
  const [billGenerationRowData, setBillGenerationRowData] = useState([]);
  const [rowDataInvoiceGrid, setRowDataInvoiceGrid] = useState<any>([]);

  const formatAmount = (amount: any) => {
    if (amount && amount !== ".") {
      const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
      // Handle empty input
      if (onlyNumber === "") {
        return "";
      }
      // Split input into integer and decimal parts
      const parts = onlyNumber.split(".");
      const integerPart = parts[0];
      const decimalPart = parts[1] || "";
      // Format the integer part with commas
      const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
      // Handle complete decimal input (e.g., "5000.50")
      if (decimalPart !== "") {
        return `${formattedInteger}.${decimalPart}`;
      }
      // Handle incomplete decimal input (e.g., "5000.")
      if (amount.toString().endsWith(".")) {
        return `${formattedInteger}.`;
      }
      // Return formatted integer
      return formattedInteger;
    }
    return "";
  };
  async function getPaymentReminderData() {
    try {
      const res = await axiosPrivate.get('company-admin/card-payment-reminder')
      setPayementReminderAmount(res.data.reminderAmount)
    } catch (error) {
      console.error("Error occurred while retrieving payment reminder data.");
    }
  }
  async function paymentCompletedCountFunction() {
    try {
      const res = await axiosPrivate.get('company-admin/payment-complete-check')
      setPaymentCompletedCount(res.data.vendorPaymentDataCount)
    } catch (error) {
      console.error("Error occurred while retrieving PaymentCompletedCountFunction.");
    }
  }
  async function getApprovalStatusFunction() {
    try {
      const res = await axiosPrivate.get('company-admin/approval-status')
      const userTitle = await loggedUserCall(res.data)
      // setApprovalStatusDatas(res.data)
    } catch (error) {
      console.error("Error occurred while retrieving PaymentCompletedCountFunction.");
    }
  }
  useEffect(() => {
    getPaymentReminderData()
    paymentCompletedCountFunction()
    getApprovalStatusFunction()
    getInvoices()
  }, [])

  function formatDate(dateString: any) {
    const originalDate = new Date(dateString)
    const formattedDateTime = format(originalDate, "dd-MMM-yyyy HH:mm:ss");
    return formattedDateTime;
  }
  // chartAggrid Datas Download
  const handleDownloadExcel = (title: any) => {
    switch (title) {
      case 'utilizationCard':
        utilizationCardRef.current!.onBtExport();
        break;
      case 'billgenerationDate':
        billgenerationDateRef.current!.onBtExport();
        break;
      case 'utilizationPayment':
        utilizationPaymentRef.current!.onBtExport();
        break;
      case 'utilizationSegments':
        utilizationSegmentsRef.current!.onBtExport();
        break;
      case 'utilizationVendor':
        utilizationVendorRef.current!.onBtExport();
        break;
      default:
        break;
    }
  }

  const loggedUserCall = async (data: any) => {
    try {
      const response = await axiosPrivate.get(`/vendor-payable/logedin-user`);
      if (response.status === 200) {
        if (response.data.userData.currentUser == 'singleUser') {
          let singleUser: any = { ...data };
          // delete singleUser['Maker'];
          // delete singleUser['Checker'];
          // delete singleUser['Recommender'];
          // delete singleUser['Approver'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender', 'Approver'])
          setApprovalStatusDatas(singleUser)
        } else {
          await fetchData(data)
        }
        // setCurrentUserTitle('singleUser')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (data: any) => {
    try {
      const response: any = await axiosPrivate.get('/user/get-privilege');
      switch (response.data.UserPrivilege) {
        case "Checker":
          // let Checker: any = { ...data };
          // delete Checker['Maker'];
          // setApprovalStatusDatas(Checker)

          setApprovalStatusDisableDatas(['Maker'])
          break;
        case "Recommender":
          // let Recommender: any = { ...data };
          // delete Recommender['Maker'];
          // delete Recommender['Checker'];
          // setApprovalStatusDatas(Recommender)
          setApprovalStatusDisableDatas(['Maker', 'Checker'])
          break;
        case "Approver":
          // let Approver: any = { ...data };
          // delete Approver['Maker'];
          // delete Approver['Checker'];
          // delete Approver['Recommender'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender'])
          // setApprovalStatusDatas(Approver)
          break;
        case "Payment":
          // let Payment: any = { ...data };
          // delete Payment['Maker'];
          // delete Payment['Checker'];
          // delete Payment['Recommender'];
          // delete Payment['Approver'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender', 'Approver'])
          // setApprovalStatusDatas(Payment)
          break;
        default:
          setApprovalStatusDatas(data)
          break;

      }
      setApprovalStatusDatas(data)
      // setNavbarTitle(response.data.UserPrivilege)
    } catch (error) {
      console.log(error);
    }
  }

  const getInvoices = async () => {
    try {
      const response = await axiosPrivate.get(`/vendor-payable/vendor/invoice-details`);
      setRowDataInvoiceGrid(response.data.payableInvoices);
    } catch (error) {
      console.log("error", error)
    }
  }

  const vendorColumnData = [
    {
      field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
      field: 'Invoice Details', headerName: 'Invoice Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = (e.data.approvalStatus === "Approved" || e.data.approvalStatus === "Completed") ? "green" : e.data.approvalStatus === "Declined" ? "red" : "#0055D4";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Number:</span> {e.data.invoiceNumber}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.approvalStatus}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Created date:</span> {e.data.invoiceDate}
            </div>
          </div>
        )
      },
    },
    // { 
    //     field: 'draft', headerName: 'Draft', minWidth: 100, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    // },
    {
      field: 'Company Name', headerName: 'Company Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div>{e.data.companyName}</div>
          </div>
        )
      },
    },
    {
      field: 'Payment Details', headerName: 'Payment Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = e.data.payment_Status === "Captured" ? "green" : e.data.payment_Status === "Failed" ? "red" : "black";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>ID:</span> {e.data.razorpayPaymentId}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.payment_Status}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Order ID:</span> {e.data.razorpayOrderId}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Settlement Details', headerName: 'Settlement Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = e.data.settlementStatus === "Scheduled" ? "#0c1dff" : e.data.settlementStatus === "Settled" ? "green" : "black";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.settlementStatus}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Date:</span> {DateConverter(e.data.settlementDate)}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Reference No', headerName: 'Reference No', minWidth: 380, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>UTR:</span> {e.data.utrNumber}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Settlement ID:</span> {e.data.settlementId}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Amount Details', headerName: 'Amount Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Payable Amount:</span> {e.data.paymentAmount}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount to Vendor:</span> {e.data.amountToVendor}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount Paid:</span> {e.data.amountPaid}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount Due:</span> {e.data.amountDue}
            </div>
          </div>
        )
      },
    },
  ]


  return (
    <div className='company-admin-dashboard' style={{ padding: "50px" }}>
      <>
        <div className='payment-reminder-container'>
          <h4>{"Payment Reminder"}</h4>
          <div className='payment-reminders'>
            <PaymentReminder
              paymentTitle={"Vendor Payment"}
              paymentAmount={payementReminderAmount ? payementReminderAmount : "--"}
              background={"transparent linear-gradient(247deg, #0055D4 0%, #011D60 100%) 0% 0% no-repeat padding-box"}
            />
          </div>
        </div>

        <h4 style={{ marginTop: "20px" }}>{"Transaction History"}</h4>
        <div className='user-details'>
          <div className='transaction-history-container'>
            <div>
              <div className='summary-view-dashboard'>
                <AgGrid
                  ref={parentGridDownloadRef}
                  rowHeight={140}
                  hidePaginationDD={true}
                  fileName={"Payment-processes-details"}
                  tabelRowData={rowDataInvoiceGrid}
                  tableColumnData={currentActor === "singleUser" ? vendorColumnData.filter((obj: any) =>
                    obj.field !== "approvalStatus" &&
                    obj.field !== "checker" &&
                    obj.field !== "recommender" &&
                    obj.field !== "checkerStatus" &&
                    obj.field !== "recommenderStatus" &&
                    obj.field !== "approver"
                  ) : vendorColumnData
                  }
                  displaylength={25}
                  doubleClickUpdate={() => {
                    // getEditData && getEditData("", false);
                  }}
                  // singleSelect={true}
                  getEdit={() => { }}
                  getCurruntData={() => { }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </div >

  )
}
export default VendorAdminDashboard;