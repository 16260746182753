import { useRef, useState, useEffect } from 'react'
import Card from '../common-component/cards/card';
import ChartAgGrid, { AgGridRef } from '../common-component/charts/chart-ag-grid';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { coloumDataSample } from '../../config/dashboard/pa-transaction-history-config';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/dashboard/organization-vs-companies.scss';
import CustomEllipsisRenderer from '../../config/dashboard/customers-vs-transaction-value-config';
import { DateConverter } from '../../helpers/capitalizetext';

interface propsType {
  dashBoardWidth?: any;
  setOpenOneDashboard?: any;
  isOpenEmpty?: boolean;
  setIsOpenEmpty?: any;
}

function PaTransactionHistory({ dashBoardWidth, setOpenOneDashboard, isOpenEmpty, setIsOpenEmpty }: propsType) {
  // gridIcon 
  const [tableColumnData, setTableColumnData] = useState<any[]>([]);
  const [tableRowData, setTableRowData] = useState<any[]>([]);
  // axiosPrivate
  const axiosPrivate = useAxiosPrivate();
  // useref for grid
  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const width = window.innerWidth;

  // initialApiCall
  useEffect(() => {
    getBarDataForApi()
  }, [])

  // chartApiFunctions
  async function getBarDataForApi() {
    try {
      const res = await axiosPrivate.post(`product-admin/transaction-history/report-data`);
      if (res.status === 200) {
        setTableRowData(res.data.payableInvoices)
        // handleConvertTableData(res)
      }
    } catch (error) {
      console.log(`Error:${error}`)
    }
  }

  // handleGridViewIconFunctions
  // function handleConvertTableData(data: any) {
  //   let addColumnData: any[] = [];
  //   let addRowData: any[] = [];

  //   Object.keys(data.data.transactionHistory[0]).map((dataset: any) => {
  //     if (["Company Name", "Name/Business", "Date"].includes(dataset)) {
  //       addColumnData.push({
  //         ...coloumDataSample,
  //         cellStyle: { textAlign: 'left' },
  //         field: dataset,
  //         headerName:  dataset,
  //       })
  //     } else if (["Amount", "Transaction Charge", "Total Amount"].includes(dataset)) {
  //       addColumnData.push({
  //         ...coloumDataSample,
  //         cellStyle: { textAlign: 'right' },
  //         headerClass: 'header-right-transaction',
  //         field: dataset,
  //         headerName: `${dataset} ₹`,
  //       })
  //     } else if (["Status","InvoiceId"].includes(dataset)) {
  //       addColumnData.push({
  //         ...coloumDataSample,
  //         cellStyle: { textAlign: 'left' },
  //         minWidth: 150,
  //         field: dataset,
  //         headerName: dataset.includes("InvoiceId") ? "Invoice Id" :dataset,
  //       })
  //     } else {
  //       addColumnData.push({
  //         ...coloumDataSample,
  //         field: dataset,
  //         headerName: dataset,
  //       })
  //     }
  //   })

  //   data.data.transactionHistory.map((dataset: any, i: number) => {
  //     let rowData: any = {};
  //     Object.keys(dataset).map((elem: any) => {
  //       Object.assign(rowData, { [elem]: dataset[elem] === null ? "" : dataset[elem] });
  //     })
  //     addRowData.push(rowData);
  //   })

  //   setTableColumnData(addColumnData)
  //   setTableRowData(addRowData)
  // }
  const vendorColumnData = [
    {
      field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },

    {
      field: 'Invoice Details', headerName: 'Invoice Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = (e.data.approvalStatus === "Approved" || e.data.approvalStatus === "Completed") ? "green" : e.data.approvalStatus === "Declined" ? "red" : "#0055D4";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Number:</span> {e.data.invoiceNumber}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.approvalStatus}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Created date:</span> {e.data.invoiceDate}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Vendor Name', headerName: 'Vendor Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            {
              e.data.vendorName ?
                <div>{e.data.vendorName}</div>
                :
                <div>{e.data.vendorRazorpayName}</div>
            }
          </div>
        )
      },
    },
    {
      field: 'Payment Details', headerName: 'Payment Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = e.data.payment_Status === "Captured" ? "green" : e.data.payment_Status === "Failed" ? "red" : "black";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>ID:</span> {e.data.razorpayPaymentId}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.payment_Status}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Order ID:</span> {e.data.razorpayOrderId}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Settlement Details', headerName: 'Settlement Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        const statusColor = e.data.settlementStatus === "Scheduled" ? "#0c1dff" : e.data.settlementStatus === "Settled" ? "green" : "black";
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Status:</span> <span style={{ color: statusColor }}>{e.data.settlementStatus}</span>
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Date:</span> {DateConverter(e.data.settlementDate)}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Reference No', headerName: 'Reference No', minWidth: 380, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>UTR:</span> {e.data.utrNumber}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Settlement ID:</span> {e.data.settlementId}
            </div>
          </div>
        )
      },
    },
    {
      field: 'Amount Details', headerName: 'Amount Details', minWidth: 320, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{ whiteSpace: 'normal', height: "100%", width: "100%", wordWrap: "break-word", lineHeight: "35px" }}>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Payable Amount:</span> {e.data.paymentAmount}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount to Vendor:</span> {e.data.amountToVendor}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount Paid:</span> {e.data.amountPaid}
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ fontWeight: "500" }}>Amount Due:</span> {e.data.amountDue}
            </div>
          </div>
        )
      },
    },
  ]

  //download
  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  }

  return (
    <div className='organization-vs-companies-component'>
      <div className='charts-main'>
        <Card
          width={!isOpenEmpty || (width < 1869 && width > 1601) || (width < 1601) ? dashBoardWidth.cardWidth : "1510px"}
          height={isOpenEmpty ? "400px" : dashBoardWidth.cardHeight}
          isOpenEmpty={isOpenEmpty}
          setIsOpenEmpty={setIsOpenEmpty}
          currentChart={"paTransactionHistory"}
          chartHeading={"Transaction History"}
          isGroupbyIconDisabled={true}
          isSaveFilterIconDisabled={true}
          isModelNeed={true}
          isFilterNeed={true}
          isGridNeed={true}
          setOpenOneDashboard={setOpenOneDashboard}
          isOpenGrid={true}
          handleDownloadExcel={handleDownloadExcel}
        >
          <div key={uuidv4()}>
            <ChartAgGrid
              rowHeight={140}
              fileName={"Dashboard Report"}
              ref={gridDataDownloadRef}
              tabelRowData={tableRowData}
              tableColumnData={vendorColumnData}
              className={!isOpenEmpty ? 'ag-theme-alpine-chart-single table-align-transaction' : 'ag-theme-alpine-chart table-align-transaction'}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PaTransactionHistory;