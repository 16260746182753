import React, { useEffect, useRef, useState } from 'react'
import '../../../styles/modals/vendor-pending-info.scss'
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import Chart from 'chart.js/auto';
import { RiEditFill } from "react-icons/ri";
import { axiosPrivate } from '../../../middleware/axois-api';

interface Styles {
    closeModal: () => void;
    pendingInfoEditClick: (val: any) => void;
    pendingList: any
    overAllPercentage: any
    requirements?: any
}

export default function VendorPendingInfo({ closeModal, pendingInfoEditClick, pendingList, overAllPercentage, requirements }: Styles) {
    const [nameFormatTitle, setNameFormatTitle] = useState<any>({
        basicDetails: 'Basic Details',
        vendorOverView: 'Vendor Overview',
        address: 'Address',
        bankDetails: 'Bank Details',
        pointOfContact: 'Point Of Contact',
        kycDocument: "kyc Document",
        statutoryDetails: "Statutory Details",
        stakeholderInfo: "Stakeholder Info",
        razorpayBusinessDocStatus: "Business Document",
        razorpayStakeholderDocStatus: "Stakeholder Documents",
        razorpayStakeholderStatus: "Stakeholder Info",
        razorpayBankDetailsStatus: "Bank Details",
    })
    const [showPageTitle, setShowPageTitle] = useState<any>({
        basicDetails: 'BasicDetails',
        vendorOverView: 'VendorOverview',
        address: 'Address',
        bankDetails: 'BankDetails',
        pointOfContact: 'PointOfContact',
        kycDocument: "KYCDocuments",
        statutoryDetails: "StatutoryDetails",
        stakeholderInfo: "StakeholderInfo",
        razorpayBusinessDocStatus: "KYCDocuments",
        razorpayStakeholderDocStatus: "StakeholderInfo",
        razorpayStakeholderStatus: "StakeholderInfo",
        razorpayBankDetailsStatus: "BankDetails",
    })
    const [columnNameFormatTitle, setColumnNameFormatTitle] = useState<any>({
        companyCIN: "Company CIN",
        companyLLPIN: "Company LLPIN",
        companyPAN: "Company PAN",
        legalBusinessName: "Legal Business Name",
        businessType: "Business Type",
        businessCategory: "Business Category",
        businessSubCategory: "Business Sub Category",
        businessModel: "Business Model",
        website: "Website",
        description: "Description",
        contact_street_1: "Contact Street 1",
        contact_street_2: "Contact Street 2",
        contact_city: "Contact City",
        contact_state: "Contact State",
        contact_postalCode: "Contact Postal Code",
        contact_country: "Contact Country",
        registered_street_1: "Registered Street 1",
        registered_street_2: "Registered Street 2",
        registered_city: "Registered City",
        registered_state: "Registered State",
        registered_postalCode: "Registered Postal Code",
        registered_country: "registered_country",
        accountNumber: "Account Number",
        ifscCode: "IFSC Code",
        accountName: "Account Name",
        contactPersonName: "Contact Person Name",
        contactNumber: "Contact Number",
        email: "Email",
    })
    const [requirementsTitle, setRequirementsTitle] = useState<any>({
        'settlements.beneficiary_name': "Bank - Account Name",
        'settlements.account_number': "Bank - Account Number",
        'settlements.ifsc_code': "Bank - IFSC Code",
        'name': "Stakeholders - Name",
        'kyc.pan': "Stakeholders - Pan",

        'individual_proof_of_address': "Stakeholders - Documents",
        'individual_proof_of_identification': "Stakeholders - Documents",
        'additional_documents.affiliation_certificate': "Accounts Info - Additional Documents",
        'additional_documents': "Accounts Info - Additional Documents",
        'business_proof_of_identification.business_pan_url': "Accounts Info - Business Pan",
        'business_proof_of_identification.business_proof_url': "Accounts Info - Business Proof",
    })

    useEffect(() => {
        if (document.querySelector(".changes-modal")?.parentElement?.className == "side-main-navbar") {
            (document.querySelector(".sidebar") as HTMLElement).style.zIndex = "11";
            (document.querySelector(".header") as HTMLElement).style.zIndex = "10";
        } else {
            (document.querySelector(".sidebar") as HTMLElement).style.zIndex = "10";
            (document.querySelector(".header") as HTMLElement).style.zIndex = "11";
        }
    }, [])
    const chartRef: any = useRef(null);
    useEffect(() => {
        const data = {
            value: overAllPercentage,
            max: 100,
            label: "Progress"
        };

        const config: any = {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [data.value, data.max - data.value],
                    backgroundColor: [overAllPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
                    borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 85,
                rotation: -90,
                circumference: 180,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                animation: {
                    animateRotate: true,
                    animateScale: false
                },
                title: {
                    display: true,
                    text: data.label,
                    fontSize: 16
                }
            }
        };

        if (chartRef.current) {
            const chartCtx = chartRef.current.getContext('2d');
            const gaugeChart = new Chart(chartCtx, config);

            // Cleanup on unmount
            return () => {
                gaugeChart.destroy();
            };
        }
    }, []);

    return (
        <div className={"vendor-pending-info-main"}>
            <div className={"container"}>
                <h5>{"Pending Information"}</h5>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                    <div style={{ position: 'relative', height: '140px', width: '400px' }}>
                        <canvas ref={chartRef}></canvas>
                        <div style={{ position: "absolute", left: "173px", top: "90px", textAlign: "center" }}><b>{overAllPercentage}%</b> <br />Overall</div>
                        <div style={{ position: "absolute", left: "90px" }}>0%</div>
                        <div style={{ position: "absolute", right: "80px" }}>100%</div>
                    </div>
                </div>
                <div>
                    {
                        requirements?.filter((e: any) => e.reason_code == "field_missing").length > 0 &&
                        <>
                            <h5 style={{ margin: "20px 0px" }}>{"Field Missing"}</h5>
                            {
                                requirements?.filter((e: any) => e.reason_code == "field_missing").map((e: any) => (
                                    <>
                                        <div>
                                            {requirementsTitle[e.field_reference]}
                                        </div>
                                    </>
                                ))
                            }
                        </>
                    }
                    {
                        requirements?.filter((e: any) => e.reason_code == "document_missing").length > 0 &&
                        <>
                            <h5 style={{ margin: "20px 0px" }}>{"Document Missing"}</h5>
                            {
                                requirements?.filter((e: any) => e.reason_code == "document_missing").map((e: any) => (
                                    <div>
                                        {requirementsTitle[e.field_reference]}
                                    </div>
                                ))
                            }
                        </>
                    }
                </div>
                {/* <div style={{ height: pendingList?.length > 0 ? "400px" : "", overflowY: "auto", width: "100%" }}>
                    {
                        pendingList?.map((e: any) => {
                            return (
                                <div style={{ width: "100%", overflowY: "auto", marginTop: "30px" }}>
                                    <div className='pendingList'>
                                        <div className='heading'>
                                            <b>{nameFormatTitle[e]}</b>
                                            <div onClick={() => {
                                                pendingInfoEditClick(showPageTitle[e])
                                            }}><RiEditFill style={{ fontSize: "25px", color: "#0055D4", cursor: "pointer" }} /></div>
                                        </div>
                                        <div>
                                            {"Click the edit icon to complete the pending information"}
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div> */}

                <div className='cancelBtn'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"140px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={closeModal}
                    />
                </div>

                {/* <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 style={{ fontSize: "40px" }} onClick={handleClose} />
                    </span>
                </div>

                <div className={'changes-title'}>

                    {
                        modelType == "navigate-radiobtn" ?
                            <div className={"title"}>{type === "logout" ? "" : "Leave this page?"}</div>
                            :
                            <div className={"title"}>{type === "logout" ? "" : "Leave this page?"}</div>
                    }
                    {
                        modelType == "navigate-radiobtn" ?
                            <div className={"sub-title"}>{type === "logout" ? "Are you sure want to exit application?" : "Changes you made will not be saved"}</div>
                            :
                            <div className={"sub-title"}>{type === "logout" ? "Are you sure want to exit application?" : "Changes you made will not be saved"}</div>
                    }
                    <div className={"content"}>
                    </div>

                    <div className={"buttons"} >
                        {
                            modelType == "navigate-radiobtn" ?
                                <>
                                    <ButtonComponent
                                        title={"Stay"}
                                        height={"50px"}
                                        width={"140px"}
                                        backgroundColor={"#888888"}
                                        color={"white"}
                                        className={"button-component-hover cancel"}
                                        handleClick={closeModal}
                                    />
                                    <ButtonComponent
                                        title={"Leave"}
                                        height={"50px"}
                                        width={"140px"}
                                        backgroundColor={"#0055D4"}
                                        color={"white"}
                                        handleClick={leavePage}
                                        className={"button-component common-btn"}
                                    />
                                </>
                                :
                                <>
                                    <ButtonComponent
                                        title={type === "logout" ? "Yes" : "Stay"}
                                        height={"50px"}
                                        width={"140px"}
                                        backgroundColor={"#888888"}
                                        color={"white"}
                                        className={"button-component-hover cancel"}
                                        handleClick={closeModal}
                                    />
                                    <ButtonComponent
                                        title={type === "logout" ? "No" : "Leave"}
                                        height={"50px"}
                                        width={"140px"}
                                        backgroundColor={"#0055D4"}
                                        color={"white"}
                                        handleClick={leavePage}
                                        className={"button-component common-btn"}
                                    />
                                </>
                        }
                    </div>
                </div> */}
            </div>
        </div >
    )
}

