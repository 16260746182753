import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import DoumentDeclinemodal from './document-decline-modal';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import "../../../styles/modals/document-show-modal.scss"
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from './loading-screen';
import AlertBox from '../alert-box';

interface props {
    document: any;
    showDocumentModal?: any;
    documentDeclineStatus?: any;
    kycId: any;
    proof: any;
    companyId: any;
    closeDocument: (val: any) => void;
    setDocumentDeclineStatus: (val: any, type: string) => void;
    setDocumentDeclineValues: (val: any) => void;
    getOverallDetails: (val: any) => void;
}

export default function DocumentShowModal({
    closeDocument,
    document,
    showDocumentModal,
    documentDeclineStatus,
    setDocumentDeclineStatus,
    kycId,
    proof,
    companyId,
    setDocumentDeclineValues,
    getOverallDetails
}: props) {

    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [zoomLevel, setZoomLevel] = useState(600);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")
    const [isChecked, setIsChecked] = useState(false);

    const [userEditedData, setUserEditedData] = useState<any>([])

    const [comparisonData, setComparisonData] = useState<any>([])

    // Function for toggle the mismatch button
    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    // // Function for Approve Button... 
    async function updateAproveStatus(status: string) {

        try {
            const response = await axiosPrivate.patch(`customer-onboarding/approval-status/update`, {
                companyKYCId: kycId,
                status: {
                    approvalStatus: status,
                    description: status === "Approved" ? "" : documentDeclineStatus.Documentremarks,
                }
            });

            if (response.status === 200) {

                getOverallDetails(companyId)
                closeDocument(false)
                setDocumentDeclineValues("")
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(response.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            };
        } catch (error: any) {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.message)

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    // Function for setting Comparison data from hyperverge...
    async function hypervergeDataComparison() {
        try {
            const response = await axiosPrivate.post(`/customer-onboarding/certificate-details/get`, {
                "proofType": proof,
                "companyId": companyId
            });
            if (response.status === 200) {
                setComparisonData(response.data.hypervergeData)
                setUserEditedData(response.data.userEditData)
                setLoadingScreen(false)
            } else {
                setLoadingScreen(false)
            }
        } catch (error: any) {
            // setShowAlertBox(true)
            // setShowType("warning")
            // setShowMessage(error.message)

            setLoadingScreen(false)

            // setTimeout(() => {
            //     setShowAlertBox(false);
            // }, 5000);
        }
    }

    // Function for showing pdf or image files...
    useEffect(() => {
        const downloadfile = async () => {
            setLoadingScreen(true)
            try {
                const response = await axiosPrivate.post(`customeronboarding/download`, { document: document },
                    {
                        responseType: "arraybuffer"
                    });

                const contentType = response.headers['content-type'];
                const isPdf = contentType === 'application/pdf' ? contentType === 'application/pdf' : false;
                const blob = new Blob([response.data], { type: contentType });
                const objectURL = URL.createObjectURL(blob);

                // const fileURL = URL.createObjectURL(response.data);
                const fileURL = objectURL;

                if (isPdf) {
                    setPdfUrl(fileURL);
                } else {
                    setImgUrl(fileURL);
                }

                if (response.status === 200) {
                    // hypervergeDataComparison()
                    setLoadingScreen(false)
                }

            } catch (error: any) {
                setLoadingScreen(false)
                // setShowAlertBox(true)
                // setShowType("warning")
                // setShowMessage(error.message)

                // setTimeout(() => {
                //     setShowAlertBox(false);
                // }, 5000);
            }
        }
        downloadfile()
    }, [showDocumentModal])

    // Function for ZoomIn 
    const handleZoomIn = () => {
        if (zoomLevel < 1500) {
            setZoomLevel(zoomLevel + 200);
        }
    };

    // Function for ZoomOut
    const handleZoomOut = () => {
        if (zoomLevel > 300) {
            setZoomLevel(zoomLevel - 200);
        }
    };

    return (
        <>
            <div className={"document-show-modal"}>
                <div style={{ display: "flex", gap: "20px" }} className={"main-document"}>



                    <div className={"main-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                            </span>
                        </div>
                        <div className='document-content'>
                            {pdfUrl ? (
                                <div style={{ height: "700px", width: "600px" }} >
                                    <object
                                        data={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                        aria-label="Online PDF"
                                    ></object>
                                </div>
                            ) : imgUrl ? (
                                <div style={{ overflow: "hidden", overflowY: "auto", overflowX: "auto", maxHeight: "650px", maxWidth: "650px" }}>
                                    <img src={imgUrl}
                                        alt="Image"
                                        style={{
                                            maxHeight: `${zoomLevel}px`,
                                            maxWidth: `${zoomLevel}px`,
                                        }} />
                                </div>
                            ) : (
                                <div>No valid file found</div>
                            )}

                            {imgUrl &&
                                <>

                                    {/* <div className={"document-show-model-buttons"} > */}
                                    <div style={{ position: 'absolute', top: 730, left: 260, display: "flex", gap: "3px" }}>
                                        <ButtonComponent
                                            title={"Decline"}
                                            height={"50px"}
                                            width={"130px"}
                                            backgroundColor={"white"}
                                            color={"#004E9C"}
                                            className={"button-component common-btn"}
                                            border={"1px solid #004E9C"}
                                            handleClick={() => { setShowDeclineModal(!showDeclineModal); }}
                                        />
                                        <ButtonComponent
                                            title={"Approve"}
                                            height={"50px"}
                                            width={"130px"}
                                            backgroundColor={"#0055D4"}
                                            color={"white"}
                                            className={"button-component common-btn"}
                                            handleClick={() => {
                                                closeDocument(false)
                                                updateAproveStatus("Approved")
                                            }}
                                        />
                                        {/* </div> */}

                                        <AiOutlineMinusCircle className={"zoomOut"} onClick={handleZoomOut} />
                                        <IoIosAddCircleOutline className={"zoomIn"} onClick={handleZoomIn} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>



                    {/* <div className={"sub-container-document-show-model"}>


                        <div className='checkbox-Group' onClick={toggleCheckbox} style={{ userSelect: "none" }}>
                            <input type="checkbox" checked={isChecked} onChange={toggleCheckbox} id={"isChecked"} />
                            {"Data Mismatch"}
                        </div>

                        <div className='document-content'>
                            <div className='view-table-component' style={{ marginTop: "10px", marginBottom: "40px" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='document-type'>Details</th>
                                            <th className='proof'>Hyperverge</th>
                                            <th className='file-status'>Fingertip (user edited)</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {!isChecked && comparisonData.length === 0 && userEditedData.length === 0 && (
                                            <div className='main-body-header'>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No records to show</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </div>
                                        )}
                                        {(
                                            isChecked && (
                                                (comparisonData.length === 0 && userEditedData.length === 0) || // No data
                                                (comparisonData.every((data: any, index: number) => {
                                                    return Object.keys(data).every((values) => {
                                                        return data[values] === userEditedData[index][values];
                                                    });
                                                }))
                                            ) ? (
                                                <div className='main-body-header'>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td>No records to show</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </div>
                                            ) : (
                                                comparisonData.length > userEditedData.length ? (
                                                    comparisonData?.map((data: any, index: number) => {
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    Object.keys(data).map((values: any, i: number) => {
                                                                        return (
                                                                            <div className='main-body-header'
                                                                                key={i}
                                                                                style={{
                                                                                    display: (isChecked && data[values].toLowerCase() && userEditedData[index] && userEditedData[index][values].toLowerCase() && data[values].toLowerCase() !== userEditedData[index][values].toLowerCase()) ? "block" : isChecked ? "none" : "block",

                                                                                    borderColor: data[values].toLowerCase() && userEditedData[index] && userEditedData[index][values].toLowerCase() && data[values].toLowerCase() !== userEditedData[index][values].toLowerCase() ? "red" : "#B3CAE1"
                                                                                }}
                                                                            >
                                                                                <tr>
                                                                                    <td className='document-type'
                                                                                        style={{ color: data[values].toLowerCase() && userEditedData[index] && userEditedData[index][values].toLowerCase() && data[values].toLowerCase() !== userEditedData[index][values].toLowerCase() ? "red" : "black" }}
                                                                                    >{values}
                                                                                    </td>
                                                                                    <td className='proof'>{data[values]}</td>
                                                                                    <td className='file-status'>
                                                                                        {userEditedData[index] && userEditedData[index][values]}
                                                                                    </td>
                                                                                </tr>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    userEditedData?.map((data: any, index: number) => {
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    Object.keys(data).map((values: any, i: number) => {
                                                                        return (
                                                                            <div
                                                                                className='main-body-header'
                                                                                key={i}
                                                                                style={{ display: (isChecked && comparisonData[index] && data[values].toLowerCase() && comparisonData[index][values].toLowerCase() && comparisonData[index][values].toLowerCase() !== data[values].toLowerCase()) ? "block" : isChecked ? "none" : "block", borderColor: comparisonData[index] && comparisonData[index][values].toLowerCase() && comparisonData[index][values].toLowerCase() !== data[values].toLowerCase() ? "red" : "#B3CAE1" }}
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        className='document-type'
                                                                                        style={{ color: comparisonData[index] && comparisonData[index][values].toLowerCase() && comparisonData[index][values].toLowerCase() !== data[values].toLowerCase() ? "red" : "black" }}
                                                                                    >{values}
                                                                                    </td>
                                                                                    <td className='proof'>{comparisonData[index] ? comparisonData[index][values] : ""}</td>
                                                                                    <td className='file-status'>{data[values] ? data[values] : ""}
                                                                                    </td>
                                                                                </tr>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                )
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={"document-show-model-buttons"} >
                            <ButtonComponent
                                title={"Decline"}
                                height={"50px"}
                                width={"130px"}
                                backgroundColor={"white"}
                                color={"#004E9C"}
                                className={"button-component common-btn"}
                                border={"1px solid #004E9C"}
                                handleClick={() => { setShowDeclineModal(!showDeclineModal); }}
                            />
                            <ButtonComponent
                                title={"Approve"}
                                height={"50px"}
                                width={"130px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                className={"button-component common-btn"}
                                handleClick={() => {
                                    closeDocument(false)
                                    updateAproveStatus("Approved")
                                }}
                            />
                        </div>
                    </div> */}
                </div>
            </div>

            {
                showDeclineModal &&
                <DoumentDeclinemodal
                    setDocumentDeclineStatus={setDocumentDeclineStatus}
                    documentDeclineStatus={documentDeclineStatus}
                    clearDoumentDeclinemodal={(val: boolean) => { setShowDeclineModal(val); }}
                    closeDocument={(val: boolean) => { closeDocument(val) }}
                    updateAproveStatus={() => updateAproveStatus("Declined")}
                />
            }

            {loadingScreen && <Loading />}
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
        </>
    )
}



