import { useState } from 'react';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import { ReactComponent as Message } from '../../../assets/icons/message.svg'
import { ReactComponent as MessageNotify } from '../../../assets/icons/messageNotify.svg'
import { RiDeleteBin6Line } from 'react-icons/ri';

interface Props {
    handleIconClick: (val: string) => void;
    iconClicked: boolean;
    showIcon?: boolean;
    iconType?: string;
    deleteIcon?: string;
    data: { vendorName: string }; // Add the data property to the Props interface
    remarks?: string
    isDraft?: string
}

const CellEyeIcon: React.FC<Props> = ({ handleIconClick, iconClicked, data, showIcon, iconType, remarks, deleteIcon, isDraft }) => {

    return (
        <div>
            {
                (data.vendorName !== "Bulk Payment" || showIcon) &&
                <span
                    data-testid="column-icon"
                    className="header-icon-individual"
                    onClick={() => {
                        handleIconClick("eye");
                    }}
                >
                    {!iconClicked ? <BsEyeFill className='icon' /> : <BsEyeSlashFill className='icon' />}
                </span>
            }
            {
                (iconType === "remarks") &&
                <span
                    style={(remarks === "" || remarks == undefined || remarks == null) ? { pointerEvents: "none", opacity: "0.5" } : {}}
                    data-testid="column-icon"
                    className="header-icon-individual"
                    onClick={() => {
                        handleIconClick("remarks");
                    }}
                >
                    {(remarks === "" || remarks == undefined || remarks == null) ? <MessageNotify className='icon' /> : <Message className='icon' />}
                </span>
            }
            {
                (deleteIcon === "delete") &&
                <span
                    style={(isDraft === "No" || isDraft == undefined || isDraft == null) ? { pointerEvents: "none", opacity: "0.5" } : {}}
                    data-testid="column-icon"
                    className="header-icon-individual"
                    onClick={() => {
                        handleIconClick("delete");
                    }}
                >
                    {(isDraft !== "No" || isDraft == undefined || isDraft == null) ? <RiDeleteBin6Line className='icon' style={{ fill: "#FF0000" }} /> : <RiDeleteBin6Line className='icon' />}
                </span>
            }
        </div>
    );
};

export default CellEyeIcon;
