import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import TextArea from '../../common-component/form-elements/text-area'
import "../../../styles/organisation/bank/form.scss"
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";



export interface bankValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}

interface FormProps {
    userNavigateFunction: (val: string) => void;
    userNavigate: string;
    isEditMode: boolean;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
}
export interface BankDataType {
    customerId: string,
    accountNumber: string;
    bank: string;
    ifsc: string;
    branch: string;
    address: string;
    priority: string;
    isActive: string;
    status: string;
    remarks: string;
    [key: string]: string;
}

const Form: React.FC<FormProps> = ({ userNavigateFunction, getEditMode, isEditMode, editData, modeFunction }) => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [componentNavigator, setComponentNavigator] = useState("form-page");
    const [priorityValues, setPriorityValues] = useState<any[]>([]);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [bankValues, setBankValues] = useState<any[]>([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [organisationBankData, setOrganisationBankData] = useState<BankDataType>({
        customerId: "",
        accountNumber: "",
        accountName: "",
        bank: "Select",
        ifsc: "",
        branch: "",
        address: "",
        priority: "",
        isActive: "Yes",
        status: "",
        remarks: "",
        approvedBy: "",

    });

    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    // regux for mandatory while click save 
    const bankValidationRules: bankValidationRulesInterface = {
        Bank_details: {
            customerId: {
                regex: /^[A-Za-z0-9]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            accountNumber: {
                regex: /^\d+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            accountName: {
                regex: '',
                field: "mandatory",
                shouldNotBe: ""
            },
            bank: {
                regex: "",
                field: "mandatory",
                shouldNotBe: "Select"
            },
            ifsc: {
                regex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            branch: {
                regex: /^[a-zA-Z][A-Za-z. ]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            address: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },
            priority: {
                regex: "",
                field: "",
                shouldNotBe: "Select"
            },
            isActive: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""

            },

            remarks: {
                regex: (organisationBankData.isActive === "No" && isEditMode) ? /^[^\s].*/ : "",
                field: (organisationBankData.isActive === "No" && isEditMode) ? "mandatory" : "",
                shouldNotBe: ""

            },

        }
    }

    // useEffect for dropdown functions
    useEffect(() => {
        fetchPriorityValues();
        fetchBankValues();
    }, []);

    // useEffect for retreving data while edit 
    useEffect(() => {
        if (!isEditMode) return;
        setOrganisationBankData({
            customerId: editData.customerId,
            accountNumber: editData.accountNumber,
            accountName: editData.accountName,
            bank: editData.bank,
            ifsc: editData.ifsc,
            branch: editData.branch,
            address: editData.address,
            priority: editData.priority,
            isActive: editData.isActive,
            status: editData.status,
            approvedBy: editData.approvedBy,
            remarks: editData.remarks || "",
            declineRemarks: editData.declineRemarks || "",
            companyBankId: editData.companyBankId
        });
    }, [isEditMode]);


    // dropdown value for priority
    const fetchPriorityValues = async () => {
        try {
            const response = await axiosPrivate.get("priority");
            if (response.status === 200) {
                const dropdownValues = response.data.priority.map((item: any) => ({
                    label: item.priorityName,
                    value: item.priorityName,
                    id: item.priorityId,
                }));

                const selectOption = {
                    label: "Select",
                    value: "",
                    id: "",
                };

                const bankValuesWithSelectOption = [selectOption, ...dropdownValues];

                setPriorityValues(bankValuesWithSelectOption);
            }
        } catch (error) {
        }
    };

    // dropdown value for bank
    const fetchBankValues = async () => {
        try {
            const response = await axiosPrivate.get("bank");
            if (response.status === 200) {
                const dropdownValues = response.data.bank.map((item: any) => ({
                    label: item.bankName,
                    value: item.bankName,
                    id: item.bankId,
                }));
                setBankValues(dropdownValues);
            }
        } catch (error) {
        }
    };

    // Regux validation
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(bankValidationRules.Bank_details).forEach((field) => {
            const rule = bankValidationRules.Bank_details[field];
            const value = organisationBankData[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid

        });
        setUserDataErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        }
        );

        return isFormValid;
    };

    // Timeout function for messages
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 200) {
                userNavigateFunction("grid")
            }
            clearTimeout(timer);
        }, 5000);
    }

    // This function call while click save button
    async function organisationBankDataSave() {
        const isValid = validateForm();
        if (isValid) {
            setIsLoading(true)

            try {
                const dataToSend = {
                    ...organisationBankData,
                    status: "Pending Approval",
                    approvedBy: "",
                    page: "master"
                };

                const res = isEditMode ?
                    await axiosPrivate.patch(`companyadmin/bank/update/${organisationBankData.companyBankId}`, dataToSend) :
                    await axiosPrivate.post('companyadmin/bank', dataToSend)
                if (res.status === 200) {
                    setIsLoading(false)
                    setShowAlertBox(true)
                    getEditMode(false)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    clearAleart(res.status)
                    dispatch(resetFormModified(false));
                    // userNavigateFunction("grid")
                }
            } catch (error: any) {
                setIsLoading(false)
                if (error.status === 500) {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")

                } else if (error.code === "ERR_NETWORK") {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                } else {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                }
            }
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart("")
        }

    }
    // This function call while click cancel button
    const organisationBankDataCancel = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {

            setOrganisationBankData({
                customerId: "",
                accountNumber: "",
                accountName: "",
                bank: "",
                ifsc: "",
                branch: "",
                address: "",
                priority: "",
                isActive: "",
                status: "",
                remarks: "",

            })
            modeFunction(false)
            userNavigateFunction("grid")
        }
    }

    return (
        <>
            <MainPageCard>
                <NavigateHeader
                    style={{
                        cursor: "pointer"
                    }}
                    firstValue={"Organisation"}
                    secondValue={"Bank Details"} navigatePage={() => { organisationBankDataCancel() }} />
                {componentNavigator === "form-page" &&
                    <div>
                        <div className='form-top-main'>
                            <div className='bank-main-form'>
                                <div className='form-input'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"350px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={userDataErrors.customerId ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        autoFocus={true}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        color={"black"}
                                        type={"mobile"}
                                        maxLength={20}
                                        inputTitle={"Customer ID"}
                                        placeHolder={"Enter Customer ID"}
                                        required={true}
                                        inputValue={organisationBankData.customerId}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            const number = value.replace(/[^0-9]/g, "")
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                customerId: number,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    <InputComponent
                                        height={"40px"}
                                        width={"350px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={userDataErrors.accountNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"mobile"}
                                        maxLength={20}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        inputTitle={"Account Number"}
                                        placeHolder={"Enter Account Number"}
                                        required={true}
                                        inputValue={organisationBankData.accountNumber}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            const number = value.replace(/[^0-9]/g, "")
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                accountNumber: number,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    <InputComponent
                                        height={"40px"}
                                        width={"350px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={userDataErrors.accountName ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={100}
                                        inputTitle={"Account Name"}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        placeHolder={"Enter Account Name"}
                                        required={true}
                                        inputValue={organisationBankData.accountName}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            const onlyAlphabet = value.replace(/^[ ]+|[^A-Za-z. ]+/g, '');
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                accountName: onlyAlphabet,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />

                                </div>
                                <div className='form-input'>
                                    <div className={userDataErrors.bank ? 'setError' : 'dropdown-user'} key={uuidv4()}>
                                        <DropdownComponent
                                            options={bankValues}
                                            title={"Bank"}
                                            required={true}
                                            // isDisabled={organisationBankData.status === "Pending Approval"}
                                            // border={userDataErrors.bank ? "1px solid red" : "1px solid #A9C3DC"}
                                            getData={(val) => {
                                                setIsFormDirty(true)
                                                setOrganisationBankData((prevState) => ({
                                                    ...prevState,
                                                    bank: val.value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                            defaultValue={[{ label: organisationBankData.bank ? organisationBankData.bank : "Select", value: organisationBankData.bank }]}
                                        />
                                    </div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"350px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={userDataErrors.ifsc ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={11}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        inputTitle={"IFSC Code"}
                                        placeHolder={"Enter IFSC Code"}
                                        required={true}
                                        inputValue={organisationBankData.ifsc}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                ifsc: ifsc,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />

                                    <InputComponent
                                        height={"40px"}
                                        width={"350px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={userDataErrors.branch ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={100}
                                        inputTitle={"Branch"}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        placeHolder={"Enter Branch"}
                                        required={true}
                                        inputValue={organisationBankData.branch}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)

                                            const onlyAlphabet = value.replace(/[^A-Za-z. ]+/g, '')
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                branch: onlyAlphabet,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    <div className={userDataErrors.priority ? 'setError' : 'dropdown-user'} style={{ marginRight: "0px" }} key={uuidv4()} >
                                        <DropdownComponent
                                            options={priorityValues}
                                            title={"Priority"}
                                            // isDisabled={organisationBankData.status === "Pending Approval"}
                                            required={false}
                                            getData={(val) => {
                                                setIsFormDirty(true)
                                                if (val.value !== "Select") {
                                                    setOrganisationBankData((prevState) => ({
                                                        ...prevState,
                                                        priority: val.value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }
                                            }}
                                            defaultValue={[{ label: organisationBankData.priority ? organisationBankData.priority : "Select", value: organisationBankData.priority ? organisationBankData.priority : "" }]}
                                        />
                                    </div>

                                </div>
                                <div>
                                    <TextArea
                                        name={"Address"}
                                        placeHolder={"Enter Address"}
                                        width={"53%"}
                                        height={"80px"}
                                        // border={userDataErrors.address ? "1px solid red" : "1px solid #A9C3DC"}
                                        margin={"0px 0px 30px 0px"}
                                        // disabled={organisationBankData.status === "Pending Approval"}
                                        padding={"0px 0px 0px 10px"}
                                        maxLength={500}
                                        inputValue={organisationBankData.address}
                                        getUser={(val) => {
                                            setIsFormDirty(true)
                                            setOrganisationBankData((prevState) => ({
                                                ...prevState,
                                                address: val,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div className='form-input'
                                    style={organisationBankData.status === "Pending Approval" || organisationBankData.status === "Declined" ?
                                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                // style={!isEditMode || organisationBankData.status === "Pending Approval" || organisationBankData.status === "Declined" ?
                                //     { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                >
                                    <div className='radio-input user-form-radio-input'>
                                        <p>Is Active<span>*</span></p>
                                        <div className='radio-btn-main'>
                                            <RadioOrCheckbox
                                                value={"Yes"}
                                                type={"radio"}
                                                name={"bankUser"}
                                                disabled={true}

                                                margin={"0px 30px 0px 0px"}
                                                checkedValue={organisationBankData.isActive}
                                                getVal={(val) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationBankData((prevState) => ({
                                                        ...prevState,
                                                        isActive: val,
                                                        remarks: ""
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                            <div style={
                                                !isEditMode && isEditMode ?
                                                    { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}>
                                                <RadioOrCheckbox
                                                    value={"No"}
                                                    name={"bankuser"}
                                                    type={"radio"}
                                                    disabled={true}

                                                    checkedValue={organisationBankData.isActive}
                                                    getVal={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationBankData((prevState) => ({
                                                            ...prevState,
                                                            isActive: val,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {organisationBankData.isActive === "No" &&
                                        <div style={
                                            { pointerEvents: "none", opacity: 0.5 }
                                        }>
                                            <InputComponent
                                                height={"40px"}
                                                width={windowWidth > 1250 ? "760px" : "350px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                maxLength={250}
                                                type={"text"}
                                                disabled={true}
                                                inputTitle={"Remarks"}
                                                required={true}
                                                placeHolder={"Reason"}
                                                inputValue={organisationBankData.remarks}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationBankData((prevState) => ({
                                                        ...prevState,
                                                        remarks: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                {isEditMode && organisationBankData.status !== "Pending Approval" &&
                                    <>
                                        <div style={isEditMode ?
                                            { pointerEvents: "none", opacity: 1, width: "95%" } : {}}>
                                            <div className='bank-approval'>
                                                <div className='approval-title'>
                                                    {organisationBankData.status === "Declined" ? (
                                                        <p>Your request to add a bank master record has been declined.</p>
                                                    ) : (<p>Your request to add a bank master record has been approved.</p>)}
                                                </div>
                                                <div className='approval-radio-input'>
                                                    <div className='approval-radio-btn'
                                                        style={{ pointerEvents: "none", opacity: 0.5 }}
                                                    >
                                                        <RadioOrCheckbox
                                                            value={"Approved"}
                                                            type={"radio"}
                                                            disabled={true}
                                                            margin={"0px 30px 0px 0px"}
                                                            checkedValue={organisationBankData.status}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationBankData((prevState) => ({
                                                                    ...prevState,
                                                                    status: val,
                                                                    declineRemarks: ""
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                                setIsDisabled(false)
                                                            }}
                                                        />
                                                        <div>
                                                            <RadioOrCheckbox
                                                                value={"Declined"}
                                                                type={"radio"}
                                                                disabled={true}
                                                                checkedValue={organisationBankData.status}
                                                                getVal={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setOrganisationBankData((prevState) => ({
                                                                        ...prevState,
                                                                        status: val,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                    setIsDisabled(false)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='decline-reason'>
                                                <div className='reason-area'
                                                    style={{ pointerEvents: "none", opacity: 0.5 }}>
                                                    <InputComponent
                                                        height={"60px"}
                                                        width={"90%"}
                                                        margin={"10px 60px 30px 0px"}
                                                        padding={"0px 0px 0px 10px"}
                                                        border={userDataErrors.declineRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        maxLength={250}
                                                        type={"text"}
                                                        readOnly
                                                        disabled={organisationBankData.status === "Declined" ? false : true}
                                                        inputTitle={"Reason for Declining Request"}
                                                        required={true}
                                                        placeHolder={"Reason"}
                                                        inputValue={organisationBankData.declineRemarks}
                                                        getUser={(value: any) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationBankData((prevState) => ({
                                                                ...prevState,
                                                                declineRemarks: value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                        <div className='footer-user' style={{ marginTop: "35px" }}>
                            <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"150px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={organisationBankDataCancel}
                            />
                            {/* {!isEditMode && <ButtonComponent
                                title={"Submit for Approval"}
                                height={"50px"}
                                width={"200px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={organisationBankDataSave}
                            />
                            } */}
                            {/* {isEditMode && */}
                            <ButtonComponent
                                title={"Submit for Approval"}
                                height={"50px"}
                                width={"200px"}
                                // disabled={isButtonDisabled ? true : false}
                                disabled={(showMessage && showType === "success") ? true : false}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={(showMessage && showType === "success") ? "button-component-hover disabled" : "button-component-hover common-btn"}
                                handleClick={
                                    organisationBankDataSave
                                }
                            />
                            {/* } */}
                        </div>

                    </div>
                }

            </MainPageCard >
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                isLoading &&
                <Loading />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        userNavigateFunction("grid")
                        getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }

        </>
    )
}

export default Form;