import { DateConverterAgGrid } from "../../helpers/capitalizetext";
import CustomEllipsisRenderer from "../product-admin/users/users-aggrid-column-config";


export const companyColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }
    },
    {
        field: 'organisationName', headerName: 'Organisation', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'midStatus', headerName: 'MID Status', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'razorPayMerchantId', headerName: 'Merchant ID', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'platform', headerName: 'Platform', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'createdBy', headerName: 'Created By', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'CompanyName', headerName: 'Company Name', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'CompanyConstitution', headerName: 'Company Constitution', minWidth: 240, maxWidth: 240, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'BuisnessCategory', headerName: 'Business Category', minWidth: 240, maxWidth: 240, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'isActive', headerName: 'Is Active', resizable: false, suppressMovable: true, filter: false, minWidth: 100, maxWidth: 105, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'from', headerName: 'Subscription Validity From', minWidth: 240, maxWidth: 240, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: DateConverterAgGrid,
    },
    {
        field: 'to', headerName: 'Subscription Validity To', minWidth: 240, maxWidth: 240, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: DateConverterAgGrid,
    },
]

// export const rowData = [
//     {
//         companyname: "Larsen & Toubro",
//         companyconstitution: "Private Limited",
//         businesscategory: "Information Technology",
//         isactive: "Yes",
//         subscriptionvalidityfrom: "20-02-2023",
//         subscriptionvalidityto: "20-02-2024"

//     },
//     {
//         companyname: "Komatsu India Pvt. Ltd",
//         companyconstitution: "Private Limited",
//         businesscategory: "Manufacturing",
//         isactive: "Yes",
//         subscriptionvalidityfrom: "20-02-2023",
//         subscriptionvalidityto: "20-02-2024"
//     },
//     {
//         companyname: "Hexaware Technology",
//         companyconstitution: "Private Limited",
//         businesscategory: "Manufacturing",
//         isactive: "Yes",
//         subscriptionvalidityfrom: "20-02-2023",
//         subscriptionvalidityto: "20-02-2024"
//     },
//     {
//         companyname: "TVS Motor Company Limited",
//         companyconstitution: "Private Limited",
//         businesscategory: "Manufacturing",
//         isactive: "Yes",
//         subscriptionvalidityfrom: "20-02-2023",
//         subscriptionvalidityto: "20-02-2024"
//     },
// ]