import React, { useEffect, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import useAuth from '../services/hooks/useauth';
import { v4 as uuidv4 } from 'uuid';

// Icons
import { ReactComponent as DashboardIcon } from '../assets/icons/group.svg';
import { ReactComponent as ReportIcon } from '../assets/icons/reports.svg';
import { ReactComponent as Onboarding } from '../assets/icons/onboarding.svg';
import { ReactComponent as CustomerMakerIcon } from '../assets/icons/customer-maker.svg';
import { ReactComponent as CustomerAuthorizerIcon } from '../assets/icons/customer-authorizer.svg';
import { ReactComponent as Payable } from '../assets/icons/payable.svg';
import { ReactComponent as Approval } from '../assets/icons/approval-icon.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/productadmin.svg';
import { ReactComponent as Organisation } from '../assets/icons/organisation.svg';
import { ReactComponent as UserLogIcon } from '../assets/icons/userlogs.svg';
import { ReactComponent as VendorUserIcon } from '../assets/icons/userIcon.svg'
import { ReactComponent as InvoiceIcon } from '../assets/icons/invoiceIcon.svg'
import { ReactComponent as SecurityLogIcon } from '../assets/icons/security-icon.svg';
import { ReactComponent as IntegrationLogIcon } from '../assets/icons/integration-icon.svg';
import { useLocation, useNavigate } from "react-router-dom";
import Changesmodal from '../components/common-component/modals/changes-modal';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../redux/action";
import useAxiosPrivate from "../services/hooks/useaxios-private";
import { axiosPrivate } from '../middleware/axois-api';

interface props {
    currentPaymentActor: any
    navbarTitle: any
}

// ProductAdmin-
export const ProductAdmin = () => {

    const [selectedTitle, setSelectedTitle] = useState("");
    const [tempSelect, setTempSelect] = useState("");
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [tempNav, setTempNav] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [productadminMenu, setProductadminMenu] = useState(false);
    const [logMonitoringMenu, setLogMonitoringMenu] = useState(false);
    const [approvalMenu, setApprovalMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    useEffect(() => {
        switch (location.pathname) {
            case "/product-admin/organisation":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Organisation")
                setSubSelectedTitle("Organisation")
                break;

            case "/product-admin/Customer-onboarding":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("KYC Onboarding")
                setSubSelectedTitle("KYC Onboarding")
                break;
            case "/product-admin/vendor-onboarding":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Vendor Onboarding")
                setSubSelectedTitle("Vendor Onboarding")
                break;
            case "/product-admin/vendor-bank-approval":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(false)
                setSelectedTitle("VB Master")
                setSubSelectedTitle("VB Master")
                break;

            case "/bank-admin/onboarding/company":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(false)
                setSelectedTitle("Company")
                setSubSelectedTitle("Company")
                break;
            case "/product-admin/user":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(false)
                setSelectedTitle("Users")
                setSubSelectedTitle("Users")
                break;
            case "/product-admin/tally-integration":
                setProductadminMenu(true)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(false)
                setSelectedTitle("Tally")
                setSubSelectedTitle("Tally")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setProductadminMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("Dashboard")
                break;
            case "/log/dashboard":
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("Log Dashboard")
                setSubSelectedTitle("Log Dashboard")
                break;
            case "/log-report/user-log":
                setApprovalMenu(false)
                setDashboardMenu(false)
                setLogMonitoringMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("User Logs")
                setSubSelectedTitle("User Logs")
                break;
            case "/log-report/security-log":
                setDashboardMenu(false)
                setLogMonitoringMenu(true)
                setApprovalMenu(false)
                setProductadminMenu(false)
                setSelectedTitle("Security")
                setSubSelectedTitle("Security")
                break;
            case "/log-report/integration-log":
                setDashboardMenu(false)
                setApprovalMenu(false)
                setLogMonitoringMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("Integration")
                setSubSelectedTitle("Integration")
                break;
            case "/log-report/administration-log":
                setDashboardMenu(false)
                setLogMonitoringMenu(true)
                setApprovalMenu(false)
                setProductadminMenu(false)
                setSelectedTitle("Administration")
                setSubSelectedTitle("Administration")
                break;
            case "/approval/organisation":
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("Organisation Approval")
                setSubSelectedTitle("Organisation Approval")
                break;
            case "/approval/company-onboarding":
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("Company Approval")
                setSubSelectedTitle("Company Approval")
                break;
            case "/approval/vendor-bank":
                setDashboardMenu(false)
                setLogMonitoringMenu(false)
                setApprovalMenu(true)
                setProductadminMenu(false)
                setSelectedTitle("Vendor Bank Approval")
                setSubSelectedTitle("Vendor Bank Approval")
                break;
            default:
                setApprovalMenu(false)
                setProductadminMenu(false)
                setLogMonitoringMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;
        }
    }, [location])
    useEffect(() => {
        getPrivilegedUser()
    }, []);
    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }
    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }
    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);
    const ProductAdminSubmenu = ["Organisation", "Users", "KYC Onboarding", "Vendor Onboarding", "company", "VB Master", "Tally"].includes(selectedTitle)
    const ProductAdminApprovaalSubmenu = ["Organisation Approval", "Company Approval", "Vendor Bank Approval",].includes(selectedTitle)
    interface UserAccess {
        dashBoardMenu?: string[];
        productAdmin?: boolean;
        logMonitor?: boolean;
        dashBoard?: boolean;
        productSubmenu?: string[];
        logMonitoringSubMenu?: string[];

    }
    const [usersAccess, setUsersAccess] = useState<UserAccess | any>({
        // dashBoardMenu: ["Dashboard"],
        dashBoard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: false,
        logMonitor: false,
        // productSubmenu: ["Organisation", "Users", "KYC Onboarding", "Company", "VB Master"] ,
        productSubmenu: [],
        logMonitoringSubMenu: [],
    })

    const getPrivilegedUser = async () => {
        try {
            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {
                console.log(res.data.features);
                // const dashBoard = res.data.features.filter((item: any) => item.pbiTitle === 'Dashboard');
                // const dashBoardpbiNames = dashBoard.map((item: any) => item.pbiName);
                const FingertipAdmin = res.data.features.filter((item: any) => item.pbiTitle === 'Fingertip Admin');
                const logMonitoring = res.data.features.filter((item: any) => item.pbiTitle === 'Log Monitoring');
                const FingertipAdminpbiNames = FingertipAdmin.map((item: any) => item.pbiName);
                const logMonitoringpbiNames = logMonitoring.map((item: any) => item.pbiName);
                console.log(logMonitoring)
                setUsersAccess({
                    ...usersAccess,
                    // dashBoardMenu: dashBoardpbiNames,
                    // dashBoard: dashBoardpbiNames.length > 0,
                    logMonitor: logMonitoringpbiNames.length > 0,
                    productAdmin: FingertipAdminpbiNames.length > 0,
                    productSubmenu: FingertipAdminpbiNames,
                    logMonitoringSubMenu: logMonitoringpbiNames
                });
            }
        }
        catch (error: any) {
        }

    }

    return (
        <>
            <div className='product-admin expanded' key={usersAccess}>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>
                {/* ProductAdmin */}

                {usersAccess?.productAdmin &&
                    <div className='titles' onClick={() => {
                        setSelectedTitle("ProductAdmin")
                        setProductadminMenu(!productadminMenu)
                    }}>
                        <div className='icon'>
                            <AdminIcon style={{ fill: (selectedTitle === "ProductAdmin" || ProductAdminSubmenu) ? "orange" : undefined }} />
                        </div>
                        <div style={{ color: (selectedTitle === "ProductAdmin" || ProductAdminSubmenu) ? "orange" : undefined }}>{"Product Admin"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "ProductAdmin" || ProductAdminSubmenu) ? "orange" : undefined, transform: productadminMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }

                {
                    productadminMenu &&
                    <div className='productadmin-submenu'>
                        {usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Organisation") &&
                            <li style={{ color: subSelectedTitle === "Organisation" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Organisation")
                                //     navigate("/product-admin/organisation")
                                // }}
                                onClick={() => navConfirmation("Organisation", "/product-admin/organisation")}
                            >{"Organisation"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                onClick={() => {
                                    navConfirmation("Company", "/bank-admin/onboarding/company")
                                }}
                            >{"Company Onboarding"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Users") &&
                            <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Users")
                                //     navigate("/product-admin/user")
                                // }}
                                onClick={() => navConfirmation("Users", "/product-admin/user")}
                            >{"Users"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("KYC Onboarding") &&
                            <li style={{ color: subSelectedTitle === "KYC Onboarding" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("KYC Onboarding", "/product-admin/Customer-onboarding") }}>{"KYC Onboarding"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Vendor Onboarding") &&
                            <li style={{ color: subSelectedTitle === "Vendor Onboarding" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("Vendor Onboarding", "/product-admin/vendor-onboarding") }}>{"Vendor Onboarding"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("VB Master") &&
                            <li style={{ color: subSelectedTitle === "VB Master" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("VB Master", "/product-admin/vendor-bank-approval") }}>{"Vendor Bank Approval"}
                            </li>
                        }
                        {
                            usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Tally") &&
                            <li style={{ color: subSelectedTitle === "Tally" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("Tally", "/product-admin/tally-integration") }}>{"Tally"}
                            </li>
                        }

                    </div>
                }
                {
                    <div className='titles' onClick={() => {
                        setSelectedTitle("ProductAdminApproval")
                        setApprovalMenu(!approvalMenu)
                    }}>
                        <div className='icon'>
                            <Approval style={{ fill: (selectedTitle === "ProductAdminApproval" || ProductAdminApprovaalSubmenu) ? "orange" : undefined }} />
                        </div>
                        <div style={{ color: (selectedTitle === "ProductAdminApproval" || ProductAdminApprovaalSubmenu) ? "orange" : undefined }}>{"Approval"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "ProductAdminApproval" || ProductAdminApprovaalSubmenu) ? "orange" : undefined, transform: approvalMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }

                {
                    approvalMenu &&
                    <div className='productadmin-submenu'>
                        {/* {usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Organisation") && */}
                        <li style={{ color: subSelectedTitle === "Organisation Approval" ? "orange" : undefined }}
                            // onClick={() => {
                            //     setSelectedTitle("Organisation")
                            // navigate("/product-admin/organisation")
                            // }}
                            onClick={() => navConfirmation("Organisation Approval", "/approval/organisation")}
                        >{"Organisation"}
                        </li>
                        {/* } */}
                        {/* {
                            // usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company Approval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company Approval", "/approval/company-onboarding")}
                            >{"Company Onboarding"}
                            </li>
                        } */}
                        {
                            // usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Users") &&
                            <li style={{ color: subSelectedTitle === "Vendor Bank Approval" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Users")
                                //     navigate("/product-admin/user")
                                // }}
                                onClick={() => navConfirmation("Vendor Bank Approval", "/approval/vendor-bank")}
                            >{"Vendor Bank Approval"}
                            </li>
                        }


                    </div>
                }

                {usersAccess?.logMonitor &&
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Log Monitoring")
                        setLogMonitoringMenu(!logMonitoringMenu)
                    }}>
                        <div className='icon'>
                            <UserLogIcon style={{ fill: (selectedTitle === "Log Monitoring") ? "orange" : undefined }} />
                        </div>
                        <div style={{ color: (selectedTitle === "Log Monitoring") ? "orange" : undefined }}>{"Log Monitoring"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Log Monitoring") ? "orange" : undefined, transform: logMonitoringMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }
                {
                    logMonitoringMenu &&
                    <div className='productadmin-submenu'>
                        {usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Dashboard") &&
                            <li style={{ color: subSelectedTitle === "Log Dashboard" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Organisation")
                                //     navigate("/product-admin/organisation")
                                // }}
                                onClick={() => navConfirmation("Log Dashboard", "/log/dashboard")}
                            >{"Dashboard"}
                            </li>
                        }
                        {
                            usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("User Logs") &&
                            <li style={{ color: subSelectedTitle === "User Logs" ? "orange" : undefined }}
                                onClick={() => navConfirmation("User Logs", "/log-report/user-log")}
                            >{"User Logs"}
                            </li>
                        }
                        {
                            usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Security") &&
                            <li style={{ color: subSelectedTitle === "Security" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Security")
                                //     navigate("/product-admin/user")
                                // }}
                                onClick={() => navConfirmation("Security", "/log-report/security-log")}
                            >{"Security"}
                            </li>
                        }
                        {
                            usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Integration") &&
                            <li style={{ color: subSelectedTitle === "Integration" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("Integration", "/log-report/integration-log") }}>{"Integration"}
                            </li>
                        }
                        {
                            usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Administration") &&
                            <li style={{ color: subSelectedTitle === "Administration" ? "orange" : undefined }}
                                onClick={() => { navConfirmation("Administration", "/log-report/administration-log") }}>{"Administration"}
                            </li>
                        }
                    </div>
                }

                {/* Report */}
                {/* <div className='titles' onClick={() => { }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} /></div>
                </div> */}

            </div>

            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => { navConfirmation("dasboard", "/dashboard") }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>


                <div className='titles'>
                    <div className='icon'>
                        <AdminIcon style={{ fill: (selectedTitle === "ProductAdmin" || ProductAdminSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "ProductAdmin" || productadminMenu ? "orange" : undefined }}><div className={"heading-item"}><div>{"Fingertip Admin"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Organisation") &&
                                <li style={{ color: subSelectedTitle === "Organisation" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Organisation")
                                    //     navigate("/product-admin/organisation")
                                    // }}
                                    onClick={() => navConfirmation("Organisation", "/product-admin/organisation")}
                                >{"Organisation"}
                                </li>
                            }
                            {
                                usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Company") &&
                                <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Company", "/bank-admin/onboarding/company")}
                                >{"Company"}
                                </li>
                            }
                            {
                                usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Users") &&
                                <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Users")
                                    //     navigate("/product-admin/user")
                                    // }}
                                    onClick={() => navConfirmation("Users", "/product-admin/user")}
                                >{"Users"}
                                </li>
                            }
                            {
                                usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("KYC Onboarding") &&
                                <li style={{ color: subSelectedTitle === "KYC Onboarding" ? "orange" : undefined }}
                                    onClick={() => { navConfirmation("KYC Onboarding", "/product-admin/Customer-onboarding") }}>{"KYC Onboarding"}
                                </li>
                            }
                            {
                                usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("VB Master") &&
                                <li style={{ color: subSelectedTitle === "VB Master" ? "orange" : undefined }}
                                    onClick={() => { navConfirmation("VB Master", "/product-admin/vendor-bank-approval") }}>{"VB Master"}
                                </li>
                            }

                        </div>
                    </div>
                </div>
                <div className='titles'>
                    <div className='icon'>
                        <Approval style={{ fill: (selectedTitle === "ProductAdminApproval" || ProductAdminApprovaalSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "ProductAdminApproval" || ProductAdminApprovaalSubmenu ? "orange" : undefined }}><div className={"heading-item"}><div>{"Approval"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {/* {usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Organisation") && */}
                            <li style={{ color: subSelectedTitle === "Organisation Approval" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Organisation")
                                // navigate("/product-admin/organisation")
                                // }}
                                onClick={() => navConfirmation("Organisation Approval", "/approval/organisation")}
                            >{"Organisation"}
                            </li>
                            {/* } */}
                            {/* {
                            // usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company Approval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company Approval", "/approval/company-onboarding")}
                            >{"Company Onboarding"}
                            </li>
                        } */}
                            {
                                // usersAccess?.productSubmenu && usersAccess?.productSubmenu.includes("Users") &&
                                <li style={{ color: subSelectedTitle === "Vendor Bank Approval" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Users")
                                    //     navigate("/product-admin/user")
                                    // }}
                                    onClick={() => navConfirmation("Vendor Bank Approval", "/approval/vendor-bank")}
                                >{"Vendor Bank Approval"}
                                </li>
                            }


                        </div>
                    </div>
                </div>
                <div className='titles'>
                    <div className='icon'>
                        <UserLogIcon style={{ fill: (selectedTitle === "Log Monitoring") ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Log Monitoring" || logMonitoringMenu ? "orange" : undefined }}><div className={"heading-item"}><div>{"Log Monitoring"}</div></div></div>

                        <div className='productadmin-submenu'>
                            {usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Dashboard") &&
                                <li style={{ color: subSelectedTitle === "Log Dashboard" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Organisation")
                                    //     navigate("/product-admin/organisation")
                                    // }}
                                    onClick={() => navConfirmation("Log Dashboard", "/log/dashboard")}
                                >{"Dashboard"}
                                </li>
                            }
                            {
                                usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("User Logs") &&
                                <li style={{ color: subSelectedTitle === "User Logs" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("User Logs", "/log-report/user-log")}
                                >{"User Logs"}
                                </li>
                            }
                            {
                                usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Security") &&
                                <li style={{ color: subSelectedTitle === "Security" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Security")
                                    //     navigate("/product-admin/user")
                                    // }}
                                    onClick={() => navConfirmation("Security", "/log-report/security-log")}
                                >{"Security"}
                                </li>
                            }
                            {
                                usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Integration") &&
                                <li style={{ color: subSelectedTitle === "Integration" ? "orange" : undefined }}
                                    onClick={() => { navConfirmation("Integration", "/log-report/integration-log") }}>{"Integration"}
                                </li>
                            }
                            {
                                usersAccess?.logMonitoringSubMenu && usersAccess?.logMonitoringSubMenu.includes("Administration") &&
                                <li style={{ color: subSelectedTitle === "Administration" ? "orange" : undefined }}
                                    onClick={() => { navConfirmation("Administration", "/log-report/administration-log") }}>{"Administration"}
                                </li>
                            }
                        </div>

                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }

        </>
    );
};


//BankAdmin
export const BankAdmin = () => {
    const [selectedTitle, setSelectedTitle] = useState("");
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [onboardingMenu, setOnboardingMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        switch (location.pathname) {
            case "/bank-admin/onboarding/company":
                setOnboardingMenu(true)
                setSelectedTitle("Company")
                setSubSelectedTitle("Company")
                break;
            case "/dashboard":
                setOnboardingMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            case "/product-admin/user":
                setOnboardingMenu(true)
                setSelectedTitle("Users")
                setSubSelectedTitle("Users")
                break;
            default:
                setOnboardingMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;
        }
    }, [location])


    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    const OnboardingSubmenu = ["Company", "Users"].includes(selectedTitle)
    interface UserAccess {

        dashboard?: boolean;
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: string[];
        OrganisationSubMenu?: string[];
        ApprovalSubMenu?: string[];
        PayableSubMenu?: string[];
        OnboardingSubMenu?: string[];
        reports: boolean;
        virtualCard: boolean;
    }
    const [usersAccess, setUsersAccess] = useState<UserAccess>({
        virtualCard: true,
        dashboard: true,
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OnboardingSubMenu: [],
        reports: true
    })

    useEffect(() => {
        getPrivilegedUser()
    }, [])


    const getPrivilegedUser = async () => {
        try {
            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {
                // const dashBoard = res.data.features.filter((item: any) => item.pbiTitle === 'Dashboard');
                const Onboarding = res.data.features.filter((item: any) => item.pbiTitle === "Onboarding");
                // const dashBoardpbiNames = dashBoard.map((item: any) => item.pbiName);
                const OnboardingSubMenu = Onboarding.map((item: any) => item.pbiName);

                let update = {
                    ...usersAccess,
                    // dashBoardMenu: dashBoardpbiNames,
                    // dashBoard: dashBoardpbiNames.length > 0,
                    Onboarding: OnboardingSubMenu.length > 0,
                    OnboardingSubMenu: OnboardingSubMenu,
                    // ApprovalSubMenu: ApprovalpbiNames,
                    // PayableSubMenu: PayablepbiNames,
                }
                console.log(update, "jhsdgvfhsdbvfjsdbvbjhsdabvfjhbesafhehavhbehfqwjl")
                setUsersAccess(update)
            }
        }
        catch (error: any) {
        }

    }
    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: selectedTitle === "Dashboard" ? "orange" : undefined }}>{"Dashboard"}</div>
                </div>

                {/* Onboarding */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Onboarding")
                    setOnboardingMenu(!onboardingMenu)
                }}>
                    <div className='icon'>
                        <Onboarding style={{ fill: (selectedTitle === "Onboarding" || OnboardingSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Onboarding" || OnboardingSubmenu) ? "orange" : undefined }}>{"Onboarding"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Onboarding" || OnboardingSubmenu) ? "orange" : undefined, transform: onboardingMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    onboardingMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company", "/bank-admin/onboarding/company")}
                            >{"Company"}
                            </li>
                        }
                        {
                            usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Users") &&
                            <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                // onClick={() => {
                                //     setSelectedTitle("Users")
                                //     navigate("/product-admin/user")
                                // }}
                                onClick={() => navConfirmation("Users", "/product-admin/user")}
                            >{"Users"}
                            </li>
                        }
                    </div>
                }

                {/* Report */}
                {/* <div className='titles' onClick={() => { setSelectedTitle("Reports") }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} /></div>
                </div> */}
            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                </div>

                <div className='titles'>
                    <div className='icon'>
                        <Onboarding style={{ fill: (selectedTitle === "Onboarding" || OnboardingSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Onboarding" || OnboardingSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Onboarding"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Company") &&
                                <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Company", "/bank-admin/onboarding/company")}
                                >{"Company"}
                                </li>
                            }
                            {
                                usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Users") &&
                                <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                    // onClick={() => {
                                    //     setSelectedTitle("Users")
                                    //     navigate("/product-admin/user")
                                    // }}
                                    onClick={() => navConfirmation("Users", "/product-admin/user")}
                                >{"Users"}
                                </li>
                            }
                        </div>
                    </div>
                </div>

            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};


// customerMaker
export const CustomerMaker = () => {
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [selectedTitle, setSelectedTitle] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [onboardingMenu, setOnboardingMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (location.pathname) {

            case "/organisation/kycUpload":
                setOnboardingMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("KYC")
                setSubSelectedTitle("KYC")
                break;
            case "/bank-admin/onboarding/company":
                setOnboardingMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Company")
                setSubSelectedTitle("Company")
                break;
            case "/dashboard":
                setOnboardingMenu(false)
                setDashboardMenu(true)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            default:
                setOnboardingMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;
        }
    }, [location])

    useEffect(() => {
        getPrivilegedUser()
    }, [])
    interface UserAccess {
        dashBoardMenu?: string[];
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: boolean;
        OrganisationSubMenu?: boolean;
        ApprovalSubMenu?: boolean;
        PayableSubMenu?: boolean;
        OnboardingSubMenu?: string[];
        reports: boolean;
        virtualCard: boolean;
    }
    const [usersAccess, setUsersAccess] = useState({
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: true,
        ApprovalSubMenu: true,
        productSubmenu: true,
        PayableSubMenu: true,
        OnboardingSubMenu: ["KYC", "Company"],
        reports: true
    })

    const getPrivilegedUser = async () => {
        try {
            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {
                console.log(res.data.features);

                // const dashBoard = res.data.features.filter((item: any) => item.pbiTitle === 'Dashboard');
                const Customermaker = res.data.features.filter((item: any) => item.pbiTitle === "Customer Maker");
                // const dashBoardpbiNames = dashBoard.map((item: any) => item.pbiName);
                const CustomermakerpbiNames = Customermaker.map((item: any) => item.pbiName);

                let update = {
                    ...usersAccess,
                    // dashBoardMenu: dashBoardpbiNames,
                    // dashBoard: dashBoardpbiNames.length > 0,
                    Onboarding: CustomermakerpbiNames.length > 0,
                    OnboardingSubMenu: CustomermakerpbiNames,
                    // ApprovalSubMenu: ApprovalpbiNames,
                    // PayableSubMenu: PayablepbiNames,
                }
                setUsersAccess(update)
            }
        }
        catch (error: any) {
        }

    }


    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    const onBoardingList = ["Company", "KYC"];
    const onBoardingSubmenu = onBoardingList.includes(selectedTitle);





    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                {/* Organization */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Customer Maker")
                    setOnboardingMenu(!onboardingMenu)
                }}>
                    <div className='icon'>
                        <CustomerMakerIcon style={{ fill: (selectedTitle === "Customer Maker" || onBoardingSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Customer Maker" || onBoardingSubmenu) ? "orange" : undefined }}>{"Customer Maker"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Customer Maker" || onBoardingSubmenu) ? "orange" : undefined, transform: onboardingMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    onboardingMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company", "/bank-admin/onboarding/company")}
                            >{"Company"}
                            </li>
                        }
                        {
                            usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("KYC") &&
                            <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                            >{"KYC"}
                            </li>
                        }
                    </div>
                }

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>


                <div className='titles'>
                    <div className='icon'>
                        <CustomerMakerIcon style={{ fill: (selectedTitle === "Customer Maker" || onBoardingSubmenu) ? "orange" : undefined }} />
                        {/* <Organisation style={{ fill: (selectedTitle === "Onboarding" || onBoardingSubmenu) ? "orange" : undefined }} /> */}
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Customer Maker" || onBoardingSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Customer Maker"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("Company") &&
                                <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Company", "/bank-admin/onboarding/company")}
                                >{"Company"}
                                </li>
                            }
                            {
                                usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("KYC") &&
                                <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                                >{"KYC"}
                                </li>
                            }
                        </div>
                    </div>

                </div>
            </div>

            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

//CustomerAuthorizer
export const CustomerAuthorizer = () => {
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [selectedTitle, setSelectedTitle] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [onboardingMenu, setOnboardingMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (location.pathname) {

            // case "/organisation/kycUpload":
            //     setOnboardingMenu(true)
            //     setDashboardMenu(false)
            //     setSelectedTitle("KYC")
            //     break;
            case "/product-admin/Customer-onboarding":
                setOnboardingMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("KYC Onboarding")
                setSelectedTitle("KYC Onboarding")
                break;
            case "/dashboard":
                setOnboardingMenu(false)
                setDashboardMenu(true)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            default:
                setOnboardingMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;
        }
    }, [location])

    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    const onBoardingList = ["KYC Onboarding"];
    const onBoardingSubmenu = onBoardingList.includes(selectedTitle);


    interface UserAccess {
        dashBoardMenu?: string[];
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: boolean;
        OrganisationSubMenu?: boolean;
        ApprovalSubMenu?: boolean;
        PayableSubMenu?: boolean;
        OnboardingSubMenu?: string[];
        reports: boolean;
        virtualCard: boolean;
    }
    // const usersAccess: UserAccess[] = [{
    //     virtualCard: true,
    //     dashBoardMenu: ["Dashboard"],
    //     productAdmin: true,
    //     Organisation: true,
    //     Payable: true,
    //     Approval: true,
    //     Onboarding: true,
    //     OrganisationSubMenu: true,
    //     ApprovalSubMenu: true,
    //     productSubmenu: true,
    //     PayableSubMenu: true,
    //     OnboardingSubMenu: ["KYC Onboarding"],
    //     reports: true
    // }
    // ]

    const [usersAccess, setUsersAccess] = useState<UserAccess>({
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: true,
        ApprovalSubMenu: true,
        productSubmenu: true,
        PayableSubMenu: true,
        OnboardingSubMenu: [],
        reports: true
    })

    useEffect(() => {
        getPrivilegedUser()
    }, [])


    const getPrivilegedUser = async () => {
        try {
            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {
                console.log(res.data.features);
                const Customerauthorizer = res.data.features.filter((item: any) => item.pbiTitle === "Customer Authorizer");
                const CustomerauthorizerpbiNames = Customerauthorizer.map((item: any) => item.pbiName);

                let update = {
                    ...usersAccess,
                    // dashBoardMenu: dashBoardpbiNames,
                    // dashBoard: dashBoardpbiNames.length > 0,
                    Onboarding: CustomerauthorizerpbiNames.length > 0,
                    OnboardingSubMenu: CustomerauthorizerpbiNames,
                    // ApprovalSubMenu: ApprovalpbiNames,
                    // PayableSubMenu: PayablepbiNames,
                }
                setUsersAccess(update)
            }
        }
        catch (error: any) {
        }

    }
    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                {/* Organization */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Customer Authorizer")
                    setOnboardingMenu(!onboardingMenu)
                }}>
                    <div className='icon'>
                        <CustomerAuthorizerIcon style={{ fill: (selectedTitle === "Customer Authorizer" || onBoardingSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Customer Authorizer" || onBoardingSubmenu) ? "orange" : undefined }}>{"Customer Authorizer"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Customer Authorizer" || onBoardingSubmenu) ? "orange" : undefined, transform: onboardingMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    onboardingMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("KYC Onboarding") &&
                            <li style={{ color: subSelectedTitle === "KYC Onboarding" ? "orange" : undefined }}
                                onClick={() => navConfirmation("KYC Onboarding", "/product-admin/Customer-onboarding")}
                            >{"KYC Onboarding"}
                            </li>
                        }

                    </div>
                }

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>


                <div className='titles'>
                    <div className='icon'>
                        <CustomerAuthorizerIcon style={{ fill: (selectedTitle === "Customer Authorizer" || onBoardingSubmenu) ? "orange" : undefined }} />
                        {/* <Organisation style={{ fill: (selectedTitle === "Onboarding" || onBoardingSubmenu) ? "orange" : undefined }} /> */}
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Customer Authorizer" || onBoardingSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Customer Authorizer"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.OnboardingSubMenu && usersAccess?.OnboardingSubMenu.includes("KYC Onboarding") &&
                                <li style={{ color: subSelectedTitle === "KYC Onboarding" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("KYC Onboarding", "/product-admin/Customer-onboarding")}
                                >{"KYC Onboarding"}
                                </li>
                            }
                        </div>
                    </div>

                </div>
            </div>

            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

// CompanyAdminUnApproved
export const CompanyAdminUnApproved = () => {
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [organisationMenu, setOrganisationMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (location.pathname) {

            case "/organisation/kycUpload":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("KYC")
                setSubSelectedTitle("KYC")
                break;
            case "/organisation/company":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Company")
                setSubSelectedTitle("Company")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setOrganisationMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            default:
                setOrganisationMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;
        }
    }, [location])

    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    const OrganizationList = ["Company", "KYC"];
    const OrganisationSubmenu = OrganizationList.includes(selectedTitle);


    interface UserAccess {
        dashBoardMenu?: string[];
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        OrganisationSubMenu?: string[];
        ApprovalSubMenu?: boolean;
        PayableSubMenu?: boolean;
        OnboardingSubMenu?: boolean;
        reports: boolean;
        virtualCard: boolean;
    }
    const [usersAccess, setUsersAccess] = useState<UserAccess | any>({
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: ["KYC", "Company"],
        ApprovalSubMenu: true,
        PayableSubMenu: true,
        OnboardingSubMenu: true,
        reports: true
    })



    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                {/* <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div> */}

                {/* Organization */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Organisation")
                    setOrganisationMenu(!organisationMenu)
                }}>
                    <div className='icon'>
                        <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}>{"Organisation"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined, transform: organisationMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    organisationMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess.OrganisationSubMenu && usersAccess.OrganisationSubMenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company", "/organisation/company")}
                            >{"Company"}
                            </li>
                        }
                        {
                            usersAccess.OrganisationSubMenu && usersAccess.OrganisationSubMenu.includes("KYC") &&
                            <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                            >{"KYC"}
                            </li>
                        }
                    </div>
                }

            </div>

            <div className='product-admin collapsed'>
                {/* <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div> */}


                <div className='titles'>
                    <div className='icon'>
                        <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Organisation"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess.OrganisationSubMenu && usersAccess.OrganisationSubMenu.includes("Company") &&
                                <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Company", "/organisation/company")}
                                >{"Company"}
                                </li>
                            }
                            {
                                usersAccess.OrganisationSubMenu && usersAccess.OrganisationSubMenu.includes("KYC") &&
                                <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                                >{"KYC"}
                                </li>
                            }
                        </div>
                    </div>

                </div>
            </div>

            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

//CompanyAdminApproved
export const CompanyAdminApproved = ({ currentPaymentActor, navbarTitle }: props) => {

    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [organisationMenu, setOrganisationMenu] = useState(false);
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [reportsMenu, setReportsMenu] = useState(false);
    const [approvalMenu, setApprovalMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [payableMenu, setPayableMenu] = useState(false);
    const [tempNav, setTempNav] = useState("");
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();


    useEffect(() => {
        switch (location.pathname) {
            case "/organisation/bank":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Bank Details")
                setSubSelectedTitle("Bank Details")
                break;
            case "/organisation/card":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Card Details")
                setSubSelectedTitle("Card Details")
                break;
            case "/organisation/vendor":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Vendor Details")
                setSubSelectedTitle("Vendor Details")
                break;
            case "/organisation/customer":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("Customer Details")
                setSubSelectedTitle("Customer Details")
                break;
            case "/virtualcard":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Virtual Card Details")
                setSubSelectedTitle("Virtual Card Details")
                break;
            case "/organisation/kycUpload":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("KYC")
                setSubSelectedTitle("KYC")
                break;
            case "/organisation/company":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Company")
                setSubSelectedTitle("Company")
                break;
            case "/product-admin/user":
                setOrganisationMenu(true)
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Users")
                setSubSelectedTitle("Users")
                break;
            case "/approval/bank":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Bank")
                setSubSelectedTitle("Bank")
                break;
            case "/virtualcardApproval":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Virtual Card")
                setSubSelectedTitle("Virtual Card")
                break;
            case "/gst/approval":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("GST Payment")
                setSubSelectedTitle("GST Payment")
                break;
            case "/direct-tax/approval":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Direct Tax Payment")
                setSubSelectedTitle("Direct Tax Payment")
                break;
            case "/excise-payment/approval":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Excise Payment")
                setSubSelectedTitle("Excise Payment")
                break;
            case "/approval/vendor":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("VendorApproval")
                setSubSelectedTitle("VendorApproval")
                break;
            case "/approval/customer":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Customer")
                setSubSelectedTitle("Customer")
                break;
            case "/approval/utility":
                setPayableMenu(false)
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Utility Payment")
                setSubSelectedTitle("Utility Payment")
                break;
            case "/approval/card":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Card")
                setSubSelectedTitle("Card")
                break;
            case "/payable/approver/vendor":
                setOrganisationMenu(false)
                setApprovalMenu(true)
                setDashboardMenu(false)
                setPayableMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Vendor Payment")
                setSubSelectedTitle("Vendor Payment")
                break;
            case "/payable/vendor":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setReportsMenu(false)
                setSelectedTitle("PayableVendor")
                setSubSelectedTitle("PayableVendor")
                break;
            case "/gst":
                setOrganisationMenu(false)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setPayableMenu(true)
                setReportsMenu(false)
                setSelectedTitle("GST")
                setSubSelectedTitle("GST")
                break;
            case "/payable/utility":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Utility")
                setSubSelectedTitle("Utility")
                break;
            case "/payable/direct-tax":
                setOrganisationMenu(false)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setPayableMenu(true)
                setReportsMenu(false)
                setSelectedTitle("Direct Tax Payable")
                setSubSelectedTitle("Direct Tax Payable")
                break;
            case "/payable/excise-payment":
                setOrganisationMenu(false)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setPayableMenu(true)
                setReportsMenu(false)
                setSelectedTitle("excisePayment")
                setSubSelectedTitle("excisePayment")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setReportsMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            case "/reports/masterdetails":
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setReportsMenu(true)
                setSelectedTitle("Master Details")
                setSubSelectedTitle("Master Details")
                break;
            case "/reports/vendorpayable":
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setReportsMenu(true)
                setSelectedTitle("Vendor Payable")
                setSubSelectedTitle("Vendor Payable")
                break;
            default:
                setOrganisationMenu(false)
                setReportsMenu(false)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setPayableMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;

        }
    }, [location]);

    useEffect(() => {
        getPrivilegedUser()
    }, []);

    interface UserAccess {
        dashBoard: boolean;
        Organisation: boolean;
        Approval: boolean;
        Payable: boolean;
        Reports: boolean;
        dashBoardMenu?: string[];
        OrganisationSubMenu?: string[];
        ApprovalSubMenu?: string[];
        PayableSubMenu?: string[];
        ReportsSubMenu?: string[];
    }

    const [usersAccess, setUsersAccess] = useState<UserAccess>({
        dashBoard: true,
        Organisation: false,
        Approval: false,
        Payable: false,
        Reports: false,
        dashBoardMenu: ["Dashboard"],
        OrganisationSubMenu: [],
        ApprovalSubMenu: [],
        PayableSubMenu: [],
        ReportsSubMenu: [],
        // OrganisationSubMenu: ["Bank", "KYC", "Company", "Card", "Customer", "Vendor", "Users", "Virtualcard"],
        // ApprovalSubMenu: ["Bank Approval", "Virtual Card", "GSTApproval", "exciseApproval", "CardApproval", "UtilityPayment", "VendorApproval", "DirectTaxApproval", "Customerapproval"],
        // PayableSubMenu: ["PayableVendor", "GST", "excisePayment", "DirectTax"],
    })



    const getPrivilegedUser = async () => {
        try {
            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {
                console.log(res.data.features);

                // const dashBoard = res.data.features.filter((item: any) => item.pbiTitle === 'Dashboard');
                const Organisation = res.data.features.filter((item: any) => item.pbiTitle === "Organisation");
                const Approval = res.data.features.filter((item: any) => item.pbiTitle === "Approval");
                const Payable = res.data.features.filter((item: any) => item.pbiTitle === "Payable");
                const Reports = res.data.features.filter((item: any) => item.pbiTitle === "Reports");
                // const dashBoardpbiNames = dashBoard.map((item: any) => item.pbiName);
                const OrganisationpbiNames = Organisation.map((item: any) => item.pbiName);
                const ApprovalpbiNames = Approval.map((item: any) => item.pbiName);
                const PayablepbiNames = Payable.map((item: any) => item.pbiName);
                const ReportspbiNames = Reports.map((item: any) => item.pbiName);
                console.log(ApprovalpbiNames, '-------------------------------ApprovalpbiNames---->')
                let update = {
                    ...usersAccess,
                    // dashBoardMenu: dashBoardpbiNames,
                    // dashBoard: dashBoardpbiNames.length > 0,
                    Organisation: OrganisationpbiNames.length > 0,
                    Approval: ApprovalpbiNames.length > 0,
                    Payable: PayablepbiNames.length > 0,
                    Reports: ReportspbiNames.length > 0,
                    OrganisationSubMenu: OrganisationpbiNames,
                    ApprovalSubMenu: ApprovalpbiNames,
                    PayableSubMenu: PayablepbiNames,
                    ReportsSubMenu: ReportspbiNames,
                }
                setUsersAccess(update)
            }
        }
        catch (error: any) {
        }

    }



    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard") ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard") ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                {/* Organization */}
                {
                    usersAccess.Organisation &&

                    <div className='titles' onClick={() => {
                        setSelectedTitle("Organisation")
                        setOrganisationMenu(!organisationMenu)
                    }}>
                        <div className='icon'>
                            <Organisation style={{ fill: (selectedTitle === "Organisation") ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Organisation") ? "orange" : undefined }}>{"Organisation"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Organisation") ? "orange" : undefined, transform: organisationMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }

                {
                    organisationMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Company") &&
                            <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Company", "/organisation/company")}
                            >{"Company"}
                            </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Users") &&
                            <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Users", "/product-admin/user")}
                            >{"Users"}
                            </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Bank Details") &&
                            <li style={{ color: subSelectedTitle === "Bank Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Bank Details", "/organisation/bank")}
                            >{"Bank Details"}
                            </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Card Details") &&
                            <li style={{ color: subSelectedTitle === "Card Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Card Details", "/organisation/card")}
                            >{"Card Details"}
                            </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Vendor Details") &&
                            <li style={{ color: subSelectedTitle === "Vendor Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Vendor Details", "/organisation/vendor")}
                            >{"Vendor Details"}
                            </li>
                        }
                        {usersAccess?.OrganisationSubMenu &&
                            usersAccess?.OrganisationSubMenu.includes("Customer Details") &&
                            <li style={{ color: subSelectedTitle === "Customer Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Details"} </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Virtual Card Details") &&
                            <li style={{ color: subSelectedTitle === "Virtual Card Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Virtual Card Details", "/virtualcard")}
                            >{"Virtual Card Details"}
                            </li>
                        }
                        {
                            usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("KYC") &&
                            <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                            >{"KYC"}
                            </li>
                        }
                    </div>
                }

                {/* Approval */}
                {
                    usersAccess.Approval &&
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Approval")
                        setApprovalMenu(!approvalMenu)
                    }}>
                        <div className='icon'>
                            <Approval style={{ fill: (selectedTitle === "Approval") ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Approval") ? "orange" : undefined }}>{"Approval"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Approval") ? "orange" : undefined, transform: approvalMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }
                {
                    approvalMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Vendor Payment") &&
                            <li style={{ color: subSelectedTitle === "Vendor Payment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Vendor Payment", "/payable/vendor")}
                            >{"Vendor Payment"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("GST Payment") &&
                            <li style={{ color: subSelectedTitle === "GST Payment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("GST Payment", "/gst/approval")}
                            >{"GST Payment"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Direct Tax Payment") &&
                            <li style={{ color: subSelectedTitle === "Direct Tax Payment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Direct Tax Payment", "/direct-tax/approval")}
                            >{"Direct Tax"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Excise Payment") &&
                            <li style={{ color: subSelectedTitle === "Excise Payment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Excise Payment", "/excise-payment/approval")}
                            >{"Excise Payment"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Utility Payment") &&
                            <li style={{ color: subSelectedTitle === "Utility Payment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Utility Payment", "/approval/utility")}
                            >{"Utility Payment"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Bank") &&
                            <li style={{ color: subSelectedTitle === "Bank" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Bank", "/approval/bank")}
                            >{"Bank"}</li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Card") &&
                            <li style={{ color: subSelectedTitle === "Card" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Card", "/approval/card")}
                            >{"Card"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Vendor") &&
                            <li style={{ color: subSelectedTitle === "VendorApproval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                            >{"Vendor"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Virtual Card") &&
                            <li style={{ color: subSelectedTitle === "Virtual Card" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                            >{"Virtual Card"}
                            </li>
                        }
                        {
                            usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Customer") &&
                            <li style={{ color: subSelectedTitle === "Customer" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Customer", "/approval/customer")}
                            >{"Customer"}
                            </li>
                        }
                    </div>
                }
                {
                    usersAccess.Payable &&
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Payable")
                        setPayableMenu(!payableMenu)
                    }}>
                        <div className='icon'>
                            <Payable style={{ fill: (selectedTitle === "Payable") ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Payable") ? "orange" : undefined }}>{"Payable"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Payable") ? "orange" : undefined, transform: payableMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }
                {
                    payableMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Vendor") &&
                            <li style={{ color: subSelectedTitle === "PayableVendor" ? "orange" : undefined }}
                                onClick={() => navConfirmation("PayableVendor", "/payable/vendor")}
                            >{"Vendor"}
                            </li>
                        }
                        {
                            usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("GST") &&
                            <li style={{ color: subSelectedTitle === "GST" ? "orange" : undefined }}
                                onClick={() => navConfirmation("GST", "/gst")}
                            >{"GST"}
                            </li>
                        }
                        {
                            usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Direct Tax") &&
                            <li style={{ color: subSelectedTitle === "Direct Tax Payable" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Direct Tax Payable", "/payable/direct-tax")}
                            >{"Direct Tax"}
                            </li>
                        }
                        {
                            usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Excise") &&
                            <li style={{ color: subSelectedTitle === "excisePayment" ? "orange" : undefined }}
                                onClick={() => navConfirmation("excisePayment", "/payable/excise-payment")}
                            >{"Excise"}
                            </li>
                        }
                        {
                            usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Utility") &&
                            <li style={{ color: subSelectedTitle === "Utility" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Utility", "/payable/utility")}
                            >{"Utility"}
                            </li>
                        }
                    </div>
                }
                {
                    usersAccess.Reports &&
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Reports")
                        setReportsMenu(!reportsMenu)
                    }}>
                        <div className='icon'>
                            <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Reports") ? "orange" : undefined }}>{"Reports"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Reports") ? "orange" : undefined, transform: reportsMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                }
                {
                    reportsMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess?.ReportsSubMenu && usersAccess?.ReportsSubMenu.includes("Master Details") &&
                            <li style={{ color: subSelectedTitle === "Master Details" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Master Details", "/reports/masterdetails")}
                            >{"Master Details"}
                            </li>
                        }
                        {
                            usersAccess?.ReportsSubMenu && usersAccess?.ReportsSubMenu.includes("Vendor Payable") &&
                            <li style={{ color: subSelectedTitle === "Vendor Payable" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Vendor Payable", "/reports/vendorpayable")}
                            >{"Vendor Payable"}
                            </li>
                        }

                    </div>
                }

                {/* Report */}
                {/* <div className='titles' onClick={() => { setSelectedTitle("Reports") }}>
                    <div className='icon'>
                        
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} 
                      /></div>
                </div> */}
                {/* <div className='titles' onClick={() => {
                    setSelectedTitle("Reports")
                    setReportsMenu(!reportsMenu)
                }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Reports") ? "orange" : undefined }}
                        onClick={() => navConfirmation("Reports", "/reports")}
                    >{"Reports"}</div>
                </div> */}
            </div>


            <div className='product-admin collapsed'>

                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>


                <div className='titles'>
                    <div className='icon'>
                        <Organisation style={{ fill: (selectedTitle === "Organisation") ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Organisation") ? "orange" : undefined }}><div className={"heading-item"}><div>{"Organisation"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Company") &&
                                <li style={{ color: subSelectedTitle === "Company" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Company", "/organisation/company")}
                                >{"Company"}
                                </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Users") &&
                                <li style={{ color: subSelectedTitle === "Users" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Users", "/product-admin/user")}
                                >{"Users"}
                                </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Bank Details") &&
                                <li style={{ color: subSelectedTitle === "Bank Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank Details", "/organisation/bank")}
                                >{"Bank Details"}
                                </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Card Details") &&
                                <li style={{ color: subSelectedTitle === "Card Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Card Details", "/organisation/card")}
                                >{"Card Details"}
                                </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Vendor Details") &&
                                <li style={{ color: subSelectedTitle === "Vendor Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Vendor Details", "/organisation/vendor")}
                                >{"Vendor Details"}
                                </li>
                            }
                            {usersAccess?.OrganisationSubMenu &&
                                usersAccess?.OrganisationSubMenu.includes("Customer Details") &&
                                <li style={{ color: subSelectedTitle === "Customer Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Details"} </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("Virtual Card Details") &&
                                <li style={{ color: subSelectedTitle === "Virtual Card Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtual Card Details", "/virtualcard")}
                                >{"Virtual Card Details"}
                                </li>
                            }
                            {
                                usersAccess?.OrganisationSubMenu && usersAccess?.OrganisationSubMenu.includes("KYC") &&
                                <li style={{ color: subSelectedTitle === "KYC" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("KYC", "/organisation/kycUpload")}
                                >{"KYC"}
                                </li>
                            }
                        </div>
                    </div>

                </div>

                <div className='titles'>
                    <div className='icon'>
                        <Approval style={{ fill: (selectedTitle === "Approval") ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Approval") ? "orange" : undefined }}><div className={"heading-item"}><div>{"Approval"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("GST Payment") &&
                                <li style={{ color: subSelectedTitle === "GST Payment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("GST Payment", "/gst/approval")}
                                >{"GST Payment"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Direct Tax Payment") &&
                                <li style={{ color: subSelectedTitle === "Direct Tax Payment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Direct Tax Payment", "/direct-tax/approval")}
                                >{"Direct Tax"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Excise Payment") &&
                                <li style={{ color: subSelectedTitle === "Excise Payment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Excise Payment", "/excise-payment/approval")}
                                >{"Excise Payment"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Utility Payment") &&
                                <li style={{ color: subSelectedTitle === "Utility Payment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Utility Payment", "/approval/utility")}
                                >{"Utility Payment"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Bank") &&
                                <li style={{ color: subSelectedTitle === "Bank" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank", "/approval/bank")}
                                >{"Bank"}</li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Card") &&
                                <li style={{ color: subSelectedTitle === "Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Card", "/approval/card")}
                                >{"Card"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Vendor") &&
                                <li style={{ color: subSelectedTitle === "VendorApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                                >{"Vendor"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Virtual Card") &&
                                <li style={{ color: subSelectedTitle === "Virtual Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                                >{"Virtual Card"}
                                </li>
                            }
                            {
                                usersAccess?.ApprovalSubMenu && usersAccess?.ApprovalSubMenu.includes("Customer") &&
                                <li style={{ color: subSelectedTitle === "Customer" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Customer", "/approval/customer")}
                                >{"Customer"}
                                </li>
                            }
                        </div>
                    </div>
                </div>

                <div className='titles'>
                    <div className='icon'>
                        <Payable style={{ fill: (selectedTitle === "Payable") ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Payable") ? "orange" : undefined }}><div className={"heading-item"}><div>{"Payable"}</div></div></div>

                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Vendor") &&
                                <li style={{ color: subSelectedTitle === "PayableVendor" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("PayableVendor", "/payable/vendor")}
                                >{"Vendor"}
                                </li>
                            }
                            {
                                usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("GST") &&
                                <li style={{ color: subSelectedTitle === "GST" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("GST", "/gst")}
                                >{"GST"}
                                </li>
                            }
                            {
                                usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Direct Tax") &&
                                <li style={{ color: subSelectedTitle === "Direct Tax" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Direct Tax", "/payable/direct-tax")}
                                >{"Direct Tax"}
                                </li>
                            }
                            {
                                usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Excise") &&
                                <li style={{ color: subSelectedTitle === "Excise" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Excise", "/payable/excise-payment")}
                                >{"Excise"}
                                </li>
                            }
                            {
                                usersAccess?.PayableSubMenu && usersAccess?.PayableSubMenu.includes("Utility") &&
                                <li style={{ color: subSelectedTitle === "Utility" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Utility", "/payable/utility")}
                                >{"Utility"}
                                </li>
                            }
                        </div>
                    </div>

                </div>
                <div className='titles'>
                    <div className='icon'>
                        <ReportIcon style={{ fill: (selectedTitle === "Reports") ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Reports") ? "orange" : undefined }}><div className={"heading-item"}><div>{"Payable"}</div></div></div>

                        <div className='productadmin-submenu'>
                            {
                                usersAccess?.ReportsSubMenu && usersAccess?.ReportsSubMenu.includes("Master Details") &&
                                <li style={{ color: subSelectedTitle === "Master Details" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Master Details", "/reports/masterdetails")}
                                >{"Master Details"}
                                </li>
                            }
                            {
                                usersAccess?.ReportsSubMenu && usersAccess?.ReportsSubMenu.includes("Vendor Payable") &&
                                <li style={{ color: subSelectedTitle === "Vendor Payable" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Vendor Payable", "/reports/vendorpayable")}
                                >{"Vendor Payable"}
                                </li>
                            }

                        </div>

                    </div>

                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

//CompanyMasterDataCreater
export const CompanyMasterDataCreater = () => {
    const isFormModified = useSelector((state: any) => state.isFormModified);

    const [selectedTitle, setSelectedTitle] = useState("");
    const [organisationMenu, setOrganisationMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [tempNav, setTempNav] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (location.pathname) {
            case "/organisation/bank":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Bank")
                break;
            case "/organisation/card":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Card")
                break;
            case "/organisation/vendor":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Vendor")
                break;
            case "/organisation/customer":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Customer")
                break;
            case "/virtualcard":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Virtualcard")
                break;
            case "/user-profile":
                setOrganisationMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setOrganisationMenu(false)
                setSelectedTitle("Dashboard")
                break;
            default:
                setOrganisationMenu(false)
                setDashboardMenu(false)
                setSelectedTitle("")
                break;
        }
    }, [location])



    const OrganizationList = ["Bank", "Card", "Vendor", "Customer", "Virtualcard"];
    const OrganisationSubmenu = OrganizationList.includes(selectedTitle);
    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);
    interface UserAccess {

        dashBoardMenu?: string[];
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: boolean;
        OrganisationSubMenu?: string[];
        ApprovalSubMenu?: boolean;
        PayableSubMenu?: boolean;
        OnboardingSubMenu?: boolean;
        reports: boolean;
        virtualCard: boolean;
    }
    const usersAccess: UserAccess[] = [{
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: ["Bank", "Card", "Customer", "Vendor", "Virtualcard"],
        ApprovalSubMenu: true,
        productSubmenu: true,
        PayableSubMenu: true,
        OnboardingSubMenu: true,
        reports: true
    }
    ]

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                {/* Organization */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Organisation")
                    setOrganisationMenu(!organisationMenu)
                }}>
                    <div className='icon'>
                        <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}>{"Organisation"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined, transform: organisationMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    organisationMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Bank") &&
                            <li style={{ color: selectedTitle === "Bank" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Bank", "/organisation/bank")}
                            >{"Bank Creation"}
                            </li>
                        }
                        {
                            usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Card") &&
                            <li style={{ color: selectedTitle === "Card" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Card", "/organisation/card")}
                            >{"Card Creation"}
                            </li>
                        }
                        {
                            usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Virtualcard") &&
                            <li style={{ color: selectedTitle === "Virtualcard" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Virtualcard", "/virtualcard")}
                            >{"Virtual Card Creation"}
                            </li>
                        }
                        {
                            usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Vendor") &&
                            <li style={{ color: selectedTitle === "Vendor" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Vendor", "/organisation/vendor")}
                            >{"Vendor Creation"}
                            </li>
                        }
                        {usersAccess[0].OrganisationSubMenu &&
                            usersAccess[0].OrganisationSubMenu.includes("Customer") &&
                            <li style={{ color: selectedTitle === "Customer" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Creation"} </li>}

                    </div>
                }

                {/* Report */}
                {/* <div className='titles' onClick={() => { setSelectedTitle("Reports") }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} /></div>
                </div> */}

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>



                <div className='titles'>
                    <div className='icon'>
                        <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Organisation"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Bank") &&
                                <li style={{ color: selectedTitle === "Bank" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank", "/organisation/bank")}
                                >{"Bank Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Card") &&
                                <li style={{ color: selectedTitle === "Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Card", "/organisation/card")}
                                >{"Card Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Virtualcard") &&
                                <li style={{ color: selectedTitle === "Virtualcard" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtualcard", "/virtualcard")}
                                >{"Virtual Card Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Vendor") &&
                                <li style={{ color: selectedTitle === "Vendor" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Vendor", "/organisation/vendor")}
                                >{"Vendor Creation"}
                                </li>
                            }
                            {usersAccess[0].OrganisationSubMenu &&
                                usersAccess[0].OrganisationSubMenu.includes("Customer") &&
                                <li style={{ color: selectedTitle === "Customer" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Creation"} </li>}

                        </div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

//CompanyMasterDataAuthorizer
export const CompanyMasterDataAuthorizer = () => {
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [approvalMenu, setApprovalMenu] = useState(false);
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (location.pathname) {
            case "/approval/bank":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSelectedTitle("Bank Approval")
                setSubSelectedTitle("Bank Approval")
                break;
            case "/virtualcardApproval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("Virtual Card")
                setSelectedTitle("Virtual Card")

                break;
            case "/approval/vendor":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("VendorApproval")
                setSelectedTitle("VendorApproval")

                break;
            case "/gst/approval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("GSTApproval")
                setSelectedTitle("GSTApproval")

                break;
            case "/approval/customer":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("Customerapproval")
                setSelectedTitle("Customerapproval")

                break;
            case "/approval/card":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setSubSelectedTitle("CardApproval")
                setSelectedTitle("CardApproval")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setApprovalMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            default:
                setApprovalMenu(false)
                setDashboardMenu(false)
                setSubSelectedTitle("")
                setSelectedTitle("")
                break;

        }
    }, [location])

    const ApprovalList = ["Vendor Payment ", "GST Payment", "Direct Tax ", "Utility Payment", "Bank Approval", "CardApproval", " User", "Virtual Card", "VendorApproval", "Customerapproval",];
    const ApprovalSubmenu = ApprovalList.includes(selectedTitle);
    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    interface UserAccess {

        dashBoardMenu?: string[];
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: boolean;
        OrganisationSubMenu?: boolean;
        ApprovalSubMenu?: string[];
        PayableSubMenu?: boolean;
        OnboardingSubMenu?: boolean;
        reports: boolean;
        virtualCard: boolean;
    }
    const usersAccess: UserAccess[] = [{
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: true,
        ApprovalSubMenu: ["Bank Approval", "Virtual Card", "CardApproval", "VendorApproval", "GSTApproval", "Customerapproval"],
        productSubmenu: true,
        PayableSubMenu: true,
        OnboardingSubMenu: true,
        reports: true
    }
    ]

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ width: "80%", color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                {/* Approval */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Approval")
                    setApprovalMenu(!approvalMenu)
                }}>
                    <div className='icon'>
                        <Approval style={{ fill: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }} />
                    </div>

                    <div style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }}>{"Approval"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined, transform: approvalMenu ? "rotate(180deg)" : undefined }} /></div>
                </div>
                {
                    approvalMenu &&
                    <div className='productadmin-submenu'>
                        {
                            usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Bank Approval") &&
                            <li style={{ color: subSelectedTitle === "Bank Approval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Bank Approval", "/approval/bank")}
                            >{"Bank"}
                            </li>
                        }
                        {
                            usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("CardApproval") &&
                            <li style={{ color: subSelectedTitle === "CardApproval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("CardApproval", "/approval/card")}
                            >{"Card"}
                            </li>
                        }
                        {
                            usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Virtual Card") &&
                            <li style={{ color: subSelectedTitle === "Virtual Card" ? "orange" : undefined }}
                                onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                            >{"Virtual Card"}
                            </li>
                        }
                        {
                            usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("VendorApproval") &&
                            <li style={{ color: subSelectedTitle === "VendorApproval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                            >{"Vendor"}
                            </li>
                        }
                        {
                            usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("GSTApproval") &&
                            <li style={{ color: subSelectedTitle === "GSTApproval" ? "orange" : undefined }}
                                onClick={() => navConfirmation("GSTApproval", "/gst/approval")}
                            >{"GST Payment"}
                            </li>
                        }
                        {usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Customerapproval") && <li style={{ color: subSelectedTitle === "Customerapproval" ? "orange" : undefined }} onClick={() => navConfirmation("Customerapproval", "/approval/customer")} >{"Customer"}</li>}

                    </div>
                }


                {/* Report */}
                {/* <div className='titles' onClick={() => { setSelectedTitle("Reports") }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} /></div>
                </div> */}

            </div>
            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>

                <div className='titles'>
                    <div className='icon'>
                        <Approval style={{ fill: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Approval"}</div></div></div>
                        <div className='productadmin-submenu'>
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Bank Approval") &&
                                <li style={{ color: selectedTitle === "Bank Approval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank Approval", "/approval/bank")}
                                >{"Bank"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("CardApproval") &&
                                <li style={{ color: selectedTitle === "CardApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("CardApproval", "/approval/card")}
                                >{"Card"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Virtual Card") &&
                                <li style={{ color: selectedTitle === "Virtual Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                                >{"Virtual Card"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("VendorApproval") &&
                                <li style={{ color: selectedTitle === "VendorApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                                >{"Vendor"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("GSTApproval") &&
                                <li style={{ color: selectedTitle === "GSTApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("GSTApproval", "/gst/approval")}
                                >{"GST Payment"}
                                </li>
                            }
                            {usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Customerapproval") && <li style={{ color: selectedTitle === "Customerapproval" ? "orange" : undefined }} onClick={() => navConfirmation("Customerapproval", "/approval/customer")} >{"Customer"}</li>}

                        </div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    );
};

// Maker, Checker, Approver
export const Maker = ({ currentPaymentActor, navbarTitle }: props) => {
    const [selectedTitle, setSelectedTitle] = useState("");
    const [payableMenu, setPayableMenu] = useState(false);
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [approvalMenu, setApprovalMenu] = useState(false);
    const [organisationMenu, setOrganisationMenu] = useState(false);
    const [subSelectedTitle, setSubSelectedTitle] = useState("");
    const [tempSelect, setTempSelect] = useState("");
    const [tempNav, setTempNav] = useState("");
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const PayableList = ["PayableVendor", "GST", "excisePayment", "DirectTax", "Utility"];
    const PayableSubmenu = PayableList.includes(selectedTitle);

    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    const OrganizationList = ["Bank", "Card", "Vendor", "Customer", "Virtualcard"];
    const OrganisationSubmenu = OrganizationList.includes(selectedTitle);

    const ApprovalList = ["PayableVendorApproval", "GST Payment", "DirectTax ", "Utility Payment", "Bank Approval", "CardApproval", " User", "Virtual Card", "VendorApproval", "exciseApproval", "Customerapproval"];
    const ApprovalSubmenu = ApprovalList.includes(selectedTitle);

    useEffect(() => {
        switch (location.pathname) {
            case "/organisation/bank":
                setOrganisationMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Bank")
                setSubSelectedTitle("Bank")
                break;
            case "/organisation/card":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Card")
                setSubSelectedTitle("Card")
                break;
            case "/organisation/vendor":
                setOrganisationMenu(true)
                setDashboardMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Vendor")
                setSubSelectedTitle("Vendor")
                break;
            case "/organisation/customer":
                setOrganisationMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Customer")
                setSubSelectedTitle("Customer")
                break;
            case "/virtualcard":
                setOrganisationMenu(true)
                setPayableMenu(false)
                setDashboardMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Virtualcard")
                setSubSelectedTitle("Virtualcard")
                break;
            case "/payable/vendor":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("PayableVendor")
                setSubSelectedTitle("PayableVendor")
                break;
            case "/gst":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("GST")
                setSubSelectedTitle("GST")
                break;
            case "/payable/direct-tax":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("DirectTax")
                setSubSelectedTitle("DirectTax")
                break;
            case "/payable/excise-payment":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("excisePayment")
                setSubSelectedTitle("excisePayment")
                break;
            case "/approval/bank":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSelectedTitle("Bank Approval")
                setSubSelectedTitle("Bank Approval")
                break;
            case "/virtualcardApproval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("Virtual Card")
                setSelectedTitle("Virtual Card")

                break;
            case "/approval/vendor":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("VendorApproval")
                setSelectedTitle("VendorApproval")

                break;
            case "/gst/approval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("GSTApproval")
                setSelectedTitle("GSTApproval")

                break;
            case "/direct-tax/approval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("DirectTaxApproval")
                setSelectedTitle("DirectTaxApproval")
                break;
            case "/excise-payment/approval":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("exciseApproval")
                setSelectedTitle("exciseApproval")

                break;
            case "/payable/utility":
                setPayableMenu(true)
                setApprovalMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("Utility")
                setSubSelectedTitle("Utility")
                break;
            case "/payable/approver/vendor":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("PayableVendorApproval")
                setSelectedTitle("PayableVendorApproval")

                break;
            case "/approval/customer":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("Customerapproval")
                setSelectedTitle("Customerapproval")

                break;
            case "/approval/utility":
                setPayableMenu(false)
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("UtilityPayment")
                setSubSelectedTitle("UtilityPayment")
                break;
            case "/approval/card":
                setApprovalMenu(true)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setSubSelectedTitle("CardApproval")
                setSelectedTitle("CardApproval")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setOrganisationMenu(false)
                setPayableMenu(false)
                setApprovalMenu(false)
                setSelectedTitle("Dashboard")
                setSubSelectedTitle("")
                break;
            default:
                setApprovalMenu(false)
                setPayableMenu(false)
                setDashboardMenu(false)
                setOrganisationMenu(false)
                setSelectedTitle("")
                setSubSelectedTitle("")
                break;

        }
    }, [location])

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }
    interface UserAccess {

        dashBoardMenu?: string[];
        productAdmin?: boolean;
        Organisation?: boolean;
        Approval?: boolean;
        Payable?: boolean;
        Onboarding?: boolean;
        productSubmenu?: boolean;
        OrganisationSubMenu?: string[];
        ApprovalSubMenu?: string[];
        PayableSubMenu?: string[];
        OnboardingSubMenu?: boolean;
        reports: boolean;
        virtualCard: boolean;
    }
    const usersAccess: UserAccess[] = [{
        virtualCard: true,
        dashBoardMenu: ["Dashboard"],
        productAdmin: true,
        Organisation: true,
        Payable: true,
        Approval: true,
        Onboarding: true,
        OrganisationSubMenu: ["Bank", "Card", "Customer", "Vendor", "Virtualcard"],
        ApprovalSubMenu: ["Bank Approval", "Virtual Card", "exciseApproval", "GSTApproval", "CardApproval", "VendorApproval", "Customerapproval", "PayableVendorApproval", "UtilityPayment"],
        productSubmenu: true,
        PayableSubMenu: ["PayableVendor", "GST", "DirectTax", "excisePayment"],
        OnboardingSubMenu: true,
        reports: true
    }
    ]

    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>
                {/* Payable */}
                {navbarTitle !== "Approver" && <>
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Payable")
                        setPayableMenu(!payableMenu)
                    }}>
                        <div className='icon'>
                            <Payable style={{ fill: (selectedTitle === "Payable" || PayableSubmenu) ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Payable" || PayableSubmenu) ? "orange" : undefined }}>{"Payable"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Payable" || PayableSubmenu) ? "orange" : undefined, transform: payableMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                    {
                        payableMenu &&

                        <div className='productadmin-submenu'>
                            {
                                usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("PayableVendor") &&
                                <li style={{ color: subSelectedTitle === "PayableVendor" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("PayableVendor", "/payable/vendor")}
                                >{"Vendor"}
                                </li>
                            }
                            {
                                usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("GST") &&
                                <li style={{ color: subSelectedTitle === "GST" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("GST", "/gst")}
                                >{"GST"}
                                </li>
                            }
                            {
                                usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("excisePayment") &&
                                <li style={{ color: subSelectedTitle === "excisePayment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("excisePayment", "/payable/excise-payment")}
                                >{"Excise Payment"}
                                </li>
                            }
                            {
                                usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("DirectTax") &&
                                <li style={{ color: subSelectedTitle === "DirectTax" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("DirectTax", "/payable/direct-tax")}
                                >{"Direct Tax"}
                                </li>
                            }
                            {
                                usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("Utility") &&
                                <li style={{ color: subSelectedTitle === "Utility" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Utility", "/payable/utility")}
                                >{"Utility"}
                                </li>
                            }
                        </div>
                    }
                </>}
                {navbarTitle === "Maker" && <>
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Organisation")
                        setOrganisationMenu(!organisationMenu)
                    }}>
                        <div className='icon'>
                            <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}>{"Organisation"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined, transform: organisationMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                    {
                        organisationMenu &&
                        <div className='productadmin-submenu'>
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Bank") &&
                                <li style={{ color: subSelectedTitle === "Bank" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank", "/organisation/bank")}
                                >{"Bank Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Card") &&
                                <li style={{ color: subSelectedTitle === "Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Card", "/organisation/card")}
                                >{"Card Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Virtualcard") &&
                                <li style={{ color: subSelectedTitle === "Virtualcard" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtualcard", "/virtualcard")}
                                >{"Virtual Card Creation"}
                                </li>
                            }
                            {
                                usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Vendor") &&
                                <li style={{ color: subSelectedTitle === "Vendor" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Vendor", "/organisation/vendor")}
                                >{"Vendor Creation"}
                                </li>
                            }
                            {usersAccess[0].OrganisationSubMenu &&
                                usersAccess[0].OrganisationSubMenu.includes("Customer") &&
                                <li style={{ color: subSelectedTitle === "Customer" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Creation"} </li>}

                        </div>
                    }
                </>
                }
                {navbarTitle === "Approver" && <>
                    <div className='titles' onClick={() => {
                        setSelectedTitle("Approval")
                        setApprovalMenu(!approvalMenu)
                    }}>
                        <div className='icon'>
                            <Approval style={{ fill: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }} />
                        </div>

                        <div style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }}>{"Approval"}</div>
                        <div className='accordion-icon'><AiFillCaretDown style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined, transform: approvalMenu ? "rotate(180deg)" : undefined }} /></div>
                    </div>
                    {
                        approvalMenu &&
                        <div className='productadmin-submenu'>
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("PayableVendorApproval") &&
                                <li style={{ color: subSelectedTitle === "PayableVendorApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("PayableVendorApproval", "/payable/approver/vendor")}
                                >{"Vendor Payment"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("GSTApproval") &&
                                <li style={{ color: subSelectedTitle === "GSTApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("GSTApproval", "/gst/approval")}
                                >{"GST Payment"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("DirectTaxApproval") &&
                                <li style={{ color: subSelectedTitle === "DirectTaxApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("DirectTaxApproval", "/direct-tax/approval")}
                                >{"Direct Tax"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("exciseApproval") &&
                                <li style={{ color: subSelectedTitle === "exciseApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("exciseApproval", "/excise-payment/approval")}
                                >{"Excise Payment"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("UtilityPayment") &&
                                <li style={{ color: subSelectedTitle === "UtilityPayment" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("UtilityPayment", "/approval/utility")}
                                >{"Utility Payment"}
                                </li>
                            }

                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Bank Approval") &&
                                <li style={{ color: subSelectedTitle === "Bank Approval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Bank Approval", "/approval/bank")}
                                >{"Bank"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("CardApproval") &&
                                <li style={{ color: subSelectedTitle === "CardApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("CardApproval", "/approval/card")}
                                >{"Card"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Virtual Card") &&
                                <li style={{ color: subSelectedTitle === "Virtual Card" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                                >{"Virtual Card"}
                                </li>
                            }
                            {
                                usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("VendorApproval") &&
                                <li style={{ color: subSelectedTitle === "VendorApproval" ? "orange" : undefined }}
                                    onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                                >{"Vendor"}
                                </li>
                            }
                            {usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Customerapproval") && <li style={{ color: subSelectedTitle === "Customerapproval" ? "orange" : undefined }} onClick={() => navConfirmation("Customerapproval", "/approval/customer")} >{"Customer"}</li>}

                        </div>
                    }
                </>}
                {/* Report */}
                {/* <div className='titles' onClick={() => { setSelectedTitle("Reports") }}>
                    <div className='icon'>
                        <ReportIcon style={{ fill: selectedTitle === "Reports" ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: selectedTitle === "Reports" ? "orange" : undefined }}>{"Reports"}</div>
                    <div className='accordion-icon'><AiFillCaretDown style={{ color: selectedTitle === "Reports" ? "orange" : undefined }} /></div>
                </div> */}
            </div>

            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>
                {navbarTitle !== "Approver" && <>

                    <div className='titles'>
                        <div className='icon'>
                            <Payable style={{ fill: (selectedTitle === "Payable" || PayableSubmenu) ? "orange" : undefined }} />
                        </div>
                        <div className='menu-group'>
                            <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Payable" || PayableSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Payable"}</div></div></div>

                            <div className='productadmin-submenu'>
                                {
                                    usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("PayableVendor") &&
                                    <li style={{ color: subSelectedTitle === "PayableVendor" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("PayableVendor", "/payable/vendor")}
                                    >{"Vendor"}
                                    </li>
                                }
                                {
                                    usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("GST") &&
                                    <li style={{ color: subSelectedTitle === "GST" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("GST", "/gst")}
                                    >{"GST"}
                                    </li>
                                }
                                {
                                    usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("DirectTax") &&
                                    <li style={{ color: subSelectedTitle === "DirectTax" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("DirectTax", "/payable/direct-tax")}
                                    >{"Direct Tax"}
                                    </li>
                                }
                                {
                                    usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("excisePayment") &&
                                    <li style={{ color: subSelectedTitle === "excisePayment" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("excisePayment", "/payable/excise-payment")}
                                    >{"Excise Payment"}
                                    </li>
                                }
                                {
                                    usersAccess[0].PayableSubMenu && usersAccess[0].PayableSubMenu.includes("Utility") &&
                                    <li style={{ color: subSelectedTitle === "Utility" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Utility", "/payable/utility")}
                                    >{"Utility"}
                                    </li>
                                }
                            </div>
                        </div>
                    </div>
                </>}
                {navbarTitle === "Maker" && <>
                    <div className='titles'>
                        <div className='icon'>
                            <Organisation style={{ fill: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }} />
                        </div>
                        <div className='menu-group'>
                            <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Organisation" || OrganisationSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Organisation"}</div></div></div>
                            <div className='productadmin-submenu'>
                                {
                                    usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Bank") &&
                                    <li style={{ color: subSelectedTitle === "Bank" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Bank", "/organisation/bank")}
                                    >{"Bank Creation"}
                                    </li>
                                }
                                {
                                    usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Card") &&
                                    <li style={{ color: subSelectedTitle === "Card" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Card", "/organisation/card")}
                                    >{"Card Creation"}
                                    </li>
                                }
                                {
                                    usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Virtualcard") &&
                                    <li style={{ color: subSelectedTitle === "Virtualcard" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Virtualcard", "/virtualcard")}
                                    >{"Virtual Card Creation"}
                                    </li>
                                }
                                {
                                    usersAccess[0].OrganisationSubMenu && usersAccess[0].OrganisationSubMenu.includes("Vendor") &&
                                    <li style={{ color: subSelectedTitle === "Vendor" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Vendor", "/organisation/vendor")}
                                    >{"Vendor Creation"}
                                    </li>
                                }
                                {usersAccess[0].OrganisationSubMenu &&
                                    usersAccess[0].OrganisationSubMenu.includes("Customer") &&
                                    <li style={{ color: subSelectedTitle === "Customer" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Customer", "/organisation/customer")} >{"Customer Creation"} </li>}

                            </div>
                        </div>
                    </div>

                </>
                }
                {navbarTitle === "Approver" && <>
                    <div className='titles'>
                        <div className='icon'>
                            <Approval style={{ fill: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }} />
                        </div>
                        <div className='menu-group'>
                            <div className='main-menu menu-item menu-heading' style={{ color: (selectedTitle === "Approval" || ApprovalSubmenu) ? "orange" : undefined }}><div className={"heading-item"}><div>{"Approval"}</div></div></div>
                            <div className='productadmin-submenu'>
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("PayableVendorApproval") &&
                                    <li style={{ color: subSelectedTitle === "PayableVendorApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("PayableVendorApproval", "/payable/approver/vendor")}
                                    >{"Vendor Payment"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("GSTApproval") &&
                                    <li style={{ color: subSelectedTitle === "GSTApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("GSTApproval", "/gst/approval")}
                                    >{"GST Payment"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("exciseApproval") &&
                                    <li style={{ color: subSelectedTitle === "exciseApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("exciseApproval", "/excise-payment/approval")}
                                    >{"Excise Payment"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("DirectTaxApproval") &&
                                    <li style={{ color: subSelectedTitle === "DirectTaxApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("DirectTaxApproval", "/direct-tax/approval")}
                                    >{"Direct Tax"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("UtilityPayment") &&
                                    <li style={{ color: subSelectedTitle === "UtilityPayment" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("UtilityPayment", "/approval/utility")}
                                    >{"Utility Payment"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Bank Approval") &&
                                    <li style={{ color: subSelectedTitle === "Bank Approval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Bank Approval", "/approval/bank")}
                                    >{"Bank"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("CardApproval") &&
                                    <li style={{ color: subSelectedTitle === "CardApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("CardApproval", "/approval/card")}
                                    >{"Card"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Virtual Card") &&
                                    <li style={{ color: subSelectedTitle === "Virtual Card" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("Virtual Card", "/virtualcardApproval")}
                                    >{"Virtual Card"}
                                    </li>
                                }
                                {
                                    usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("VendorApproval") &&
                                    <li style={{ color: subSelectedTitle === "VendorApproval" ? "orange" : undefined }}
                                        onClick={() => navConfirmation("VendorApproval", "/approval/vendor")}
                                    >{"Vendor"}
                                    </li>
                                }
                                {usersAccess[0].ApprovalSubMenu && usersAccess[0].ApprovalSubMenu.includes("Customerapproval") && <li style={{ color: subSelectedTitle === "Customerapproval" ? "orange" : undefined }} onClick={() => navConfirmation("Customerapproval", "/approval/customer")} >{"Customer"}</li>}

                            </div>
                        </div>
                    </div>
                </>}
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    );
};


// ReportViewer
export const ReportViewer = () => {
    const [selectedTitle, setSelectedTitle] = useState("");
    const [payableMenu, setPayableMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [tempNav, setTempNav] = useState("");
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        switch (location.pathname) {

            case "/user-profile":
                setDashboardMenu(false)
                setSelectedTitle("")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setSelectedTitle("Dashboard")
                break;
            default:
                setDashboardMenu(false)
                setSelectedTitle("")
                break;

        }
    }, [location])


    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    interface UserAccess {
        dashBoardMenu?: string[];

    }

    const usersAccess: UserAccess[] = [{
        dashBoardMenu: ["Dashboard"],

    }]

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }
    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    );
};
export const FingerTipLogViewer = () => {
    const [selectedTitle, setSelectedTitle] = useState("");
    const [payableMenu, setPayableMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [userLogMenu, setUserLogMenu] = useState(false);
    const [securityLogMenu, setSecurityLogMenu] = useState(false);
    const [integrationLogMenu, setIntegrationLogMenu] = useState(false);
    const [administrationMenu, setAdministrationMenu] = useState(false);
    const [tempNav, setTempNav] = useState("");
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        switch (location.pathname) {

            case "/user-profile":
                setDashboardMenu(false)
                setUserLogMenu(false)
                setSecurityLogMenu(false)
                setIntegrationLogMenu(false)
                setAdministrationMenu(false)
                setSelectedTitle("")
                break;
            case "/dashboard":
                setDashboardMenu(true)
                setUserLogMenu(false)
                setSecurityLogMenu(false)
                setIntegrationLogMenu(false)
                setAdministrationMenu(false)
                setSelectedTitle("Dashboard")
                break;
            case "/log-report/user-log":
                setDashboardMenu(false)
                setUserLogMenu(true)
                setSecurityLogMenu(false)
                setIntegrationLogMenu(false)
                setAdministrationMenu(false)
                setSelectedTitle("userLog")
                break;
            case "/log-report/security-log":
                setSecurityLogMenu(true)
                setDashboardMenu(false)
                setUserLogMenu(false)
                setIntegrationLogMenu(false)
                setAdministrationMenu(false)
                setSelectedTitle("securityLog")
                break;
            case "/log-report/integration-log":
                setIntegrationLogMenu(true)
                setSecurityLogMenu(false)
                setDashboardMenu(false)
                setUserLogMenu(false)
                setAdministrationMenu(false)
                setSelectedTitle("integrationLog")
                break;
            case "/log-report/administration-log":
                setAdministrationMenu(true)
                setIntegrationLogMenu(false)
                setSecurityLogMenu(false)
                setDashboardMenu(false)
                setUserLogMenu(false)
                setSelectedTitle("administrationLog")
                break;
            default:
                setDashboardMenu(false)
                setAdministrationMenu(false)
                setIntegrationLogMenu(false)
                setUserLogMenu(false)
                setSecurityLogMenu(false)
                setSelectedTitle("")
                break;

        }
    }, [location])


    const dashBoard = ["Dashboard"];
    const dashBoardMenu = dashBoard.includes(selectedTitle);
    const userLog = ["userLog"];
    const userLogEventMenu = userLog.includes(selectedTitle);
    const securityLog = ["securityLog"];
    const securityLogEventMenu = securityLog.includes(selectedTitle);
    const integrationLog = ["integrationLog"];
    const integrationLogEventMenu = integrationLog.includes(selectedTitle);
    const administrationLog = ["administrationLog"];
    const administrationLogEventMenu = administrationLog.includes(selectedTitle);

    interface UserAccess {
        dashBoardMenu?: string[];
        userLogEventMenu?: string[];
        securityLogEventMenu?: string[];
        integrationLogEventMenu?: string[];
        administrationLogEventMenu?: string[];

    }

    const usersAccess: UserAccess[] = [{
        dashBoardMenu: ["Dashboard"],
        userLogEventMenu: ["userLog"],
        securityLogEventMenu: ["securityLog"],
        integrationLogEventMenu: ["integrationLog"],
        administrationLogEventMenu: ["administrationLog"],

    }]

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }
    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/dashboard")}
                    >{"Dashboard"}</div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("userLog")
                    setUserLogMenu(!userLogEventMenu)
                }}>
                    <div className='icon'>
                        <UserLogIcon style={{ fill: (selectedTitle === "userLog" || userLogEventMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "userLog" || userLogEventMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("userLog", "/log-report/user-log")}
                    >{"User Log"}</div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("securityLog")
                    setSecurityLogMenu(!securityLogEventMenu)
                }}>
                    <div className='icon'>
                        <SecurityLogIcon style={{ fill: (selectedTitle === "securityLog" || securityLogEventMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "securityLog" || securityLogEventMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("securityLog", "/log-report/security-log")}
                    >{"Security Log"}</div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("integrationLog")
                    setIntegrationLogMenu(!integrationLogEventMenu)
                }}>
                    <div className='icon'>
                        <IntegrationLogIcon style={{ fill: (selectedTitle === "integrationLog" || integrationLogEventMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "integrationLog" || integrationLogEventMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("integrationLog", "/log-report/integration-log")}
                    >{"Integration Log"}</div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("administrationLog")
                    setAdministrationMenu(!administrationLogEventMenu)
                }}>
                    <div className='icon'>
                        <AdminIcon style={{ fill: (selectedTitle === "administrationLog" || administrationLogEventMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "administrationLog" || administrationLogEventMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("administrationLog", "/log-report/administration-log")}
                    >{"Administration"}</div>
                </div>

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("userLog")
                    navConfirmation("userLog", "/log-report/user-log")
                }}>
                    <div className='icon'>
                        <UserLogIcon style={{ fill: selectedTitle === "userLog" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "userLog" ? "orange" : undefined }}><div className={"heading-item"}><div>{"User Log"}</div></div></div>
                    </div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("securityLog")
                    navConfirmation("securityLog", "/log-report/security-log")
                }}>
                    <div className='icon'>
                        <SecurityLogIcon style={{ fill: selectedTitle === "securityLog" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "securityLog" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Security Log"}</div></div></div>
                    </div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("integrationLog")
                    navConfirmation("integrationLog", "/log-report/integration-log")
                }}>
                    <div className='icon'>
                        <IntegrationLogIcon style={{ fill: selectedTitle === "integrationLog" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "integrationLog" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Integration Log"}</div></div></div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    );
};

export const VendorUser = () => {
    const [selectedTitle, setSelectedTitle] = useState("Dashboard");
    const [payableMenu, setPayableMenu] = useState(false);
    const [tempSelect, setTempSelect] = useState("");
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [vendorKycMenu, setVendorKycMenu] = useState(true);
    const [vendorInvoiceMenu, setVendorInvoiceMenu] = useState(true);
    const [tempNav, setTempNav] = useState("");
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        switch (location.pathname) {

            case "/user-profile":
                setDashboardMenu(false)
                setVendorKycMenu(false)
                setVendorInvoiceMenu(false)
                setSelectedTitle("")
                break;
            case "/vendor/login/dashboard":
                setDashboardMenu(true)
                setVendorKycMenu(false)
                setVendorInvoiceMenu(false)
                setSelectedTitle("Dashboard")
                break;
            case "/vendor/login":
                setDashboardMenu(false)
                setVendorKycMenu(true)
                setVendorInvoiceMenu(false)
                setSelectedTitle("Vendor")
                break;
            case "/vendor/login/payable":
                setDashboardMenu(false)
                setVendorKycMenu(false)
                setVendorInvoiceMenu(true)
                setSelectedTitle("Invoice")
                break;
            default:
                setDashboardMenu(false)
                setVendorKycMenu(true)
                setVendorInvoiceMenu(false)
                setSelectedTitle("")
                break;

        }
    }, [location])


    const dashBoard = ["Dashboard"];
    const vendorKyc = ["Vendor"].includes(selectedTitle);
    const vendorInvoice = ["Invoice"].includes(selectedTitle);
    const dashBoardMenu = dashBoard.includes(selectedTitle);

    interface UserAccess {
        dashBoardMenu?: string[];

    }

    const usersAccess: UserAccess[] = [{
        dashBoardMenu: ["Dashboard"],
    }]

    const navConfirmation = (Select: string, navTo: string) => {
        if (isFormModified && location.pathname !== navTo) {
            setShowChangesModal(true);
            setTempSelect(Select);
            setTempNav(navTo);
        } else {
            setSelectedTitle(Select);
            navigate(navTo);

        }
    }

    const leavePage = () => {
        navigate(tempNav);
        setSelectedTitle(tempSelect);
        setShowChangesModal(false);
        setTempSelect('')
        dispatch(resetFormModified(false));
        setTempNav('')
    }
    return (
        <>
            <div className='product-admin expanded'>
                {/* Dashboard */}
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    setDashboardMenu(!dashboardMenu)
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Dashboard" || dashBoardMenu) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Dashboard", "/vendor/login/dashboard")}
                    >{"Dashboard"}</div>
                </div>

                <div className='titles' onClick={() => {
                    setSelectedTitle("Vendor")
                    setVendorKycMenu(!vendorKycMenu)
                }}>
                    <div className='icon'>
                        <VendorUserIcon style={{ fill: (selectedTitle === "Vendor" || vendorKyc) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Vendor" || vendorKyc) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Vendor", "/vendor/login")}
                    >{"Vendor"}</div>
                </div>

                <div className='titles' onClick={() => {
                    setSelectedTitle("Invoice")
                    setVendorInvoiceMenu(!vendorInvoiceMenu)
                }}>
                    <div className='icon'>
                        <InvoiceIcon style={{ fill: (selectedTitle === "Invoice" || vendorInvoice) ? "orange" : undefined }} />
                    </div>
                    <div style={{ color: (selectedTitle === "Invoice" || vendorInvoice) ? "orange" : undefined }}
                        onClick={() => navConfirmation("Invoice", "/vendor/login/payable")}
                    >{"Invoice"}</div>
                </div>

            </div>


            <div className='product-admin collapsed'>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Dashboard")
                    navConfirmation("dasboard", "/vendor/login/dashboard")
                }}>
                    <div className='icon'>
                        <DashboardIcon style={{ fill: selectedTitle === "Dashboard" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Dashboard" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Dashboard"}</div></div></div>
                    </div>
                </div>
                <div className='titles' onClick={() => {
                    setSelectedTitle("Vendor")
                    setVendorKycMenu(!vendorKycMenu)
                    navConfirmation("Vendor", "/vendor/login")
                }}>
                    <div className='icon'>
                        <VendorUserIcon style={{ fill: selectedTitle === "Vendor" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Vendor" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Vendor"}</div></div></div>
                    </div>
                </div>

                <div className='titles' onClick={() => {
                    setSelectedTitle("Invoice")
                    setVendorInvoiceMenu(!vendorInvoiceMenu)
                    navConfirmation("Invoice", "/vendor/login/payable")
                }}>
                    <div className='icon'>
                        <InvoiceIcon style={{ fill: selectedTitle === "Invoice" ? "orange" : undefined }} />
                    </div>
                    <div className='menu-group'>
                        <div className='main-menu menu-item menu-heading' style={{ color: selectedTitle === "Invoice" ? "orange" : undefined }}><div className={"heading-item"}><div>{"Invoice"}</div></div></div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => leavePage()}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    );
};
