import { useEffect, useState } from 'react'
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import ButtonComponent from '../../common-component/form-elements/button-component';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import InputComponent from '../../common-component/form-elements/input-component';
import DocumentShowModal from '../../common-component/modals/document-show-modal';
import IconButton from '../../common-component/form-elements/icon-button';
import Changesmodal from '../../common-component/modals/changes-modal';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
import { BsPlusLg } from 'react-icons/bs';
import "../../../styles/product-admin/kyc-table.scss";
import LicenseKeyModal from '../../common-component/modals/license-key-modal';
import AddAccountIntegration from '../../common-component/modals/account-integration-modal';
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal';
import useAuth from '../../../services/hooks/useauth';
import { DateConverter } from '../../../helpers/capitalizetext';
import TransactionCharges from './transaction-charges';

export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}

interface Props {
    userNavigateFunction: (val: string) => void;
    isEditMode: boolean;
    companyAndKycDetails: any
    getEditMode: (value: boolean) => void;
}

function KycUploadTable({
    userNavigateFunction,
    isEditMode,
    companyAndKycDetails
}: Props) {
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const updatedIsValueValidAll: Record<string, boolean> = {};

    // Constant Values for DropDown Field
    const [accountIntegrationValues, setAccountIntegrationValues] = useState([])
    const onboardingStatus = [
        { label: "Approved", value: "Approved" },
        { label: "Declined", value: "Declined" },
    ]
    const GSTValues = [
        { label: "5.00", value: "5.00" },
        { label: "12.00", value: "12.00" },
        { label: "18.00", value: "18.00" },
        { label: "28.00", value: "28.00" },
    ]
    const [componentNavigator, setComponentNavigator] = useState("form");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [viewDocumentModal, setViewDocumentModal] = useState("")
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
    // const [otpVerification, setOtpVerification] = useState<boolean>(false);
    const [licenseKey, setlicenseKey] = useState<string>("")
    const [companyNames, setcompanyNames] = useState<any>([])
    const [currentFile, SetCurrentFile] = useState("")
    const [currentproof, setCurrentProof] = useState("")
    const [customerOnboarding, setCustomerOnboarding] = useState<any>({
        CompanyName: "",
        CompanyConstitution: "",
        integrations: "",
        integrationsId: "",
        OnboardingStatus: "",
        Description: "",
        customerOnboardingId: "",
        companyId: "",
        commercialCard: "",
        virtualCard: "",
        netbanking: "",
        Gst: "",
        Upi: "",
    })
    const [companyDetails, setCompanyDetails] = useState<any>({
        organisationId: "",
        companyId: "",
        customerOnboardingId: ""
    })
    const [kycDataTableMap, setKycdataTableMap] = useState<any>([])
    const [documentDeclineStatus, setDocumentDeclineStatus] = useState({
        Documentremarks: ""
    })
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")
    const [showLicenseModal, setShowLicenseModal] = useState<boolean>(false)
    const [showIntegrationModal, setShowIntegrationModal] = useState<boolean>(false)
    const [companyConstitution, setCompanyConstitution] = useState([])
    const [processName, setProcessName] = useState<string>("Approve");
    const [companyKYCId, setCompanyKYCId] = useState<any>();
    const [onboardingStatusCheck, setOnboardingStatusCheck] = useState<string>("")
    const [userPrivilege, setUserPrivilege] = useState<any>("");
    const [companyId, setCompanyId] = useState<any>()
    const [currentMode, setCurrentMode] = useState<any>("addmode")
    const [kycCurrentMode, setKycCurrentMode] = useState<any>("addmode")
    const [transactionTable, setTransactionTable] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    //Transaction Data State -------------------------------------------->
    const [transactionData, setTransactionData] = useState<any>({
        transactionChargesId: "",
        paymentGateway: "",
        commercialCard: "Fixed",
        pgChg: "",
        pgGst: "",
        platformChg: "",
        platformGst: "",
        netBanking: "",
        virtualCard: "",
        upi: "",
    })

    //Rate Card Data State -------------------------------------------->
    const [kycTransactionData, setKycTransactionData] = useState<any>({
        rateCardId: '',
        from: '',
        to: '',
        mdr: '',
        organisationId: "",
        company_id: "",
        customerOnboardingId: "",
        transactionChargesId: ""
    })

    // useEffect for calling a userPriviledge ----------------------------------->
    useEffect(() => {
        const fetchPrivillageData = async () => {
            try {
                const response = await axiosPrivate.get('/user/get-privilege');
                setUserPrivilege(response.data.UserPrivilege)

            } catch (error) {
            }
        };
        fetchPrivillageData();
    }, []);

    // useEffect for calling a Account Integration Data ----------------------------------->
    useEffect(() => {
        getAccountIntegrationDetails();
        if (isEditMode) return;
        getCompanyDetails()
    }, [])

    // useEffect for calling a Company Details ----------------------------------->
    useEffect(() => {
        if (!isEditMode) return;
        setCustomerOnboarding((prevState: any) => ({
            ...prevState,
            CompanyName: companyAndKycDetails.companyName,
            companyId: companyAndKycDetails.companyId,
            CompanyConstitution: companyAndKycDetails.companyConstitution
        }));

        setCompanyDetails((prevState: any) => ({
            ...prevState,
            organisationId: companyAndKycDetails.organisationId,
            companyId: companyAndKycDetails.company_id,
            customerOnboardingId: companyAndKycDetails.customerOnboardingId
        }))

        if (companyAndKycDetails.companyId) {
            getOverallDetails(companyAndKycDetails.companyId)
        }
    }, [companyAndKycDetails])

    // Function for getting the Company values via API  
    // function getUserData(id: string) {
    //     axiosPrivate.get(`/customer-onboarding/form/get/${id}`)
    //         .then(response => {
    //             if (response.status === 200) {
    //                 if (getEditData) {
    //                     getEditData("form", response.data.companyAndKycDetails);
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //         });
    // }

    // Function for getting overall company details via API  ------------------------------>
    const getOverallDetails = async (id: any) => {
        try {
            const response = await axiosPrivate.get(`/customer-onboarding/form-data/get/${id}`);
            if (response.status === 200) {
                if (response.data.kycRecordDetails) {
                    setKycdataTableMap(response.data.kycRecordDetails);
                }
                setCustomerOnboarding((prevState: any) => ({
                    ...prevState,
                    integrations: response.data.integrationData.integrations,
                    OnboardingStatus: response.data.integrationData.OnboardingStatus,
                    Description: response.data.integrationData.Description,
                    customerOnboardingId: response.data.integrationData.customerOnboardingId,
                    commercialCard: response.data.integrationData.commercialCard,
                    netbanking: response.data.integrationData.netbanking,
                    virtualCard: response.data.integrationData.virtualCard,
                    Gst: response.data.integrationData.Gst,
                    Upi: response.data.integrationData.Upi,
                }));
                setOnboardingStatusCheck(response.data.integrationData.OnboardingStatus)
                setlicenseKey(response.data.integrationData.companyLicenseKey)

                let transDetails: any[] = []
                if (response.data.transactionDetails) {
                    response.data.transactionDetails.map((data: any) => {
                        transDetails.push({
                            transactionChargesId: data.transactionChargesId,
                            paymentGateway: data.paymentGateWay,
                            commercialCard: data.commercialCard,
                            pgChg: data.pgCharge,
                            pgGst: data.pgGst,
                            platformChg: data.platFormCharge,
                            platformGst: data.platFormGst,
                            netBanking: data.netbanking,
                            virtualCard: data.virtualCard,
                            upi: data.upi,
                        })
                    })
                    setTransactionTable(transDetails)
                } else {
                    setTransactionTable([])
                }

            }
        } catch (error: any) {
            setKycdataTableMap([]);
        }
    };

    // Function for getting company list and push to array ---------------------------->
    const getCompanyDetails = async () => {
        try {
            const response = await axiosPrivate.get(`/customeronboarding/company-dropdown-list`);
            if (response.status === 200) {
                let options: any = [];
                response.data.decryptedCompanys.map((item: any) => {
                    options.push({
                        label: item.name,
                        value: item.name,
                        id: item.id,
                    })
                })

                setCompanyConstitution(response.data.decryptedCompanys)
                setcompanyNames(options);
            }
            // }
        } catch (error: any) {
            setLoadingScreen(false)
        }
    };

    // Function for getting account integration details --------------------------->
    const getAccountIntegrationDetails = async () => {
        try {
            const response = await axiosPrivate.get(`/customeronboarding/accountsintegration/get`);
            if (response.status === 200) {
                let options: any = [];

                response.data.decryptData.map((item: any) => {
                    options.push({
                        label: item.accountsIntegrationValue,
                        value: item.accountsIntegrationValue,
                        id: item.accountsIntegrationId,
                    })
                })
                setAccountIntegrationValues(options)
            }
        } catch (error: any) {
            setLoadingScreen(false)
        }
    };

    // Function for Save Kyc Form -------------------------------->
    const handleApproveKyc = async () => {
        const isFormValid = validateFormView()
        if (isFormValid) {
            setLoadingScreen(true)
            try {
                const response = await axiosPrivate.post(`customer-onboarding/form-data/create`, {
                    customerOnboardingId: customerOnboarding.customerOnboardingId,
                    companyId: customerOnboarding.companyId,
                    accountIntegration: customerOnboarding.integrations,
                    integrationsId: customerOnboarding.integrationsId,
                    customerOnboardingStatus: customerOnboarding.OnboardingStatus,
                    description: customerOnboarding.Description,
                    commercialCard: "",
                    Gst: "",
                    netbanking: "",
                    virtualCard: "",
                    Upi: "",
                });
                if (response.status === 200) {
                    setLoadingScreen(false)
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(response.data.message)
                    getOverallDetails(customerOnboarding.companyId)
                    getUserData(customerOnboarding.companyId)

                    setTimeout(() => {
                        setShowAlertBox(false);
                        // if (isEditMode) {
                        //     userNavigateFunction("grid")
                        // }
                        // if (!isEditMode) {
                        //     setComponentNavigator("form")
                        // }
                    }, 5000);
                }
            } catch (error: any) {

                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.message)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    // Function for leave this page to grid page ----------------------------->
    const kycFormDataCancel = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setCustomerOnboarding({
                CompanyName: "",
                CompanyConstitution: "",
                integrations: "",
                OnboardingStatus: "",
                Description: ""
            })
            setTransactionData({
                paymentGateway: "",
                commercialCard: " ",
                pgChg: " ",
                pgGst: " ",
                platformChg: "",
                platformGst: "",
                netBanking: " ",
                virtualCard: " ",
                upi: " ",
            })
            userNavigateFunction("grid")
        }
    }

    // Function for Previous Page ----------------------------------->
    const handlePrevious = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setTransactionData({
                paymentGateway: "",
                commercialCard: "Fixed",
                pgChg: "",
                pgGst: "",
                platformChg: "",
                platformGst: "",
                netBanking: "",
                virtualCard: "",
                upi: "",
            })
            setComponentNavigator("form")
        }
    }

    // function for applying mandatory fields validation ------------------------------->
    const userValidationRules: userValidationRulesInterface = {
        onBoarding_form: {
            CompanyName: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            OnboardingStatus: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            paymentGateway: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            commercialCard: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            pgChg: {
                regex: "",
                field: transactionData.commercialCard === "Fixed" ? "mandatory" : "",
                shouldNotBe: ""
            },
            pgGst: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            platformChg: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            platformGst: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            netBanking: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            virtualCard: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            upi: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            from: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            to: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            mdr: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
        }
    }

    // Function  for Validate Transaction Form --------------------------->
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};

        Object.keys(userValidationRules.onBoarding_form).forEach((field) => {
            const rule = userValidationRules.onBoarding_form[field];
            const value = transactionData[field];
            // const value = customerOnboarding[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;

            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    // Function  for Validate Rate Card Form -------------------------------------->
    const validateFormRateCard = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};

        Object.keys(userValidationRules.onBoarding_form).forEach((field) => {
            const rule = userValidationRules.onBoarding_form[field];
            const value = kycTransactionData[field];
            // const value = customerOnboarding[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;

            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    // Function  for Validate Main Form ---------------------------------->
    const validateFormView = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};

        Object.keys(userValidationRules.onBoarding_form).forEach((field) => {
            const rule = userValidationRules.onBoarding_form[field];
            const value = customerOnboarding[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;

            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    // Function for changing the company constituition by company name onchange -------------------------->
    const CompanyDetails = (id: number) => {
        const foundPrivilege: any = companyConstitution.find((privilegeObj: any) => privilegeObj.id === id);
        if (foundPrivilege) {
            setCustomerOnboarding((prevState: any) => ({
                ...prevState,
                CompanyConstitution: foundPrivilege.constitution,
            }));
        };
    }

    // Function for resend OTP -------------------------->
    async function resendOtp() {
        let valid = validateForm()
        if (valid) {
            try {
                const res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
                    approvedBy: auth.token,

                });
                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                    // updateData()
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);

            }
        }

    }

    // Function for OTP send ------------------------------->
    const otpsend = async () => {
        setLoadingScreen(true)
        try {
            let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                approvedBy: auth.token,
                pageName: "customerOnboarding",
                processName: processName, currentDataId: ""

            });
            if (res.status === 200) {
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("success")
                // setOtpVerification(true)
                setShowMessage(res.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);

            }
        } catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(error.response.data.error)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);

        }
    }

    // Switch case for setting the default values for dropdown and input field  --------------------->
    const getDescriptionByOnboardingStatus = (status: any) => {
        switch (status) {
            case "Declined":
                return "One or more KYC documents sent back for clarification.";
            case "Approved":
                return "All verifications completed and customer onboarding is approved.";
            default:
                return "";
        }
    };

    // Function for get User Data ---------------------------->
    function getUserData(id: string) {
        axiosPrivate.get(`/customer-onboarding/form/get/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setCompanyDetails((prevState: any) => ({
                        ...prevState,
                        organisationId: response.data.companyAndKycDetails.organisationId,
                        companyId: response.data.companyAndKycDetails.company_id,
                        customerOnboardingId: response.data.companyAndKycDetails.customerOnboardingId
                    }))
                    setCustomerOnboarding((prevState: any) => ({
                        ...prevState,
                        Companyconstitution: response.data.companyAndKycDetails.companyConstitution,
                    }));
                }
            })
            .catch(error => {
            });
    }

    return (
        <>
            <MainPageCard>
                <div className='navigater sub-navigator'>
                    <NavigateHeader
                        style={{
                            cursor: "pointer"
                        }}
                        firstValue={userPrivilege === "FingertipAdmin" ? "Fingertip Admin" : "Customer Authorizer"}
                        secondValue={userPrivilege === "FingertipAdmin" ? "KYC Onboarding" : "Customer Onboarding"}
                        navigatePage={() => { kycFormDataCancel() }}
                    />
                </div>
                {
                    componentNavigator === "form" &&
                    <div>
                        <div className={"main-div-form"} >
                            <div className='form-top-main' style={{ marginTop: "30px" }}>
                                <div className='form-input'>
                                    {isEditMode &&
                                        <InputComponent
                                            height={"40px"}
                                            width={"450px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={50}
                                            inputTitle={"Company Name"}
                                            placeHolder={customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "" ? "Company Name" : customerOnboarding.CompanyName}
                                            readOnly
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                setCustomerOnboarding((prevState: any) => ({
                                                    ...prevState,
                                                    Companyname: value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    }

                                    {
                                        !isEditMode &&
                                        <div className='dropdown drop-margin'>
                                            <div className={userDataErrors.CompanyName ? 'setError' : 'dropdown-user'} key={uuidv4()} style={{ width: "350px" }}>
                                                <DropdownComponent
                                                    options={companyNames}
                                                    title={"Company Name"}
                                                    className={"kyc-dropdown"}
                                                    width='350px'
                                                    required={true}
                                                    getData={(val) => {
                                                        setIsFormDirty(true)
                                                        setCustomerOnboarding((prevState: any) => ({
                                                            ...prevState,
                                                            CompanyName: val.value,
                                                            companyId: val.id,
                                                            integrations: "",
                                                            integrationsId: "",
                                                            OnboardingStatus: "",
                                                            Description: "",
                                                            customerOnboardingId: "",
                                                            commercialCard: "",
                                                            netbanking: "",
                                                            virtualCard: "",
                                                            Upi: "",
                                                            Gst: "",
                                                            GSTId: ""
                                                        }));
                                                        getUserData(val.id)
                                                        setlicenseKey("")
                                                        setKycdataTableMap([]);
                                                        dispatch(resetFormModified(true));
                                                        CompanyDetails(val.id);
                                                        setCompanyId(val.id)
                                                        getOverallDetails(val.id);
                                                        setUserDataErrors({})
                                                    }}
                                                    defaultValue={[{
                                                        label: customerOnboarding.CompanyName === "" ? "Select" : customerOnboarding.CompanyName,
                                                        value: customerOnboarding.CompanyName === "" ? "Select" : customerOnboarding.CompanyName
                                                    }]}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <InputComponent
                                        height={"40px"}
                                        width={"450px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={50}
                                        inputTitle={"Company Constitution"}
                                        placeHolder={customerOnboarding.CompanyConstitution === null || customerOnboarding.CompanyConstitution === "" ? "Company Constitution" : customerOnboarding.CompanyConstitution}
                                        // placeHolder={customerOnboarding.CompanyConstitution === null || customerOnboarding.CompanyConstitution === "" ? "Business Type" : customerOnboarding.CompanyConstitution}
                                        readOnly
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            setCustomerOnboarding((prevState: any) => ({
                                                ...prevState,
                                                Companyconstitution: value,
                                            }));
                                            dispatch(resetFormModified(true));

                                        }}
                                    />
                                </div>

                            </div>

                            <div className='document-table-component' style={{ height: "181px" }} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='document-type'>Document Type</th>
                                            <th className='document-type'>File Name</th>
                                            <th className='proof'>Submission Date</th>
                                            <th className='approval-status'>Approval Status</th>
                                            <th className='action'>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {kycDataTableMap?.length === 0 ?
                                            <div className={'main-body-header'}>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No records to show</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </div> : kycDataTableMap.map((data: any, i: any) => (
                                                <div className={'main-body-header'} key={i}>
                                                    <tr>
                                                        <td className={'document-type'}>{data.value} </td>
                                                        <td className={'document-type'}>{data.fileName} </td>
                                                        <td className={"proof"}>{DateConverter(data.submissionDate)}</td>
                                                        <td className={`approval-status ${data.approvalStatus}`}>{data.approvalStatus}</td>
                                                        <td className={'action'} style={{ display: "flex", gap: "10px" }}>
                                                            <IconButton
                                                                iconName={"ShowEye"}
                                                                height={"35px"}
                                                                width={"40px"}
                                                                fontSize={""}
                                                                className={"icons"}
                                                                cursor={"pointer"}
                                                                margin={""}
                                                                color={"white"}
                                                                backgroundColor={""}
                                                                disabled={!data.file}
                                                                hover={!data.file}
                                                                handleClick={() => {
                                                                    setShowDocumentModal(!showDocumentModal);
                                                                    SetCurrentFile(data.file)
                                                                    setCompanyKYCId(data.companyKYCId)
                                                                    setCurrentProof(data.value)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </div>
                                            ))}

                                    </tbody>

                                </table>
                            </div>

                            <div className='form-top-main form-top-main-margin' style={{ marginTop: "30px" }}>
                                <div className={"form-input"}>
                                    <div className={"dropdown-plus-combine-group"}>
                                        <div className='dropdown-user-with-plus' key={uuidv4()} style={{ width: "300px" }}>
                                            <DropdownComponent
                                                options={accountIntegrationValues}
                                                title={"Account's Integration"}
                                                className={"kyc-dropdown"}
                                                width='250px'
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setCustomerOnboarding((prevState: any) => ({
                                                        ...prevState,
                                                        integrations: val.value,
                                                        integrationsId: val.id
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                                isDisabled={(customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "") ? true : false}
                                                defaultValue={[{
                                                    value: customerOnboarding.integrations !== "" ? customerOnboarding.integrations : "Select",
                                                    label: customerOnboarding.integrations !== "" ? customerOnboarding.integrations : "Select",
                                                }]}
                                            />
                                        </div>
                                        <div className='with-plus-kyc' onClick={() => {
                                            setShowIntegrationModal(true)
                                        }}>
                                            <BsPlusLg style={{ color: '#0055D4', zIndex: "1" }} height={"14px"} />
                                        </div>
                                    </div>
                                    <div className={"dropdown-plus-combine-group"} style={{ width: "70%" }}>
                                        <div style={{ height: "40px", width: "55%" }}>
                                            <InputComponent
                                                height={"41px"}
                                                width={"100%"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                maxLength={50}
                                                inputTitle={"License Key"}
                                                placeHolder={"License Key"}
                                                readOnly
                                                inputValue={licenseKey}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                        <div className='with-plus'
                                            style={(customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "") ? { color: '#0055D4', zIndex: "1", cursor: "not-allowed" } : { color: '#0055D4', zIndex: "1" }}
                                            onClick={() => {
                                                if ((customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "")) return
                                                setShowLicenseModal(true)
                                            }}>
                                            <BsPlusLg style={(customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "") ? { color: '#0055D4', zIndex: "1", cursor: "not-allowed" } : { color: '#0055D4', zIndex: "1" }} onClick={() => {
                                                if ((customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "")) return
                                                setShowLicenseModal(true)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-top-main' style={{ marginTop: "0px" }}>
                                <div className={"form-input"} style={{ width: "100%" }}>
                                    <div className='dropdown drop-margin'>
                                        <div className={userDataErrors.OnboardingStatus ? 'setError' : 'dropdown-user'} key={uuidv4()} style={{ width: "350px" }}>
                                            <DropdownComponent
                                                options={onboardingStatus}
                                                title={"Customer Onboarding Status"}
                                                className={"kyc-dropdown"}
                                                width='350px'
                                                required={true}
                                                isDisabled={(customerOnboarding.CompanyName === null || customerOnboarding.CompanyName === "" || kycDataTableMap.length == 0 || onboardingStatusCheck === "Approved") ? true : false}
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setCustomerOnboarding((prevState: any) => ({
                                                        ...prevState,
                                                        OnboardingStatus: val.value,
                                                        Description: getDescriptionByOnboardingStatus(val.value),
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                                defaultValue={[{
                                                    value: customerOnboarding.OnboardingStatus !== "" ? customerOnboarding.OnboardingStatus : "Select",
                                                    label: customerOnboarding.OnboardingStatus !== "" ? customerOnboarding.OnboardingStatus : "Select",
                                                }]}
                                            />
                                        </div>
                                    </div>
                                    <div style={onboardingStatusCheck === "Approved" ? { pointerEvents: "none", opacity: "0.5", width: "70%" } : { width: "70%" }}>
                                        <InputComponent
                                            height={"40px"}
                                            width={"60%"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={50}
                                            inputTitle={"Description"}
                                            placeHolder={"Description"}
                                            readOnly
                                            inputValue={customerOnboarding.Description}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                setCustomerOnboarding((prevState: any) => ({
                                                    ...prevState,
                                                    Description: value,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    componentNavigator === "transactionPage" &&
                    // <div>
                    <TransactionCharges
                        transactionData={transactionData}
                        setTransactionData={setTransactionData}
                        kycTransactionData={kycTransactionData}
                        setKycTransactionData={setKycTransactionData}
                        currentMode={currentMode}
                        setCurrentMode={setCurrentMode}
                        kycCurrentMode={kycCurrentMode}
                        setKycCurrentMode={setKycCurrentMode}
                        transactionTable={transactionTable}
                        setTransactionTable={setTransactionTable}
                        userDataErrors={userDataErrors}
                        setUserDataErrors={setUserDataErrors}
                        companyDetails={companyDetails}
                        validateForm={validateForm}
                        validateFormRateCard={validateFormRateCard}
                        updatedIsValueValidAll={updatedIsValueValidAll}
                        tableData={tableData}
                        setTableData={setTableData}
                        getOverallDetails={(val: any) => { getOverallDetails(val) }}
                        setIsFormDirty={setIsFormDirty}
                    />
                    // </div>
                }

                <div className='kyc-buttons'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"130px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        margin={"0px 0px"}
                        className={"button-component-hover cancel"}
                        handleClick={() => { kycFormDataCancel() }}
                    />
                    {
                        componentNavigator !== 'form' &&
                        <ButtonComponent
                            title={"Previous"}
                            height={"50px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 0px"}
                            className={"button-component-hover cancel"}
                            handleClick={() => { handlePrevious() }}
                        />
                    }
                    {
                        componentNavigator === 'form' &&
                        <ButtonComponent
                            title={"Save"}
                            height={"50px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 0px"}
                            className={"button-component-hover cancel"}
                            handleClick={() => { handleApproveKyc() }}
                        />
                    }
                    <ButtonComponent
                        title={componentNavigator === "form" ? "Next" : "Save"}
                        height={"50px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 0px"}
                        disabled={false}
                        className={"button-component-hover common-btn"}
                        handleClick={
                            () => {
                                if (componentNavigator === "form") {
                                    if (!isEditMode) {
                                        const validFormCheck = validateFormView()
                                        if (!validFormCheck) {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage("Mandatory field(s) should not be left blank.")
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        } else if (customerOnboarding.customerOnboardingId) {
                                            setComponentNavigator("transactionPage")
                                        } else if (!customerOnboarding.customerOnboardingId) {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage('Please save the changes before moving to the next screen.')
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        } else {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage("Select a customer onboarding status to proceed to the next page.")
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        }
                                    } else if (isEditMode) {
                                        const validFormCheck = validateFormView()
                                        if (!validFormCheck) {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage("Mandatory field(s) should not be left blank.")
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        } else if (customerOnboarding.customerOnboardingId) {
                                            setComponentNavigator("transactionPage")
                                        } else if (!customerOnboarding.customerOnboardingId) {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage('Please save the changes before moving to the next screen.')
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        } else {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage("Select a customer onboarding status to proceed to the next page.")
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        }
                                    }
                                    else {
                                        if (!customerOnboarding.customerOnboardingId) {
                                            setShowAlertBox(true);
                                            setShowType("danger")
                                            setShowMessage('Please save the changes before moving to the next screen.')
                                            setTimeout(() => {
                                                setShowAlertBox(false);
                                            }, 5000);
                                        } else {
                                            setComponentNavigator("transactionPage")
                                        }
                                    }
                                } else {
                                    // const valid = validateForm()
                                    if (transactionTable.length > 0) {
                                        if (isEditMode) {
                                            userNavigateFunction("grid")
                                        }
                                        if (!isEditMode) {
                                            userNavigateFunction("grid")
                                        }
                                    } else {
                                        setShowAlertBox(true);
                                        setShowType("danger")
                                        setShowMessage("Mandatory field(s) should not be left blank.")
                                        setTimeout(() => {
                                            setShowAlertBox(false);
                                        }, 5000);
                                    }
                                }
                            }
                        }
                    // handleClick={() => {
                    //     const showAlert = (message: string) => {
                    //         setShowAlertBox(true);
                    //         setShowType("danger");
                    //         setShowMessage(message);
                    //         setTimeout(() => setShowAlertBox(false), 5000);
                    //     };

                    //     const handleFormValidation = () => {
                    //         if (!validateFormView()) {
                    //             showAlert("Mandatory field(s) should not be left blank.");
                    //         } else if (!customerOnboarding.customerOnboardingId) {
                    //             showAlert("Select a customer onboarding status to proceed to the next page.");
                    //         } else {
                    //             setComponentNavigator("transactionPage");
                    //         }
                    //     };

                    //     if (componentNavigator === "form") {
                    //         if (isEditMode || !isEditMode) {
                    //             handleFormValidation();
                    //         } else if (!customerOnboarding.customerOnboardingId) {
                    //             showAlert("Please save the changes before moving to the next screen.");
                    //         } else {
                    //             setComponentNavigator("transactionPage");
                    //         }
                    //     } else {
                    //         if (transactionTable.length > 0) {
                    //             userNavigateFunction("grid");
                    //         } else {
                    //             showAlert("Mandatory field(s) should not be left blank.");
                    //         }
                    //     }
                    // }}
                    />
                </div>
            </MainPageCard>
            {
                showDocumentModal &&
                <DocumentShowModal
                    kycId={companyKYCId}
                    document={currentFile}
                    showDocumentModal={viewDocumentModal}
                    documentDeclineStatus={documentDeclineStatus}
                    closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
                    setDocumentDeclineStatus={(val: any, type: string) => {
                        setDocumentDeclineStatus({ ...documentDeclineStatus, Documentremarks: val, })
                    }}
                    setDocumentDeclineValues={(val: any) => {
                        setDocumentDeclineStatus({
                            ...documentDeclineStatus,
                            Documentremarks: val,
                        })
                    }}
                    proof={currentproof}
                    getOverallDetails={(value: any) => { getOverallDetails(value) }}
                    companyId={customerOnboarding.companyId}
                />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        if (componentNavigator == 'form') {
                            setShowChangesModal(false);
                            setIsFormDirty(false)
                            userNavigateFunction("grid")
                        } else {
                            setComponentNavigator("form")
                            setShowChangesModal(false);
                            setIsFormDirty(false)
                        }
                    }}
                    handleClose={() => setShowChangesModal(false)}
                />
            }
            {
                loadingScreen && <Loading />
            }
            {showAlertBox &&
                <AlertBox type={showType} message={showMessage}
                />
            }

            {showLicenseModal && <LicenseKeyModal
                companyId={customerOnboarding.companyId}
                licenseKey={licenseKey}
                setlicenseKey={setlicenseKey}
                closeDocument={(val: boolean) => { setShowLicenseModal(val); }}
            />}

            {showIntegrationModal && <AddAccountIntegration
                clearValue={(val: boolean) => { setShowIntegrationModal(val); }}
                accountIntegrationValues={accountIntegrationValues}
                getAccountIntegrationDetails={() => getAccountIntegrationDetails()}
                setAccountIntegrationValues={(val: any) => { setAccountIntegrationValues(val) }} />
            }

            {/* {
                otpVerification &&
                <OtpVerifyModal
                    otpVerify={resendOtp}
                    leavePage={() => {
                        setOtpVerification(false)
                    }}
                    isLocked={false}
                    setIsLocked={setIsLocked}
                    getStatus={async (stringFromArray) => {
                        let res;
                        try {
                            res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                                otp: stringFromArray,
                                approvedBy: auth.token,

                            });
                            if (res.status === 200) {
                                handleApproveKyc()
                            }
                        } catch (error: any) {
                            setShowAlertBox(true);
                            setShowType("danger")
                            setShowMessage(error.response.data.error)
                            setTimeout(() => {
                                setShowAlertBox(false);
                            }, 5000);
                        }
                    }
                    }
                />
            } */}
        </>
    )
}

export default KycUploadTable;