import React, { useRef, useState } from 'react';
import '../../styles/common-component/file-upload.scss';

interface Props {
    type?: any
    width?: any
    stakeholderInfoErrors?: any
    getFileName: (val: any) => void
    id?: string
    aadharFileName?: any
    disabled?: any
    setShowAlertBox?: any
    setShowType?: any
    setShowMessage?: any
}


export default function FileUpload({ type, width, getFileName, stakeholderInfoErrors, id, aadharFileName, disabled, setShowAlertBox, setShowType, setShowMessage }: Props) {
    const [fileName, setFileName] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const file: any = event.target.files?.[0];

        if (file.size > 1 * 1024 * 1024) {
            // alert('File size cannot exceed 2MB.');
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage('File size must not exceed 1MB.')
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
            event.target.value = '';
            return;
        }

        setFileName(event.target.files?.[0]?.name || '');
        getFileName(event.target.files || '');
    };

    const handleBrowseClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className="file-upload-container" style={{ width: width }}>
            {
                type == "browse-file-only" ?
                    <>
                        <div className='browse-file-only' onClick={handleBrowseClick}>
                            Browse Files
                        </div>
                    </> :
                    <>
                        <label htmlFor="file-upload" className="file-upload-label">
                            Upload Aadhar (Front page)<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="file-upload-display" onClick={handleBrowseClick} style={{ border: stakeholderInfoErrors?.uploadAadharFile && "1px solid red", borderRight: "none" }}>
                            <span>{fileName || (aadharFileName || 'Browse file')}</span>
                        </div>
                    </>
            }
            <input
                type="file"
                accept="image/*"
                id={id ? id : "file-upload"}
                className="file-upload-input"
                onChange={handleFileChange}
                ref={fileInputRef}
                disabled={disabled}
            />
        </div>
    );
};
